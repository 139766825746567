import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { CloseIcon } from "../../assets";
//components
import { Textarea, Button, RadioSelect, PrivateImage } from "../../components";
//constants
import { approveRadios, blockReasonSelect } from "../../constants";
//types
import { UserType } from "../../types";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onAccept: (status?: "Approved" | "Declined", reason?: string) => void;
  user: UserType; // IOwner | IExplorer
}
const ApproveModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  onAccept,
  user,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [approve, setApprove] = useState("approveUser"); //approveUser
  const [otherReason, setOtherReason] = useState<string>("");

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };

  const confirmHandler = () => {
    const type = approve === "approveUser" ? "Approved" : "Declined";
    onAccept(type, blockReason === "Other" ? otherReason : blockReason);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>{user.userName}</h5>{" "}
          <CloseIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
        </div>
        <div className={styles.modalBody}>
          {/* <UserInfoBlock
            item={{
              name: `${user.firstName} ${user.lastName}`,
              image: user.avatar || null,
              location: `${user.location.city} ${user.location.state ? ", " + user.location.state : ""}`,
              lookingFor: "Employment Opportunities",
              //@ts-ignore
              phone: user?.phone || "-",
              email: user.email,
              //@ts-ignore
              bussinessName: user?.business?.[0]?.name || "-",
              //@ts-ignore

              businessSector: user?.business?.[0]?.sector || "-",

              countEmployees: number || "-",
              //@ts-ignore
              revenue: revenue || "-",
            }}
          /> */}
          <div className={styles.approveBody}>
            <div className={styles.approveFlex}>
              <div className={styles.approveImage}>
                <PrivateImage
                  src={user?.verificationDocument?.url}
                  alt="approveImage"
                  size={150}
                />
              </div>
              <div>
                <div className={styles.approveSection}>
                  <div className={styles.radio}>
                    <RadioSelect
                      options={approveRadios}
                      name="approve"
                      value={approve}
                      onChange={setApprove}
                    />
                    {/* <RadioSelect
                  options={[{ label: "Reject photo", value: "rejectPhoto" }]}
                  name="approve"
                  value={approve}
                  onChange={setApprove}
                />{" "} */}
                    {/* approveRadios */}
                  </div>
                  <div className={styles.subRadio}>
                    {approve === "rejectPhoto" && (
                      <div>
                        <p>Reason of rejection:</p>
                        <RadioSelect
                          options={blockReasonSelect}
                          name="reason"
                          value={blockReason}
                          onChange={setBlockReason}
                        />
                        {blockReason === "Other" && (
                          <Textarea
                            placeholder="Message"
                            value={otherReason}
                            onChange={onChangeOtherReason}
                            maxLength={240}
                            rows={5}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <Button title="Confirm" onClick={confirmHandler} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveModal;
