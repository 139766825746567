import { ChangeEvent, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import { SearchIcon, FilterIcon, ArrowDownIcon } from "../../../assets";
//components
import {
  UsersItem,
  Button,
  Checkbox,
  Empty,
  Input,
  Layout,
  PageItem,
  UsersFilter,
} from "../../../components";
//modals
import Delete from "../../../modals/Delete";
//hooks
import useDebounce from "../../../hooks/useDebounce";
//types
import { UserType } from "../../../types";
//api
import { getAllUsers } from "../../../services/users.service";
//context
import { useAdminContext } from "../../../context";

const LIMIT_PER_PAGE = 10;

export default function Users() {
  const [openFilter, setOpenFilter] = useState(false);

  const [allUsers, setAllUsers] = useState<UserType[]>([]);

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false); // true
  const [isChecked, setIsChecked] = useState(false);
  // const [employers, setEmployers] = useState<IEmployer[]>([]);
  const [userNameSort, setUserNameSort] = useState<1 | -1 | 0>(0);
  const [locationSort, setlLocationSort] = useState<1 | -1 | 0>(0);
  const [statusSort, setlStatusSort] = useState<1 | -1 | 0>(0);
  const [createdAtSort, setlCreatedAtSort] = useState<1 | -1 | 0>(0);

  const [deleteOwners, setDeleteOwners] = useState<string[]>([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<any>();
  const { admin, adminLoading } = useAdminContext();

  const searchDebounced = useDebounce(search, 500);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetUsers = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type:
      | { userName: 1 | -1 }
      | { "location.name": 1 | -1 }
      | { status: 1 | -1 }
      | { createdAt: 1 | -1 }
      | {} = {},
    filter: any = {}
  ) => {
    console.log({ type, filter });
    try {
      setIsLoading(true);
      const { data } = await getAllUsers({
        page: page,
        limit: limit,
        search: search,
        type: type,
        filter: filter,
      });
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalUsers / limit));
        setAllUsers(data?.users);
        // verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onDeleteEmployer = async () => {
    // try {
    //   setIsLoading(true);
    //   const { data } = await deleteUSer(deleteOwners);
    //   if (data?.success) {
    //     onGetOwners(page, 20);
    //     toast.success("Users have been successfully deleted");
    //   }
    //   setIsLoading(false);
    // } catch (error) {
    //   setIsLoading(false);
    //   toast.error(toastError);
    // }
  };

  const onApplyFilters = (gender: string, verified: string) => {
    let filter: any = {};
    if (gender !== "All") {
      filter.gender = gender;
    }
    if (verified !== "All") {
      filter.verify = verified === "Verified";
    }

    if (Object.keys(filter).length === 0) {
      setCurrentFilter(undefined);
    } else {
      setCurrentFilter(filter);
    }
  };

  useEffect(() => {
    onGetUsers(1, LIMIT_PER_PAGE, search);
  }, []);

  useEffect(() => {
    const sortParams: any = {};
    if (userNameSort !== 0) {
      sortParams.userName = userNameSort;
    } else if (locationSort !== 0) {
      sortParams["location.name"] = locationSort;
    } else if (statusSort !== 0) {
      sortParams.status = statusSort;
    } else if (createdAtSort !== 0) {
      sortParams.createdAt = createdAtSort;
    }
    onGetUsers(
      page,
      LIMIT_PER_PAGE,
      searchDebounced,
      sortParams,
      currentFilter
    );
  }, [
    userNameSort,
    locationSort,
    statusSort,
    createdAtSort,
    searchDebounced,
    page,
    currentFilter,
  ]);

  return (
    <>
      <Delete
        title="Delete user"
        onDelete={onDeleteEmployer}
        isLoading={isLoading || false}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        type="delete"
        text={`Are you sure you want to delete ${
          deleteOwners.length == 1 ? `1 user` : `${deleteOwners.length} users`
        }?`}
      />

      <Layout title={"Users"}>
        <div className={styles.navBody}>
          <div className={styles.input}>
            <Input
              icon={SearchIcon}
              placeholder="Search"
              isEmpty={search.length === 0}
              isSearch
              onChange={onChangeSearch}
              // style={{ width: "88%" }}
              setEmpty={() => setSearch("")}
            />
          </div>
          <div className={styles.filterBody}>
            <div
              className={clsx({
                [styles.deleteOwnersButton]: deleteOwners.length > 0,
              })}
            >
              {deleteOwners.length > 0 &&
                !adminLoading &&
                admin?.role !== "manager" && (
                  <Button
                    title={`Delete (${deleteOwners.length})`}
                    styleType="filled"
                    onClick={() => setIsShowDeleteModal(true)}
                    loading={isLoading}
                    style={{
                      border: "none",
                      width: "120px",
                      height: "43px",
                      background: "#FF3C3C",
                      borderRadius: "16px",
                    }}
                  />
                )}
              <div
                onClick={(e) => setOpenFilter((current) => !current)}
                className={clsx({
                  [styles.filter]: !openFilter,
                  [styles.filterSelected]: openFilter,
                })}
              >
                <FilterIcon
                  className={clsx({ [styles.svgOpen]: openFilter })}
                />
              </div>
            </div>
            <div
              className={clsx({
                [styles.filterDetails]: !openFilter,
                [styles.filterDetailsOpen]: openFilter,
              })}
            >
              <UsersFilter onClose={setOpenFilter} onApply={onApplyFilters} />
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <section className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              {/* <div className={styles.checkbox}>
                <Checkbox
                  isChecked={isChecked}
                  setIsChecked={() => setIsChecked((current) => !current)}
                  mainCheckBox
                />
              </div> */}
              <div
                onClick={() => {
                  setlLocationSort(0);
                  setlStatusSort(0);
                  setlCreatedAtSort(0);
                  setUserNameSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.nameBlock, {
                  [styles.activeBlockAZ]: userNameSort === 1,
                  [styles.activeBlockZA]: userNameSort === -1,
                })}
              >
                <p>Candidate</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setUserNameSort(0);
                  setlStatusSort(0);
                  setlCreatedAtSort(0);
                  setlLocationSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.industryBlock, {
                  [styles.activeBlockAZ]: locationSort === 1,
                  [styles.activeBlockZA]: locationSort === -1,
                })}
              >
                <p>Location</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>

              <div
                onClick={() => {
                  setlLocationSort(0);
                  setlCreatedAtSort(0);
                  setUserNameSort(0);
                  setlStatusSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.locationBlock, {
                  [styles.activeBlockAZ]: statusSort === 1,
                  [styles.activeBlockZA]: statusSort === -1,
                })}
              >
                <p>Employment status</p>{" "}
                <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setlLocationSort(0);
                  setlStatusSort(0);
                  setUserNameSort(0);
                  setlCreatedAtSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.sizeBlock, {
                  [styles.activeBlockAZ]: createdAtSort === 1,
                  [styles.activeBlockZA]: createdAtSort === -1,
                })}
              >
                <p>Registration date</p>{" "}
                <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
            </div>
            <div className={styles.reportsContainer}>
              {isLoading && (
                <div className={styles.loaderWrapper}>
                  <PulseLoader color={"#236BFE"} />
                </div>
              )}
              {!isLoading &&
                allUsers.length > 0 &&
                allUsers.map((item, index) => {
                  return (
                    <UsersItem
                      allChecked={isChecked}
                      key={item.id}
                      item={item}
                      setDeleteOwners={setDeleteOwners}
                    />
                  );
                })}
              {!isLoading && allUsers.length === 0 && <Empty />}
            </div>
            <div className={styles.paginationContainer}>
              {pagesCount >= 0 &&
                Array(pagesCount === 0 ? 1 : pagesCount)
                  .fill("_")
                  .map((item, index) => (
                    <PageItem
                      onPress={() => setPage(index + 1)}
                      key={index}
                      page={index + 1}
                      isActive={index + 1 === page}
                    />
                  ))}
              {pagesCount > 1 && page !== pagesCount && (
                <div
                  onClick={() => setPage((prev) => prev + 1)}
                  className={styles.nextBtn}
                >
                  Next
                </div>
              )}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
