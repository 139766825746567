import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { ArrowDownIcon } from "../../assets";
//components
import CheckBox from "../Checkbox";
import StaffItem from "../StaffItem";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
//types
import { InstructorType, UserType } from "../../types";
//utils
import { showUserStatus } from "../../utils";

const InstructorItem = ({
  item,
  setDeleteOwners,
}: {
  item: InstructorType;
  setDeleteOwners: (item: any) => void;
}) => {
  const navigate = useNavigate();
  const formatedDate = useFormatDate(item.createdAt || "");

  const userPhoto = useMemo(() => item?.avatar || undefined, [item]);

  return (
    <div className={styles.businessBody}>
      <div
        className={styles.wrapper}
        onClick={() => navigate(`/instructors/${item.id}`)}
      >
        <div className={styles.staffBody}>
          <StaffItem
            image={userPhoto}
            name={`${item?.firstName} ${item?.lastName}`}
          />
        </div>

        <div className={styles.title}>
          <p> {item.title}</p>
        </div>
        <div className={styles.groups}>{item?.groupsCount}</div>
        <div className={styles.date}>
          <p>{formatedDate ? formatedDate : ""}</p>
        </div>

        <div
          onClick={() => navigate(`/instructors/${item.id}`)}
          className={styles.select}
        >
          <div className={styles.arrowBtn}>
            <ArrowDownIcon width={"15px"} fill="white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorItem;
