import { ChangeEvent, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";

import styles from "./styles.module.scss";
//assets
import { SearchIcon, AddIcon } from "../../../assets";
//components
import {
  Button,
  Empty,
  Input,
  Layout,
  PageItem,
  InstructorItem,
} from "../../../components";
//modals
import Delete from "../../../modals/Delete";
import InstructorModal from "../../../modals/Instructor";
//hooks
import useDebounce from "../../../hooks/useDebounce";
//types
import { InstructorType } from "../../../types";
//api
import { getAllInstructors } from "../../../services/instructors.service";

const LIMIT_PER_PAGE = 10;

export default function Instructors() {
  const [allInstructors, setAllInstructors] = useState<InstructorType[]>([]);

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false); // true
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const searchDebounced = useDebounce(search, 500);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetInstructors = async (
    page: number = 1,
    limit: number,
    search: string = ""
  ) => {
    try {
      setIsLoading(true);
      const { data } = await getAllInstructors({
        page: page,
        limit: limit,
        search: search,
      });
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalItems / limit));
        setAllInstructors(data?.instructors);
        // verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onDeleteInstructor = async () => {
    // try {
    //   setIsLoading(true);
    //   const { data } = await deleteUSer(deleteOwners);
    //   if (data?.success) {
    //     onGetOwners(page, 20);
    //     toast.success("Users have been successfully deleted");
    //   }
    //   setIsLoading(false);
    // } catch (error) {
    //   setIsLoading(false);
    //   toast.error(toastError);
    // }
  };

  useEffect(() => {
    onGetInstructors(1, LIMIT_PER_PAGE, search);
  }, []);

  useEffect(() => {
    onGetInstructors(page, LIMIT_PER_PAGE, searchDebounced);
  }, [searchDebounced, page]);

  return (
    <>
      <InstructorModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        onGetAll={onGetInstructors}
      />
      <Delete
        title="Delete instructor"
        onDelete={onDeleteInstructor}
        isLoading={isLoading || false}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        type="delete"
        text={`Are you sure you want to delete instructor?`}
      />

      <Layout title={"Instructors"}>
        <div className={styles.navBody}>
          <div className={styles.input}>
            <Input
              icon={SearchIcon}
              placeholder="Search"
              isEmpty={search.length === 0}
              isSearch
              onChange={onChangeSearch}
              // style={{ width: "88%" }}
              setEmpty={() => setSearch("")}
            />
          </div>
          <Button
            onClick={(e) => setIsOpenModal(true)}
            title="Add New"
            icon={AddIcon}
          />
        </div>
        <div className={styles.wrapper}>
          <section className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              <div className={styles.nameBlock}>
                <p>Instructor</p>
              </div>
              <div className={styles.industryBlock}>
                <p>Title</p>
              </div>
              <div className={styles.locationBlock}>
                <p>Groups</p>
              </div>
              <div className={styles.sizeBlock}>
                <p>Creation date</p>
              </div>
            </div>
            <div className={styles.reportsContainer}>
              {isLoading && (
                <div className={styles.loaderWrapper}>
                  <PulseLoader color={"#236BFE"} />
                </div>
              )}
              {!isLoading &&
                allInstructors.length > 0 &&
                allInstructors.map((item, index) => {
                  return (
                    <InstructorItem
                      key={item.id}
                      item={item}
                      setDeleteOwners={() => {}}
                    />
                  );
                })}
              {!isLoading && allInstructors.length === 0 && <Empty />}
            </div>
            <div className={styles.paginationContainer}>
              {pagesCount >= 0 &&
                Array(pagesCount === 0 ? 1 : pagesCount)
                  .fill("_")
                  .map((item, index) => (
                    <PageItem
                      onPress={() => setPage(index + 1)}
                      key={index}
                      page={index + 1}
                      isActive={index + 1 === page}
                    />
                  ))}
              {pagesCount > 1 && page !== pagesCount && (
                <div
                  onClick={() => setPage((prev) => prev + 1)}
                  className={styles.nextBtn}
                >
                  Next
                </div>
              )}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
