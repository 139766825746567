import React, { useState } from "react";

import styles from "./style.module.scss";
//components
import ContentWrapper from "../Layout/ContentWrapper";
import RadioSettings from "../RadioSelect";
import Button from "../Button";

export default function UsersFilter({
  onClose,
  onApply,
}: {
  onClose: (value: boolean) => void;
  onApply?: (gender: string, verified: string) => void;
}) {
  const [gender, setGender] = useState("All");
  const [verifiedUser, setVerifiedUser] = useState("All");
  const [businessType, setBusinessType] = useState("All");
  const [revenue, setRevenue] = useState("All");
  const [employees, setEmployees] = useState("All");
  const [location, setLocation] = useState<{
    city: string;
    state?: string;
    country: string;
  }>({
    city: "",
    state: "",
    country: "",
  });

  const onClearHandler = () => {
    setGender("All");
    setLocation({ city: "", country: "", state: "" });
    setBusinessType("All");
    setRevenue("All");
    setEmployees("All");
    setVerifiedUser("All");
  };

  return (
    <ContentWrapper>
      <div className={styles.filterHeader}>
        <p>Filter by</p> <span onClick={onClearHandler}>Clear all filters</span>
      </div>{" "}
      <div className={styles.radioBody}>
        <p>Gender</p>
        <RadioSettings
          options={[
            { label: "All", value: "All" },
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          name="gender"
          value={gender}
          onChange={setGender}
        />
      </div>
      <div className={styles.dividerX}></div>
      <div className={styles.radioBody}>
        <p>Verificated users</p>
        <RadioSettings
          options={[
            { label: "All", value: "All" },
            { label: "Verified", value: "Verified" },
            { label: "Not verified", value: "Not verified" },
          ]}
          name="verification"
          value={verifiedUser}
          onChange={setVerifiedUser}
        />
      </div>
      <div className={styles.dividerX}></div>
      <div
        onClick={() => {
          onClose(false);
          onApply?.(gender, verifiedUser);
        }}
        className={styles.applyButton}
      >
        <Button title="Apply" />
      </div>
    </ContentWrapper>
  );
}
