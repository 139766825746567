import { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
//assets
import { CloseIcon } from "../../assets";
//components
import { Input, Button, Select, Textarea } from "../../components";
//schemas
import { newGroupSchema } from "../../schemas";
//constants
import { toastError } from "../../constants";
//api
import { createGroup, editGroup } from "../../services/groups.service";
//types
import {
  GroupCategoryArr,
  GroupCategoryEnum,
  GroupCategoryObj,
  GroupType,
} from "../../types";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onGetAll: () => void;
  instructorId?: string;
  item?: GroupType | null;
}

const GroupModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  onGetAll,
  instructorId,
  item,
}): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<string>("");

  const onCreate = async (values: {
    category: GroupCategoryEnum;
    title: string;
    description: string;
  }) => {
    try {
      setIsLoading(true);

      if (!!item) {
        if (values.category === GroupCategoryEnum.meetAndGreet) {
          throw new Error("You can't edit Meet and Great group");
        }
        const { data } = await editGroup(item.id, {
          category: values.category,
          title: values.title,
          description: values.description,
        });
        if (data?.success) {
          onGetAll();
          resetForm();
          setIsOpen(false);
          toast.success("Group has been successfully updated");
        }
      } else {
        if (!instructorId) {
          throw new Error(toastError);
        }
        const { data } = await createGroup(instructorId, values);
        if (data?.success) {
          onGetAll();
          resetForm();
          setIsOpen(false);
          toast.success("Group has been successfully created");
        }
      }

      setIsLoading(false);
    } catch (error: any) {
      console.log("onCreate error", error);
      setIsLoading(false);
      toast.error(toastError);
      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    touched,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik<{
    category: GroupCategoryEnum;
    title: string;
    description: string;
  }>({
    initialValues: {
      category: GroupCategoryEnum.meetAndGreet,
      title: "",
      description: "",
    },
    validationSchema: newGroupSchema,
    validateOnChange: true,
    onSubmit: onCreate,
  });

  useEffect(() => {
    if (item) {
      setValues({
        category: item.category,
        title: item.title,
        description: item.description,
      });
    }
  }, [item]);

  useEffect(() => {
    setCommonError("");
  }, [values.category]);

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>{!!item ? "Edit" : "New"} Group</h5>{" "}
          <CloseIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.form}>
            <div className={styles.category}>
              <Select
                label="Category"
                options={GroupCategoryArr}
                value={GroupCategoryObj[values.category]}
                onChange={(op: any) => {
                  console.log(op);

                  resetForm();
                  setFieldValue("category", op?.id);
                }}
                disabled={!!item}
              />
            </div>
            {values.category === GroupCategoryEnum.relationshipCoaching && (
              <>
                <div className={styles.title}>
                  <Input
                    id="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && errors.title}
                    wrapperStyles={styles.input}
                  />
                </div>
                <div className={styles.about}>
                  <Textarea
                    id="description"
                    label="Description"
                    value={values.description}
                    onChange={handleChange}
                    maxLength={240}
                    rows={3}
                    error={touched.description && errors.description}
                    wrapperStyles={styles.input}
                  />
                </div>
              </>
            )}
            {commonError && <p className={styles.commonError}>{commonError}</p>}
            <Button
              title={!!item ? "Save" : "Create"}
              onClick={() => handleSubmit()}
              disabled={isLoading}
              type="submit"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupModal;
