import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";

// components
import GroupModal from "../../../modals/Group";
import Delete from "../../../modals/Delete";
import {
  Button,
  Layout,
  PrivateImage,
  RadioButtons,
} from "../../../components";
import { GroupFollowers, GroupMeetings } from "./components";

// context
import { useAdminContext } from "../../../context";

// api
import { deleteGroup } from "../../../services/groups.service";

// hooks
import { useGroupMeetings } from "./hooks";
import useTable from "../../../hooks/useTable";
import useBoolean from "../../../hooks/useBoolean";

// constants
import { useGroup } from "./hooks";

// types
import { GroupCategoryEnum, GroupCategoryObj } from "../../../types";

// assets
import { AddIcon } from "../../../assets";
import { PlaceholderAvatar } from "../../../assets/images";

// styles
import styles from "./styles.module.scss";
import CreateMeeting from "../../../modals/CreateMeeting";

const tabOptions = [
  { label: "Meetings", value: "Meetings" },
  { label: "Followers", value: "Followers" },
];

const GroupDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const { id: groupId } = useParams();
  const { adminLoading, admin } = useAdminContext();

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const {
    value: isOpenMeetingModal,
    setTrue: showMeetingModal,
    setFalse: closeMeetingModal,
  } = useBoolean(false);

  const [currentTab, setCurrentTab] = useState("Meetings");

  // group
  const {
    isLoading,
    group,
    groupInstructor,
    refetch: refetchGroup,
  } = useGroup();

  const isRelationshipCoaching =
    group.category === GroupCategoryEnum.relationshipCoaching;

  const onDeleteGroup = async () => {
    try {
      const { data } = await deleteGroup(group.id);

      if (data?.success) {
        navigate(`/instructors/${group.instructor.id}`);
        toast.success("Group has been successfully deleted!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // group meetings
  const { page, setPage, sortBy, onChangeSortBy } = useTable();

  const {
    isLoading: isLoadingGroupMeetings,
    groupMeetings,
    totalGroupMeetings,
    refetch: refetchMeetings,
  } = useGroupMeetings({
    groupId,
    params: { page, sort: sortBy },
  });

  const handleTabComponent = () => {
    switch (currentTab) {
      case "Meetings":
        return (
          <GroupMeetings
            isLoading={isLoadingGroupMeetings}
            meetings={groupMeetings}
            totalMeetings={totalGroupMeetings}
            page={page}
            setPage={setPage}
            setSortBy={onChangeSortBy}
            refetchMeetings={refetchMeetings}
          />
        );

      case "Followers":
        return <GroupFollowers groupId={group.id} />;

      default:
        return null;
    }
  };

  return (
    <>
      {isOpenMeetingModal ? (
        <CreateMeeting
          groupId={group.id}
          category={group.category}
          refetchMeetings={refetchMeetings}
          onClose={closeMeetingModal}
        />
      ) : null}
      <GroupModal
        isOpen={isShowEditModal}
        instructorId={group?.instructor?.id}
        item={group as any}
        setIsOpen={setIsShowEditModal}
        onGetAll={refetchGroup}
      />
      <Delete
        isLoading={false}
        isOpen={isShowDeleteModal}
        type="delete"
        title="Delete group"
        text={`Are you sure you want to delete group?`}
        setIsOpen={setIsShowDeleteModal}
        onDelete={onDeleteGroup}
      />
      <Layout
        subtitle="Back to instructor"
        buttons={
          <>
            {!adminLoading && admin?.role !== "manager" ? (
              <div className={styles.btnWrapper}>
                {isRelationshipCoaching ? (
                  <Button
                    title="Edit"
                    styleType="outlined"
                    color="#000000"
                    onClick={() => setIsShowEditModal(true)}
                    style={{
                      border: "none",
                      width: "93px",
                      background: "#E4E4E4",
                      borderRadius: "16px",
                      color: "black",
                    }}
                  />
                ) : null}
                <Button
                  title="Delete"
                  styleType="filled"
                  onClick={() => setIsShowDeleteModal(true)}
                  style={{
                    border: "none",
                    width: "93px",
                    background: "#FF3C3C",
                    borderRadius: "16px",
                  }}
                />
              </div>
            ) : null}
          </>
        }
      >
        <div className={styles.wrapper}>
          {isLoading && isEmpty(group) ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          ) : (
            <>
              <div className={styles.info}>
                <div className={styles.nameWrapper}>
                  <p className={styles.name}>
                    {isRelationshipCoaching
                      ? group.title
                      : GroupCategoryObj[group.category]}
                  </p>
                  <div className={styles.category}>
                    {GroupCategoryObj[group.category]}
                  </div>
                </div>
                <div className={styles.instructorWrapper}>
                  <PrivateImage
                    src={groupInstructor?.avatar}
                    defaultsrc={PlaceholderAvatar}
                    alt="avatar"
                    className={styles.instructorAvatar}
                  />
                  <div className={styles.instructorNameWrapper}>
                    <p className={styles.instructorName}>
                      {groupInstructor?.firstName} {groupInstructor?.lastName}
                    </p>
                    <div className={styles.instructorTitle}>
                      {groupInstructor?.title}
                    </div>
                  </div>
                </div>
              </div>
              {isRelationshipCoaching ? (
                <div className={styles.about}>{group.description}</div>
              ) : null}
              <div className={styles.tabMenu}>
                <div className={styles.tabWrapper}>
                  <RadioButtons
                    name="group"
                    value={currentTab}
                    onChange={setCurrentTab}
                    options={tabOptions}
                    tag={[groupMeetings.length || 0, group.followersCount || 0]}
                  />
                </div>
                {currentTab === "Meetings" && (
                  <Button
                    onClick={showMeetingModal}
                    title="Add Meeting"
                    icon={AddIcon}
                    className={styles.addNewBtn}
                  />
                )}
              </div>
              {handleTabComponent()}
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default GroupDetails;
