import { FC, useState } from "react";
import Modal from "react-modal";
// import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { CloseIcon } from "../../assets";
//components
import { PrivateImage } from "../../components";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  image: string;
}

const CheckImageModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  image,
}): JSX.Element => {
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <PrivateImage src={image} alt="image detail" size={150} />
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className={styles.closeBtn}
        >
          <CloseIcon fill="white" />
        </div>
      </div>
    </Modal>
  );
};

export default CheckImageModal;
