import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import { CommentIcon, HeartOutlineIcon, TrashIcon } from "../../assets";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
//types
import { PostType } from "../../types";
//components
import PrivateImage from "../PrivateImage";

export default function PostItem({ item }: { item: PostType }) {
  const formatedDate = useFormatDate(item.createdAt);
  return (
    <>
      <div className={styles.postWrapper}>
        <div className={styles.infoBody}>
          <p className={styles.date}>{formatedDate}</p>
          <span>{item.text}</span>
        </div>
        <div className={styles.detailBody}>
          {item?.mediaFile && (
            <div className={styles.image}>
              {item?.mediaFile?.type === "image" && item?.mediaFile?.url && (
                <PrivateImage src={item?.mediaFile?.url} alt="post image" />
              )}
              {item?.mediaFile?.type === "video" && item?.mediaFile?.url && (
                <video>
                  <source
                    src={`${item?.mediaFile?.url}#t=0`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          )}
          <div className={styles.likesBody}>
            <HeartOutlineIcon fill="#808191" />
            <span>{item.totalLikes}</span>
          </div>
          <div className={styles.commentsBody}>
            <CommentIcon />
            <span>{item.totalComments}</span>
          </div>
          <div className={styles.trashBody} onClick={() => {}}>
            <TrashIcon />
          </div>
        </div>
      </div>
    </>
  );
}
