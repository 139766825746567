import * as yup from "yup";

import validationValues from "./values";

export const editStaffSchema = (password = false) => {
  return yup
    .object({
      firstName: validationValues.firstName,
      lastName: validationValues.lastName,
      email: validationValues.email,
      password: password ? validationValues.password : yup.string(),
      role: validationValues.role,
    })
    .required();
};
