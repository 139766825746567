import clsx from "clsx";

import styles from "./styles.module.scss";

type Option = {
  label: string;
  value: string;
};

type RadioButtonsProps = {
  name: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
};

type RadioProps = {
  name: string;
  value: string;
  checked: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function Radio({ name, value, checked, label, onChange }: RadioProps) {
  return (
    <label
      className={clsx({ [styles.radio]: !checked, [styles.selected]: checked })}
    >
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  );
}

function RadioSelect({ name, options, value, onChange }: RadioButtonsProps) {
  return (
    <>
      {options.map((option) => (
        <Radio
          key={option.value}
          name={name}
          value={option.value}
          label={option.label}
          checked={option.value === value}
          onChange={(e) => onChange(e.target.value)}
        />
      ))}
    </>
  );
}

export default RadioSelect;
