// components
import { Button } from "../../../../../components";
import MeetingLink from "../../../../../modals/MeetingLink";

// hooks
import useBoolean from "../../../../../hooks/useBoolean";

// styles
import styles from "./styles.module.scss";

interface IAddMeetingLinkProps {
  meetingId: string;
  meetingLink?: string | null;
  refetchMeetings: () => void;
}

const AddMeetingLink = ({
  meetingId,
  meetingLink,
  refetchMeetings,
}: IAddMeetingLinkProps) => {
  const {
    value: isOpen,
    setTrue: showModal,
    setFalse: closeModal,
  } = useBoolean(false);

  return (
    <>
      <Button
        className={styles["add-meeting__button"]}
        title="Add Link"
        onClick={showModal}
      />
      {isOpen ? (
        <MeetingLink
          meetingId={meetingId}
          meetingLink={meetingLink}
          refetchMeetings={refetchMeetings}
          onClose={closeModal}
        />
      ) : null}
    </>
  );
};

export default AddMeetingLink;
