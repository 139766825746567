import queryString from "query-string";

interface IQueryParamsBuilderOptions {
  isQuestionMark?: boolean;
  queryStringOptions?: queryString.StringifyOptions;
}

export const queryParamsBuilder = (
  params: object,
  options: IQueryParamsBuilderOptions = {
    isQuestionMark: true,
    queryStringOptions: {},
  }
) => {
  const { isQuestionMark, queryStringOptions } = options;

  if (!params) {
    return "";
  }

  return `${isQuestionMark ? "?" : ""}${queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
    ...queryStringOptions,
  })}`;
};
