import React, { ReactNode, createContext, useEffect, useState } from "react";
//types
import { AdminType } from "../../types";
//api
import { getMyInfo } from "../../services/auth.service";
//utils
import { getAccessToken } from "../../utils/tokenStorage";

type AdminContextType = {
  admin: null | AdminType;
  setAdmin: (user: AdminType) => void;
  adminLoading: boolean;
};

export const AdminContext = createContext<AdminContextType>({
  admin: null,
  setAdmin: (user) => {},
  adminLoading: false,
});

export const AdminProvider = ({ children }: { children: ReactNode }) => {
  const [adminState, setAdminState] = useState<null | AdminType>(null);
  const [adminLoading, setAdminStateLoading] = useState<boolean>(false);
  const token = getAccessToken();

  const getAdmin = async () => {
    try {
      setAdminStateLoading(true);
      const { data } = await getMyInfo();

      if (data?.success) {
        setAdminState(data?.admin);
      }
    } catch (e) {
      console.log("getAdmin error", e);
    } finally {
      setAdminStateLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      getAdmin();
    }
  }, [token]);

  const setAdmin = (user: AdminType) => {
    setAdminState(user);
  };

  return (
    <AdminContext.Provider
      value={{ admin: adminState, setAdmin, adminLoading }}
    >
      {children}
    </AdminContext.Provider>
  );
};
