import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";
//assets
import { CheckIcon, SearchIcon } from "../../../assets";
//components
import { Button, Layout, Input } from "../../../components";
import PhotosVerification from "./PhotosVerification";
//modals
import AgreementModal from "../../../modals/Agreement";
//constants
import { toastError } from "../../../constants";
//api
import { verifyUser } from "../../../services/verification.service";
//context
import { useAdminContext } from "../../../context";

type VerifyUserParams = {
  userId: string;
  status: "Approved" | "Declined";
  reason?: string;
};

export default function Verification() {
  const [verifyUsers, setVerifyUsers] = useState<string[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMultiple, setIsmultiple] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [reload, setReload] = useState(false);
  const { admin, adminLoading } = useAdminContext();

  const verify = async (verifyData?: {
    id: string;
    status: "Approved" | "Declined";
    reason?: string;
  }) => {
    if (verifyUsers.length === 0 && !isMultiple && !verifyData) {
      return;
    }
    try {
      setIsLoading(true);
      if (verifyData) {
        const formatedVerify: VerifyUserParams = {
          userId: verifyData.id,
          status: verifyData.status,
        };
        if (verifyData?.reason) {
          formatedVerify.reason = verifyData?.reason;
        }

        const { data } = await verifyUser({
          users: [formatedVerify],
        });
        if (data?.success) {
          if (verifyData.status === "Approved") {
            toast.success(`User has been successfully verified`);
          } else {
            toast.error(`User has been successfully rejected`);
          }
          setReload((prev) => !prev);
        }
      } else {
        const formatedVerify = verifyUsers.map((id) => {
          return { userId: id, status: "Approved" as "Approved" };
        });
        const { data } = await verifyUser({
          users: formatedVerify,
        });
        if (data?.success) {
          toast.success("User has been successfully verified");
          setReload((prev) => !prev);
        }
      }
    } catch (error) {
      toast.error(toastError);
    } finally {
      setIsLoading(false);
    }
    setShowConfirmModal(false);
  };
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={showConfirmModal}
        setIsOpen={setShowConfirmModal}
        text={`Verify ${verifyUsers.length} users?`}
        title="Verification"
        setIsmultiple={setIsmultiple}
        onAccept={verify}
      />

      <Layout title={"Verification"}>
        <div className={styles.navBody}>
          <div className={styles.headerWrapper}>
            <div className={styles.tabWrapper}>
              <div className={styles.input}>
                <Input
                  icon={SearchIcon}
                  placeholder="Search"
                  isEmpty={search.length === 0}
                  isSearch
                  onChange={onChangeSearch}
                  // style={{ width: "88%" }}
                  setEmpty={() => setSearch("")}
                />
              </div>
            </div>
            {verifyUsers.length > 0 &&
              !adminLoading &&
              admin?.role !== "manager" && (
                <Button
                  title={`Verify (${verifyUsers.length})`}
                  styleType="filled"
                  onClick={() => setShowConfirmModal(true)}
                  loading={isLoading}
                  icon={CheckIcon}
                  style={{
                    border: "none",
                    width: "120px",
                    height: "43px",
                    background: "#63CD5A",
                    borderRadius: "16px",
                  }}
                />
              )}
          </div>
          <PhotosVerification
            setVerifyUsers={setVerifyUsers}
            isLoading={isLoading}
            handleVerify={verify}
            search={search}
            reload={reload}
          />
        </div>
      </Layout>
    </>
  );
}
