import { ReactNode } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

export default function ContentWrapper({
  children,
  className,
  refMark,
}: {
  children: ReactNode;
  className?: {};
  refMark?: string;
}) {
  // const wrapperClass = clsx(styles.contentWrapper, className);

  return (
    <div style={className} className={clsx(styles.contentWrapper, refMark)}>
      {children}
    </div>
  );
}
