import { ChangeEvent, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import { SearchIcon } from "../../../assets";
//components
import { Empty, Input, PageItem, RequestItem } from "../../../components";
//hooks
import useDebounce from "../../../hooks/useDebounce";
//types
import { RequestType } from "../../../types";
//api
import { getAllMeetupRequests } from "../../../services/meetups.service";

const LIMIT_PER_PAGE = 10;

const Requests = ({
  setTotalRequsts,
}: {
  setTotalRequsts: (total: number | string) => void;
}) => {
  const [allRequests, setAllRequests] = useState<RequestType[]>([]);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false); // true

  const searchDebounced = useDebounce(search, 500);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetRequests = async (
    page: number = 1,
    limit: number,
    search: string = ""
  ) => {
    try {
      setIsLoading(true);
      const { data } = await getAllMeetupRequests({
        page: page,
        limit: limit,
        search: search,
      });
      if (data?.success) {
        setTotalRequsts(data?.totalRequests);
        setPagesCount(Math.ceil(data?.totalRequests / limit));
        setAllRequests(data?.requests);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetRequests(1, LIMIT_PER_PAGE, search);
  }, []);

  useEffect(() => {
    onGetRequests(page, LIMIT_PER_PAGE, searchDebounced);
  }, [searchDebounced, page]);

  return (
    <>
      <div className={styles.navBody}>
        <div className={styles.input}>
          <Input
            icon={SearchIcon}
            placeholder="Search"
            isEmpty={search.length === 0}
            isSearch
            onChange={onChangeSearch}
            // style={{ width: "88%" }}
            setEmpty={() => setSearch("")}
          />
        </div>
      </div>
      <div className={styles.wrapper}>
        <section className={styles.contentContainer}>
          <div
            className={clsx(styles.sectionHeader, styles.sectionHeaderRequests)}
          >
            <div className={styles.nameBlock}>
              <p>Name</p>
            </div>
            <div className={styles.industryBlock}>
              <p>Phone</p>
            </div>
            <div className={styles.locationBlock}>
              <p>Cover letter</p>
            </div>
            <div className={styles.sizeBlock}>
              <p>Event</p>
            </div>
            <div className={styles.sizeBlock}>
              <p>Request date</p>
            </div>
          </div>
          <div className={styles.meetupsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#236BFE"} />
              </div>
            )}
            {!isLoading &&
              allRequests.length > 0 &&
              allRequests.map((item, index) => {
                return (
                  <RequestItem
                    key={item.id}
                    item={item}
                    onGetAllStaff={() =>
                      onGetRequests(1, LIMIT_PER_PAGE, search)
                    }
                  />
                );
              })}
            {!isLoading && allRequests.length === 0 && <Empty />}
          </div>
          <div className={styles.paginationContainer}>
            {pagesCount >= 0 &&
              Array(pagesCount === 0 ? 1 : pagesCount)
                .fill("_")
                .map((item, index) => (
                  <PageItem
                    onPress={() => setPage(index + 1)}
                    key={index}
                    page={index + 1}
                    isActive={index + 1 === page}
                  />
                ))}
            {pagesCount > 1 && page !== pagesCount && (
              <div
                onClick={() => setPage((prev) => prev + 1)}
                className={styles.nextBtn}
              >
                Next
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default Requests;
