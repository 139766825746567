import VERIFICATION_STATUSES from "./verificationStatuses";

export const BASE_URL = "http://134.209.113.184:5000";
export const BASE_URL_HTTPS = "https://api.stroynik.mk";

export const toastError = "Something went wrong!";

export const verificationTabs = [
  { label: "Business Owners", value: "BusinessOwners" },
  { label: "Seekers/Explorers", value: "Seekers/Explorers" },
];

export const approveRadios = [
  { label: "Approve User", value: "approveUser" },
  { label: "Reject User", value: "rejectPhoto" },
];
export const feedbackRadios = [
  { label: "Skip this report", value: "skipThisReport" },
];
export const blockReasonSelect = [
  { label: "Minor safety", value: "Minor safety" },
  {
    label: "Adult nudity and sexual activities",
    value: "Adult nudity and sexual activities",
  },
  { label: "Bulling and harassmen", value: "Bulling and harassmen" },
  {
    label: "Illegal activities and regulated goods",
    value: "Illegal activities and regulated goods",
  },
  { label: "Other", value: "Other" },
];
export const BlockReason = [
  "Minor safety",
  "Adult nudity and sexual activities",
  "Bulling and harassment",
  "Illegal activities and regulated goods",
  "Other",
];
export const profileTabs = [
  { label: "Account Details", value: "AccountDetails" },
  { label: "Business Details", value: "BusinessDetails" },
  { label: "Posts", value: "Posts" },
];

export { VERIFICATION_STATUSES };
