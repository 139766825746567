import { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";
import { PulseLoader } from "react-spinners";

import styles from "./styles.module.scss";
//assets
import { SearchIcon, FilterIcon, ArrowDownIcon } from "../../../assets";
//compoonents
import {
  Empty,
  Input,
  Layout,
  PageItem,
  ReportItem,
  ReportsFilter,
} from "../../../components";
//types
import { ReportType } from "../../../types";
//api
import { getAllReports } from "../../../services/reports.service";
//hooks
import useDebounce from "../../../hooks/useDebounce";

const LIMIT_PER_PAGE = 10;

export default function Reports() {
  const [openFilter, setOpenFilter] = useState(false);

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false); // true
  const [reports, setReports] = useState<ReportType[]>([]);
  const [reporterSort, setReporterSort] = useState<1 | -1 | 0>(0);
  const [categorySort, setCategorySort] = useState<1 | -1 | 0>(0);
  const [reportedOnSort, setReportedOnSort] = useState<1 | -1 | 0>(0);
  const [createdAtSort, setCreatedAtSort] = useState<1 | -1 | 0>(0);
  const [typeSort, setTypeSort] = useState<1 | -1 | 0>(0);
  const [currentSorts, setCurrentSort] = useState("");
  const [currentFilter, setCurrentFilter] = useState<any>();

  const searchDebounced = useDebounce(search, 500);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetFeedback = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type:
      | { "reporterInfo.userName": 1 | -1 }
      | { type: 1 | -1 }
      | { category: 1 | -1 }
      | { "reportedOnInfo.userName": 1 | -1 }
      | { createdAt: 1 | -1 }
      | {} = {},
    filter?: any
  ) => {
    try {
      setIsLoading(true);
      const { data } = await getAllReports({
        page: page,
        limit: limit,
        search: search,
        type: type,
        filter: filter,
      });
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalFeedbacks / limit));
        setReports(data?.reports);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onApplyFilters = (
    type: "User complaint" | "Problem report" | "All",
    category: string[]
  ) => {
    let filter: any = {};
    if (type !== "All") {
      filter.type = [type];
    }
    if (category.length > 0) {
      filter.category = category;
    }
    if (Object.keys(filter).length === 0) {
      setCurrentFilter(undefined);
    } else {
      setCurrentFilter(filter);
    }
  };

  // useEffect(() => {
  //   onGetFeedback(1, LIMIT_PER_PAGE, search);
  // }, []);

  useEffect(() => {
    const sortParams: any = {};
    if (reporterSort !== 0) {
      sortParams["reporterInfo.userName"] = reporterSort;
    } else if (categorySort !== 0) {
      sortParams.category = categorySort;
    } else if (reportedOnSort !== 0) {
      sortParams["reportedOnInfo.userName"] = reportedOnSort;
    } else if (createdAtSort !== 0) {
      sortParams.createdAt = createdAtSort;
    } else if (typeSort !== 0) {
      sortParams.type = typeSort;
    }

    onGetFeedback(
      page,
      LIMIT_PER_PAGE,
      searchDebounced,
      sortParams,
      currentFilter
    );
  }, [
    reporterSort,
    categorySort,
    reportedOnSort,
    createdAtSort,
    typeSort,
    currentSorts,
    searchDebounced,
    page,
    currentFilter,
  ]);

  return (
    <Layout title={"Reports"}>
      <div className={styles.navBody}>
        <div className={styles.input}>
          <Input
            icon={SearchIcon}
            placeholder="Search"
            isEmpty={search.length === 0}
            isSearch
            onChange={onChangeSearch}
            // style={{ width: "88%" }}
            setEmpty={() => setSearch("")}
          />
        </div>
        <div className={styles.filterBody}>
          <div
            onClick={(e) => setOpenFilter((current) => !current)}
            className={clsx({
              [styles.filter]: !openFilter,
              [styles.filterSelected]: openFilter,
            })}
          >
            <FilterIcon className={clsx({ [styles.svgOpen]: openFilter })} />
          </div>
          <div
            className={clsx({
              [styles.filterDetails]: !openFilter,
              [styles.filterDetailsOpen]: openFilter,
            })}
          >
            <ReportsFilter onClose={setOpenFilter} onApply={onApplyFilters} />
            {/* <FeedbackFilter onClose={setOpenFilter} onApply={onApplyFilters} /> */}
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <section className={styles.contentContainer}>
          <div className={styles.sectionHeader}>
            <div
              onClick={() => {
                setCategorySort(0);
                setReportedOnSort(0);
                setCreatedAtSort(0);
                setTypeSort(0);
                setReporterSort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
              }}
              className={clsx(styles.nameBlock, {
                [styles.activeBlockAZ]: reporterSort === 1,
                [styles.activeBlockZA]: reporterSort === -1,
              })}
            >
              <p>Reporter</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
            </div>
            <div
              onClick={() => {
                setReporterSort(0);
                setReportedOnSort(0);
                setCreatedAtSort(0);
                setCategorySort(0);
                setTypeSort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
              }}
              className={clsx(styles.industryBlock, {
                [styles.activeBlockAZ]: typeSort === 1,
                [styles.activeBlockZA]: typeSort === -1,
              })}
            >
              <p>Type</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
            </div>
            <div
              onClick={() => {
                setReporterSort(0);
                setReportedOnSort(0);
                setCreatedAtSort(0);
                setTypeSort(0);
                setCategorySort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
              }}
              className={clsx(styles.industryBlock, {
                [styles.activeBlockAZ]: categorySort === 1,
                [styles.activeBlockZA]: categorySort === -1,
              })}
            >
              <p>Category</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
            </div>

            <div
              onClick={() => {
                setReporterSort(0);
                setCategorySort(0);
                setCreatedAtSort(0);
                setTypeSort(0);
                setReportedOnSort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
              }}
              className={clsx(styles.locationBlock, {
                [styles.activeBlockAZ]: reportedOnSort === 1,
                [styles.activeBlockZA]: reportedOnSort === -1,
              })}
            >
              <p>Reported on</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
            </div>
            <div
              onClick={() => {
                setReporterSort(0);
                setCategorySort(0);
                setReportedOnSort(0);
                setTypeSort(0);
                setCreatedAtSort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
              }}
              className={clsx(styles.sizeBlock, {
                [styles.activeBlockAZ]: createdAtSort === 1,
                [styles.activeBlockZA]: createdAtSort === -1,
              })}
            >
              <p className={styles.dateBlock}>Report date</p>{" "}
              <ArrowDownIcon fill="black" width={"13px"} />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#236BFE"} />
              </div>
            )}
            {!isLoading &&
              reports.map((item, index) => {
                return (
                  <ReportItem
                    onFeedback={onGetFeedback}
                    key={item.id}
                    item={item}
                  />
                );
              })}
          </div>
          {!isLoading && reports.length === 0 && <Empty />}
          <div className={styles.paginationContainer}>
            {pagesCount >= 0 &&
              Array(pagesCount === 0 ? 1 : pagesCount)
                .fill("_")
                .map((item, index) => (
                  <PageItem
                    onPress={() => setPage(index + 1)}
                    key={index}
                    page={index + 1}
                    isActive={index + 1 === page}
                  />
                ))}
            {pagesCount > 1 && page !== pagesCount && (
              <div
                onClick={() => setPage((prev) => prev + 1)}
                className={styles.nextBtn}
              >
                Next
              </div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
}
