import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";

// components
import { Button, Input, Modal } from "../../components";

// api
import { addMeetingLink } from "../../services/meetings.service";

// schemas
import { meetingLinkSchema } from "../../schemas/meetingSchema";

// styles
import styles from "./styles.module.scss";

interface IMeetingLinkProps {
  meetingId: string;
  meetingLink?: string | null | undefined;
  refetchMeetings: () => void;
  onClose: () => void;
}

interface IMeetingLinkFormData {
  link: string;
}

const MeetingLink = ({
  meetingId,
  meetingLink,
  refetchMeetings,
  onClose,
}: IMeetingLinkProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formData: IMeetingLinkFormData) => {
    try {
      setIsLoading(true);

      const { data } = await addMeetingLink(meetingId, formData);

      if (data?.success) {
        refetchMeetings();
        onClose();
        toast.success("Meeting has been successfully added!");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const { values, errors, handleChange, handleSubmit } =
    useFormik<IMeetingLinkFormData>({
      initialValues: {
        link: meetingLink ?? "",
      },
      validationSchema: meetingLinkSchema,
      onSubmit,
    });

  return (
    <Modal
      className={styles["meeting-link"]}
      title="Edit Meeting Link"
      open
      onClose={onClose}
    >
      <form className={styles["meeting-link__form"]} onSubmit={handleSubmit}>
        <Input
          id="link"
          label="Meeting Link"
          placeholder="Enter link"
          value={values.link}
          onChange={handleChange}
          error={errors.link}
        />
        <Button type="submit" title="Save" disabled={isLoading} />
      </form>
    </Modal>
  );
};

export default MeetingLink;
