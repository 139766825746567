import * as yup from "yup";

import validationValues from "./values";

export const signInSchema = yup
  .object({
    email: validationValues.email,
    password: validationValues.password,
  })
  .required();
