import { useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import find from "lodash/find";
import filter from "lodash/filter";
import { startOfDay, addHours, getHours } from "date-fns";
import { toast } from "react-hot-toast";

// components
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Textarea,
} from "../../components";
import { OptionType } from "../../components/Select";

// apis
import { createMeeting } from "../../services/meetings.service";

// schemas
import { addMeetingSchema } from "../../schemas/meetingSchema";

// types
import { GroupCategoryEnum } from "../../types";

// styles
import styles from "./styles.module.scss";

interface ICreateMeetingProps {
  groupId: string;
  category: GroupCategoryEnum;
  refetchMeetings: () => void;
  onClose: () => void;
}

interface ICreateMeetingFormData {
  link: string;
  description: string;
  date: Date | null;
  time: string;
}

const timeOptions = [
  {
    text: "12 AM",
    id: "12",
  },
  {
    text: "1 PM",
    id: "13",
  },
  {
    text: "2 PM",
    id: "14",
  },
  {
    text: "3 PM",
    id: "15",
  },
  {
    text: "4 PM",
    id: "16",
  },
  {
    text: "5 PM",
    id: "17",
  },
  {
    text: "6 PM",
    id: "18",
  },
  {
    text: "7 PM",
    id: "19",
  },
  {
    text: "8 PM",
    id: "20",
  },
  {
    text: "9 PM",
    id: "21",
  },
  {
    text: "10 PM",
    id: "22",
  },
  {
    text: "11 PM",
    id: "23",
  },
  {
    text: "12 PM",
    id: "0",
  },
  {
    text: "1 AM",
    id: "1",
  },
  {
    text: "2 AM",
    id: "2",
  },
  {
    text: "3 AM",
    id: "3",
  },
  {
    text: "4 AM",
    id: "4",
  },
  {
    text: "5 AM",
    id: "5",
  },
  {
    text: "6 AM",
    id: "6",
  },
  {
    text: "7 AM",
    id: "7",
  },
  {
    text: "8 AM",
    id: "8",
  },
  {
    text: "9 AM",
    id: "9",
  },
  {
    text: "10 AM",
    id: "10",
  },
  {
    text: "11 AM",
    id: "11",
  },
];

// const timeOptionsByCurrentHour = filter(
//   timeOptions,
//   (option) => +option.id > getHours(new Date())
// );

const CreateMeeting = ({
  groupId,
  category,
  refetchMeetings,
  onClose,
}: ICreateMeetingProps) => {
  const isCoaching = category === GroupCategoryEnum.relationshipCoaching;

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (
    formData: ICreateMeetingFormData,
    { setFieldError }: FormikHelpers<ICreateMeetingFormData>
  ) => {
    try {
      setIsLoading(true);

      const { date, time, link, description } = formData;

      const body = {
        startDate: addHours(startOfDay(date as Date), +time),
        link,
        description,
      };

      const { data } = await createMeeting(groupId, body);

      if (data?.success) {
        refetchMeetings();
        onClose();
        toast.success("Meeting has been successfully created!");
      }
    } catch (error: any) {
      if (error?.field) {
        return setFieldError("date", error.message);
      }

      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const { values, errors, handleChange, setFieldValue, handleSubmit } =
    useFormik<ICreateMeetingFormData>({
      initialValues: {
        date: null,
        time: timeOptions[0].id,
        description: "",
        link: "",
      },
      validationSchema: addMeetingSchema(isCoaching),
      onSubmit,
    });

  const onChangeDate = (date: Date) => {
    setFieldValue("date", date);
  };

  const onChangeTime = (time: string) => {
    setFieldValue("time", time);
  };

  return (
    <Modal
      className={styles["create-meeting"]}
      title="New Meeting"
      subtitle="We’ll create meetings on the same day for the next 4 weeks"
      open
      onClose={onClose}
    >
      <form className={styles["create-meeting__form"]} onSubmit={handleSubmit}>
        <div className={styles["create-meeting__form--row"]}>
          <DatePicker
            className={styles["create-meeting__form--row-date-picker"]}
            title="Meeting Date"
            value={values.date}
            onChange={onChangeDate}
            error={errors.date}
          />
          <div className={styles["create-meeting__form--row-time"]}>
            <Select
              label="Time"
              value={find(timeOptions, { id: values.time })?.text as string}
              options={timeOptions}
              onChange={(option: OptionType) => onChangeTime(option.id)}
            />
          </div>
        </div>
        {isCoaching ? (
          <Textarea
            id="description"
            label="General info for 4 meetings"
            placeholder="Enter info"
            value={values.description}
            onChange={handleChange}
            maxLength={500}
            rows={3}
            error={errors.description}
          />
        ) : null}
        <Input
          id="link"
          label="Meeting Link"
          placeholder="Enter link"
          value={values.link}
          onChange={handleChange}
          error={errors.link}
        />
        <Button type="submit" title="Create" disabled={isLoading} />
      </form>
    </Modal>
  );
};

export default CreateMeeting;
