import { AxiosResponse } from "axios";
import axios from "axios";

export const uploadFile = (
  file: any,
  uploadUrl: string
): Promise<AxiosResponse> => {
  console.log({ file, uploadUrl });

  return new Promise((resolve, reject) => {
    axios
      .put(uploadUrl, file, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
