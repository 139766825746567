import { UserType } from "../types";

export const showUserStatus = (user: UserType) => {
  if (!user?.status || user?.status?.unemployed) return "";
  if (user.status.employee === null) return "Employee";
  if (user.status.selfEmployed === null) return "Self Employed";

  if (user.status.employee) return user.status.employee.occupation;
  if (user.status.selfEmployed) return user.status.selfEmployed.type;
  if (user.status.student) return "Student";

  return "";
};
