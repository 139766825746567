/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// services
import { getGroupFollowers } from "../../../../services/groups.service";

const useGroupFollowers = ({ groupId, params }: any) => {
  const { page = 1, perPage = 15, sort = {} } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [groupFollowers, setGroupFollowers] = useState<any>([]);
  const [totalGroupFollowers, setTotalGroupFollowers] = useState(0);

  useEffect(() => {
    const fetchGetGroupFollowers = async () => {
      try {
        setIsLoading(true);

        const { data } = await getGroupFollowers(groupId, {
          page,
          limit: perPage,
          ...sort,
        });

        if (data?.success) {
          setGroupFollowers(data?.followers);
          setTotalGroupFollowers(data?.totalItems);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    groupId && fetchGetGroupFollowers();
  }, [groupId, sort]);

  return {
    isLoading,
    groupFollowers,
    totalGroupFollowers,
  };
};

export default useGroupFollowers;
