import { useMemo, useState } from "react";

import styles from "./styles.module.scss";
//assets
import { TrashIcon } from "../../../../assets";
//modals
import CheckImageModal from "../../../../modals/CheckImage";
//types
import { UserType } from "../../../../types";
//components
import { PrivateImage } from "../../../../components";

const PhotosDetails = ({
  user,
}: {
  user?: UserType | null; // IOwner | undefined
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const photos = useMemo(() => {
    if (!user?.photos || !user?.photos?.main) return [];

    const array = [user?.photos?.main];
    if (user?.photos?.others?.length > 0) array.push(...user?.photos?.others);

    return array;
  }, [user]);

  return (
    <>
      <CheckImageModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        image={currentImage}
      />
      {photos?.length > 0 ? (
        <div className={styles.photosWrapper}>
          {photos.map((item: string, index: number) => {
            return (
              <div
                className={styles.photosBody}
                onClick={() => {
                  setShowModal(true);
                  setCurrentImage(item);
                }}
                key={`photoDetail-${index}`}
              >
                <PrivateImage src={item} alt="Photo from user" />
                <div className={styles.trash}>
                  <TrashIcon fill="white" width={"16px"} height={"16px"} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.photosEmpty}>Nothing here yet</div>
      )}
    </>
  );
};
export default PhotosDetails;
