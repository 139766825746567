import { useRef, useState } from "react";
import { toast } from "react-hot-toast";

// components
import { Popup } from "../../../../../components";
import Delete from "../../../../../modals/Delete";

// apis
import { deleteMeeting } from "../../../../../services/meetings.service";

// hooks
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import useBoolean from "../../../../../hooks/useBoolean";

// assets
import { MoreVerticalIcon } from "../../../../../assets";

// styles
import styles from "./styles.module.scss";

interface IGroupMeetingActionsProps {
  meetingId: string;
  refetchMeetings: () => void;
}

const GroupMeetingActions = ({
  meetingId,
  refetchMeetings,
}: IGroupMeetingActionsProps) => {
  const groupMeetingRef = useRef(null);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const {
    value: isOpen,
    setTrue: showActions,
    setFalse: closeActions,
  } = useBoolean(false);

  useOutsideClick(groupMeetingRef, closeActions);

  const onDelete = () => {
    setIsShowDeleteModal(true);
    closeActions();
  };

  const onSubmitDeleteMeeting = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await deleteMeeting(meetingId);

      if (data?.success) {
        refetchMeetings();
      }

      toast.success("Meeting has been successfully deleted");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoadingDelete(false);
      setIsShowDeleteModal(false);
    }
  };

  return (
    <>
      <Delete
        title="Delete meeting"
        type="delete"
        text="Are you sure you want to delete meeting?"
        isLoading={isLoadingDelete}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        onDelete={onSubmitDeleteMeeting}
      />
      <div ref={groupMeetingRef} className={styles["group-meeting"]}>
        <div className={styles["group-meeting__button"]} onClick={showActions}>
          <MoreVerticalIcon />
        </div>
        {isOpen ? <Popup onDelete={onDelete} /> : null}
      </div>
    </>
  );
};

export default GroupMeetingActions;
