import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

type InstructorsAllParams = {
  page?: number;
  limit?: number;
  search?: string;
  category?: string;
};

type InstructorsCreateParams = {
  avatar: string | null;
  firstName: string;
  lastName: string;
  description: string;
  title: string;
};

export const getAllInstructors = async ({
  page = 1,
  limit = 10,
  search = "",
  category,
}: InstructorsAllParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page: page,
      limit: limit,
    };
    if (!!search) params.search = search;
    if (!!category) params.category = category;

    axiosInstance
      .get(`instructors`, {
        params: params,
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getInstructor = async (
  userId: string | number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`instructors/${userId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createInstructor = async ({
  avatar,
  firstName,
  lastName,
  title,
  description,
}: InstructorsCreateParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      avatar,
      firstName,
      lastName,
      title,
      description,
    };

    axiosInstance
      .post(`instructors`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const editInstructor = async (
  userId: string,
  { avatar, firstName, lastName, title, description }: InstructorsCreateParams
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      avatar,
      firstName,
      lastName,
      title,
      description,
    };

    axiosInstance
      .patch(`instructors/${userId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteInstructor = async (
  userId: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`instructors/${userId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getInstructorGroups = async (
  userId: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`instructors/${userId}/groups`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
