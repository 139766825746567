import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
//assets
import { CloseIcon } from "../../assets";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
//components
import { Input, Button, Textarea, PrivateImage } from "../../components";
//schemas
import { newInstructorSchema } from "../../schemas";
//api
import { getLinkForUploadImage } from "../../services/auth.service";
import {
  createInstructor,
  editInstructor,
} from "../../services/instructors.service";
import { uploadFile } from "../../services/file.service";
//constants
import { toastError } from "../../constants";
//types
import { InstructorType } from "../../types";

const LIMIT_PER_PAGE = 10;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onGetAll?: (page: number, limit: number) => void;
  item?: InstructorType | null;
  onUpdate?: () => void;
}

const InstructorModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  onGetAll,
  item,
  onUpdate,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [commonError, setCommonError] = useState("");
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const [avatarFile, setAvatarFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >(null);
  const [avatarFileName, setAvatarFileName] = useState<string>("");
  const inputFile = useRef<HTMLInputElement>(null);

  const onCreate = async (values: {
    firstName: string;
    lastName: string;
    title: string;
    about: string;
  }) => {
    try {
      setIsLoading(true);

      let imageLink = "";
      if (avatarFile && avatarFileName && avatar) {
        //@ts-ignore
        const resLinkImg = await getLinkForUploadImage(avatarFileName);
        imageLink = resLinkImg?.data?.links[0].downloadUrl;
        let blob = await fetch(avatar).then((r) => r.blob());
        const resUploadImg = await uploadFile(
          blob,
          resLinkImg?.data?.links[0].uploadUrl
        );
      }

      if (item) {
        const { data } = await editInstructor(item.id, {
          avatar: avatarFileName ? imageLink || null : avatarFile,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title,
          description: values.about,
        });
        if (data?.success) {
          onUpdate && onUpdate();
          setIsOpen(false);
          setAvatar(undefined);
          setAvatarFile(undefined);
          setAvatarFileName("");
          toast.success("Instructor has been successfully updated");
        }
      } else {
        const { data } = await createInstructor({
          avatar: imageLink || null,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title,
          description: values.about,
        });
        if (data?.success) {
          onGetAll && onGetAll(1, LIMIT_PER_PAGE);
          setIsOpen(false);
          setAvatar(undefined);
          setAvatarFile(undefined);
          setAvatarFileName("");
          toast.success("Instructor has been successfully created");
        }
      }

      setIsLoading(false);
    } catch (error: any) {
      console.log("onCreate error", error);
      setIsLoading(false);
      toast.error(toastError);
      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  const { values, errors, handleSubmit, handleChange, touched, setValues } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        title: "",
        about: "",
      },
      validationSchema: newInstructorSchema,
      validateOnChange: true,
      onSubmit: onCreate,
    });

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setAvatar(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];
      setAvatarFile(e.target.files[0]);
      setAvatarFileName(file?.name);
    } catch (error) {}
  };

  const onDeleteAvatar = () => {
    setAvatarFile(null);
    setAvatar(undefined);
    setAvatarFileName("");
  };

  useEffect(() => {
    if (item && !isOpen) {
      console.log(item);
      setValues({
        firstName: item.firstName,
        lastName: item.lastName,
        title: item.title,
        about: item.description,
      });
      setAvatar(item?.avatar || undefined);
    }
  }, [item, isOpen]);

  useEffect(() => {
    setCommonError("");
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>{!!item ? "Edit" : "New"} Instructor</h5>{" "}
          <CloseIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.profileSection}>
            <div className={styles.profileBody}>
              <div className={styles.avatarBody}>
                <div onClick={showOpenFileDialog} className={styles.avatarBtn}>
                  <PrivateImage
                    src={avatar}
                    defaultsrc={PlaceholderAvatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                </div>
              </div>
              <div className={styles.avatarInfoBody}>
                <div>
                  <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    type={"file"}
                    accept="image/*"
                    onChange={onSelectFile}
                    onClick={(e: any) => (e.target.value = null)}
                  />
                  <span onClick={showOpenFileDialog}>Change Avatar</span>
                </div>
                {avatar && (
                  <span className={styles.deleteBody} onClick={onDeleteAvatar}>
                    Delete
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.dividerX}></div>
          <div className={styles.form}>
            <div className={styles.names}>
              <Input
                id="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && errors.firstName}
                maxLength={15}
                wrapperStyles={styles.input}
              />
              <Input
                id="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && errors.lastName}
                maxLength={15}
                wrapperStyles={styles.input}
              />
            </div>
            <div className={styles.title}>
              <Input
                id="title"
                label="Title"
                value={values.title}
                onChange={handleChange}
                error={touched.title && errors.title}
                wrapperStyles={styles.input}
              />
            </div>
            <div className={styles.about}>
              <Textarea
                id="about"
                label="About Instructor"
                value={values.about}
                onChange={handleChange}
                maxLength={240}
                rows={3}
                error={touched.about && errors.about}
                wrapperStyles={styles.input}
              />
            </div>
            {commonError && <p className={styles.commonError}>{commonError}</p>}
            <Button
              title={!!item ? "Save" : "Create"}
              onClick={() => handleSubmit()}
              disabled={isLoading}
              type="submit"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InstructorModal;
