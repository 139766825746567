import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

type UsersAllParams = {
  page?: number;
  limit?: number;
  search?: string;
  type?:
    | { userName: 1 | -1 }
    | { "location.name": 1 | -1 }
    | { status: 1 | -1 }
    | { createdAt: 1 | -1 }
    | {};
  filter?: any;
};

type UsersPostsParams = {
  userId?: string | number;
  page?: number;
  limit?: number;
};

type BlockUserParams = {
  userId: number | string;
  status: "block" | "unblock";
  reason?: string;
};

export const getAllUsers = async ({
  page = 1,
  limit = 10,
  search = "",
  type,
  filter,
}: UsersAllParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {};
    if (!!search) body.search = search;
    if (!!type) body.sort = { ...type };
    if (!!filter) body.filter = { ...filter };

    axiosInstance
      .post(`user/all?page=${page}&limit=${limit}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getUser = async (
  userId: string | number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`user/info?userId=${userId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const getUserPosts = async ({
  userId,
  page = 1,
  limit = 10,
}: UsersPostsParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/post/user/all?userId=${userId}&page=${page}&limit=${limit}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const blockUser = async ({
  userId,
  status,
  reason,
}: BlockUserParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = { userId };
    if (!!reason) body.reason = reason;

    axiosInstance
      .patch(`user/${status}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteUser = async (
  userId: string | number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`user/delete?userId=${userId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const addPaidVideoToUser = async ({
  userId,
  paidVideoInterview,
}: {
  userId: string | number;
  paidVideoInterview: string | null;
}): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`user/paid-video-interview`, {
        paidVideoInterview,
        userId,
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
