import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

type StaffAllParams = {
  page?: number;
  limit?: number;
  search?: string;
  type?:
    | { firstName: 1 | -1 }
    | { lastName: 1 | -1 }
    | { email: 1 | -1 }
    | { role: 1 | -1 }
    | {};
};

export const getAllStaff = async ({
  page = 1,
  limit = 10,
  search = "",
  type,
}: StaffAllParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {};
    if (!!search) body.search = search;
    if (!!type) body.sort = { ...type };

    axiosInstance
      .post(`admin/all?page=${page}&limit=${limit}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createStaff = async ({
  avatar,
  firstName,
  lastName,
  email,
  password,
  role,
}: any): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      avatar,
      firstName,
      lastName,
      email,
      password,
      role,
    };

    axiosInstance
      .post(`admin/create`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const deleteStaff = async (userId: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/delete?userId=${userId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const editStaff = async ({
  userId,
  avatar,
  firstName,
  lastName,
  email,
  password,
  role,
}: any): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      firstName,
      lastName,
      email,
      role,
      avatar,
    };
    if (password) body.password = password;

    axiosInstance
      .patch(`admin/update?userId=${userId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
