import { useState } from "react";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";
//components
import StaffItem from "../StaffItem";
import ActiveButton from "../ActiveButtons/ActiveButton";
import DeclineButton from "../ActiveButtons/DeclineButton";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
//types
import { RequestType } from "../../types";
//modals
import AgreementModal from "../../modals/Agreement";
//apis
import { resolveMeetupRequest } from "../../services/meetups.service";
//constants
import { toastError } from "../../constants";

const RequestItem = ({
  item,
  onGetAllStaff,
}: {
  item: RequestType;
  onGetAllStaff: () => void;
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showUnConfirmModal, setShowUnConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatedDate = useFormatDate(item?.createdAt || "");

  const resolveUserRequest = async (status: "Approved" | "Declined") => {
    try {
      setIsLoading(true);
      const { data } = await resolveMeetupRequest({ id: item.id, status });
      if (data?.success) {
        onGetAllStaff();
        toast.success(
          `User request have been successfully ${status?.toLowerCase()}`
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(toastError);
    }
  };

  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={showConfirmModal}
        setIsOpen={setShowConfirmModal}
        text="Accept user request?"
        title="User Request"
        onAccept={() => resolveUserRequest("Approved")}
      />
      <AgreementModal
        isLoading={isLoading}
        isOpen={showUnConfirmModal}
        setIsOpen={setShowUnConfirmModal}
        text="Decline user request?"
        title="User Request"
        onAccept={() => resolveUserRequest("Declined")}
      />
      <div className={styles.businessBody}>
        <div className={styles.wrapper}>
          <div className={styles.eventBody}>
            <StaffItem
              image={item?.author?.photos?.main || undefined}
              name={item?.name}
              email={item?.email}
            />
          </div>
          <div className={styles.phone}>
            <p>{item?.phone}</p>
          </div>
          <div className={styles.letter}>
            <p>{item?.coverLetter}</p>
          </div>
          <div className={styles.event}>
            <p>{item?.meetup?.title}</p>
          </div>
          <div className={styles.date}>
            <p>{formatedDate ? formatedDate : ""}</p>
          </div>
          <div className={styles.acceptButtons}>
            <div
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              <ActiveButton />
            </div>
            <div
              onClick={() => {
                setShowUnConfirmModal(true);
              }}
            >
              <DeclineButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestItem;
