import clsx from "clsx";

import styles from "./styles.module.scss";
//components
import Tag from "../Tag";

type Option = {
  label: string;
  value: string;
};

type TagType = number | string;

type RadioButtonsProps = {
  name: string;
  options: Option[];
  value: string;
  onChange: (value: Partial<string>) => void;
  style?: {};
  tag?: TagType[];
};

type RadioProps = {
  name: string;
  value: string;
  checked: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: {};
  tag?: TagType;
};

function Radio({
  name,
  value,
  checked,
  label,
  onChange,
  style,
  tag,
}: RadioProps) {
  return (
    <label
      style={style}
      className={clsx({ [styles.radio]: !checked, [styles.selected]: checked })}
    >
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label}
      {!!tag && (
        <div className={styles.tag}>
          <Tag
            style={{ fontWeight: 700 }}
            label={`${tag}`}
            isSelected={checked}
          />
        </div>
      )}
    </label>
  );
}

function RadioButtons({
  name,
  options,
  value,
  onChange,
  style,
  tag,
}: RadioButtonsProps) {
  return (
    <>
      {options.map((option, index) => (
        <Radio
          key={option.value}
          name={name}
          value={option.value}
          label={option.label}
          checked={option.value === value}
          onChange={(e) => onChange(e.target.value)}
          style={style}
          tag={tag?.[index]}
        />
      ))}
    </>
  );
}

export default RadioButtons;
