import { CheckIcon } from "../../assets";
import clsx from "clsx";
import React, { FC } from "react";
import styles from "./styles.module.scss";

interface Props {
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  iconSize?: number;
  mainCheckBox?: boolean;
}

const Checkbox = ({
  isChecked,
  setIsChecked,
  iconSize = 14,
  mainCheckBox = false,
}: Props) => {
  return (
    <div
      onClick={() => setIsChecked(!isChecked)}
      className={clsx(styles.checkbox, {
        [styles.checkboxChecked]: isChecked,
        [styles.checkboxMain]: mainCheckBox && isChecked,
      })}
    >
      {isChecked && !mainCheckBox && (
        <CheckIcon width={iconSize} height={iconSize} color="white" />
      )}
    </div>
  );
};

export default Checkbox;
