/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// services
import { getGroupMeetings } from "../../../../services/groups.service";

const useGroupMeetings = ({ groupId, params }: any) => {
  const { page = 1, perPage = 15, sort = {} } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [groupMeetings, setGroupMeetings] = useState([]);
  const [totalGroupMeetings, setTotalGroupMeetings] = useState(0);

  const fetchGetMeetings = async () => {
    try {
      setIsLoading(true);

      const { data } = await getGroupMeetings(groupId as string, {
        page,
        limit: perPage,
        ...sort,
      });

      if (data?.success) {
        setGroupMeetings(data?.meetings);
        setTotalGroupMeetings(data?.totalItems);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    groupId && fetchGetMeetings();
  }, [groupId, page, sort]);

  return {
    isLoading,
    groupMeetings,
    totalGroupMeetings,
    refetch: fetchGetMeetings,
  };
};

export default useGroupMeetings;
