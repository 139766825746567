import * as yup from "yup";

export const newMeetupSchema = yup.object().shape({
  title: yup
    .string()
    .required("Event title is a required field")
    .min(2, "Event title field should contain more than 2 characters")
    .max(50, "Event title field should not exceed 50 characters"),
  price: yup
    .number()
    .typeError("Event price must be a number")
    .required("Event price is a required field")
    .min(1, "Event price must be greater than 1"),
  // .max(50, "Event price field should not exceed 50 characters"),
  organizersName: yup
    .string()
    .required("Organizer’s name is a required field")
    .min(2, "Organizer’s name field should contain more than 2 characters")
    .max(50, "Organizer’s name field should not exceed 50 characters"),
  location: yup
    .object()
    .shape({
      name: yup
        .string()
        .required("Event location is a required field")
        .min(2, "Event location field should contain more than 2 characters")
        .max(50, "Event location field should not exceed 50 characters"),
      coordinates: yup.object().shape({
        latitude: yup.string(),
        longitude: yup.string(),
      }),
    })
    .required("Event location is a required field"),
  date: yup.date().required("Date is a required field"),
  time: yup.string().trim().required("Time is a required field"),
  minParticipants: yup
    .number()
    .typeError("MIN participants must be a number")
    .required("MIN participants is a required field")
    .min(3, "MIN participants must be greater than 3")
    .max(100, "MIN participants must be less than 100"),
  maxParticipants: yup
    .number()
    .typeError("MAX participants must be a number")
    .required("MAX participants is a required field")
    .min(3, "MAX articipants must be greater than 3")
    .max(100, "MAX articipants must be less than 100")
    .moreThan(
      yup.ref("minParticipants"),
      "MAX articipants must be more than MIN"
    ),
  minAge: yup
    .number()
    .typeError("MIN age price must be a number")
    .required("MIN age is a required field")
    .min(18, "MIN age must be at least 18 years old")
    .max(99, "MIN age must be under 100 years old"),
  description: yup
    .string()
    .trim()
    .required("Description is a required field")
    .min(2, "Description field should contain more than 2 characters")
    .max(500, "Description field should not exceed 500 characters"),
});
