import { ReactNode, useState } from "react";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import { PulseLoader } from "react-spinners";

// components
import Empty from "../Empty";

// hooks
import useIsMounted from "../../hooks/useIsMounted";

// assets
import { ArrowDownIcon } from "../../assets";

// styles
import styles from "./styles.module.scss";

interface ITableProps {
  loading?: boolean;
  columns: Array<{
    header: string;
    accessor: string;
    Cell?: any;
    width?: number;
    bold?: boolean;
    sorting?: boolean;
  }>;
  data: Array<any>;
  handleSortBy: (sort: any) => void;
  footer: ReactNode;
}

const orderBy: any = {
  "1": "desc",
  "-1": "asc",
};

const Table = ({
  loading = false,
  columns,
  data = [],
  handleSortBy,
  footer = null,
}: ITableProps) => {
  const isMounted = useIsMounted();

  const isEmptyData = isEmpty(data);
  const [sortBy, setSortBy] = useState<Record<string, number>>({});

  const onChangeSortValue = (key: string) => {
    return () => {
      const currentValue = sortBy[key];
      const newValue = currentValue === 1 ? -1 : 1;

      const newSortBy = currentValue !== -1 ? { [key]: newValue } : {};

      setSortBy(newSortBy);
      handleSortBy && handleSortBy(newSortBy);
    };
  };

  return (
    <>
      <div className={styles.table}>
        <div className={styles.table__head}>
          {columns.map(
            (
              {
                header,
                accessor,
                width = "100%",
                align = "left",
                sorting = false,
              }: any,
              index: number
            ) => {
              return (
                <div
                  key={`head-${accessor}-${index}`}
                  className={clsx(
                    styles["table__head--col"],
                    {
                      [styles["table__head--col-sorting"]]: sorting,
                    },
                    styles[
                      `table__head--col-${
                        orderBy[sortBy[accessor]?.toString()]
                      }`
                    ]
                  )}
                  style={{ maxWidth: width, justifyContent: align }}
                  onClick={
                    !isEmptyData && sorting
                      ? onChangeSortValue(accessor)
                      : undefined
                  }
                >
                  <span>{header}</span>
                  {sorting ? (
                    <ArrowDownIcon
                      className={styles["table__head--col-arrow"]}
                    />
                  ) : null}
                </div>
              );
            }
          )}
        </div>
        <div className={styles.table__body}>
          {loading ? (
            <div className={styles["table__body--loader"]}>
              <PulseLoader color={"#236BFE"} />
            </div>
          ) : null}

          {data.map((value: any, rowIndex: number) => (
            <div key={`row-${rowIndex}`} className={styles["table__body--row"]}>
              {columns.map(
                (
                  {
                    accessor,
                    Cell = null,
                    width = "100%",
                    align = "left",
                    bold = false,
                    overflow = "initial",
                  }: any,
                  colIndex: number
                ) => {
                  return (
                    <div
                      key={`row-${rowIndex}-col-${colIndex}`}
                      className={styles["table__body--col"]}
                      style={{
                        maxWidth: width,
                        justifyContent: align,
                        fontWeight: bold ? "700" : "400",
                        overflow: overflow,
                      }}
                    >
                      {Cell ? Cell(value) : value[accessor]}
                    </div>
                  );
                }
              )}
            </div>
          ))}

          {isMounted && isEmpty(data) && !loading ? <Empty /> : null}
        </div>
      </div>
      {footer}
    </>
  );
};

export default Table;
