import React from "react";

import styles from "./styles.module.scss";
//assets
import {
  BehabitsIcon,
  EducationIcon,
  EyeInterestIcon,
  FaithIcon,
  GlassIcon,
  HeightIcon,
  MarijuanaIcon,
  MoneyIcon,
  WeightIcon,
  WorkIcon,
} from "../../assets";

export default function InterestTag({
  isInterest,
  item,
}: {
  isInterest?: boolean;
  item: { value: string; topic?: string };
}) {
  const correctIcon = (topic?: string) => {
    switch (topic) {
      case "education":
        return <EducationIcon />;
      case "work":
        return <WorkIcon />;
      case "money":
        return <MoneyIcon />;
      case "height":
        return <HeightIcon />;
      case "weight":
        return <WeightIcon />;
      case "eye":
        return <EyeInterestIcon />;
      case "behabits":
        return <BehabitsIcon />;
      case "alcohol":
        return <GlassIcon />;
      case "marijuana":
        return <MarijuanaIcon />;
      case "faith":
        return <FaithIcon />;
      default:
        return <></>;
    }
  };
  return (
    <div className={styles.interestTagBody}>
      {isInterest && correctIcon(item.topic)}
      <p>{item.value}</p>
    </div>
  );
}
