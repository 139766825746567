import clsx from "clsx";
import React, {
  FunctionComponent,
  InputHTMLAttributes,
  SVGProps,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./styles.module.scss";
//assets
import { CancelInputIcon } from "../../assets";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string | boolean;
  wrapperStyles?: any;
  isShow?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  isSearch?: boolean;
  isEmpty?: boolean;
  setEmpty?: () => void;
  style?: {};
}

export default function Input({
  label = "",
  error,
  isShow,
  wrapperStyles = {},
  icon: Icon,
  style,
  isSearch,
  isEmpty,
  setEmpty,
  ...props
}: Props) {
  const [isShowField, setIShowField] = useState(false);
  const onToggleShow = () => {
    setIShowField((current) => !current);
  };

  useEffect(() => {
    const searchInput = document.querySelector(".search__input");
    if (isEmpty && searchInput) {
      //@ts-ignore
      searchInput.value = "";
    }
  }, [isEmpty]);

  return (
    <div className={clsx(styles.input, wrapperStyles)}>
      {label && <span>{label}</span>}
      <div className={styles.inputWrapper}>
        <div className={styles.icon}>{Icon && <Icon />}</div>
        <input
          className={clsx(
            styles.input__main,
            {
              [styles.error]: error,
              [styles.withIcon]: Icon,
            },
            "search__input"
          )}
          {...props}
          type={isShowField ? "text" : props.type}
          style={style}
        />
        {isSearch && !isEmpty && (
          <div
            onClick={() => {
              setEmpty?.();
            }}
            className={styles.cancelRemoveInput}
          >
            <CancelInputIcon />
          </div>
        )}
        {isShow && (
          <span onClick={onToggleShow} className={styles.show}>
            {isShowField ? "Hide" : "Show"}
          </span>
        )}
      </div>
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
}
