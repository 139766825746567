import React, { useEffect } from "react";

export const useOutsideClick = (
  ref: React.RefObject<any>,
  callback: (event?: Event) => void,
  callbackInside?: (event?: Event) => void
) => {
  const handleOutsideClick: EventListener = (event: any) => {
    const path = event.path || (event.composedPath && event.composedPath());

    if (!path.includes(ref.current)) {
      callback(event);
    } else {
      callbackInside && callbackInside(event);
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => document.body.removeEventListener("click", handleOutsideClick);
  }, [handleOutsideClick]);
};
