export function createNumArray(to, from = 1, inStr = false) {
  var arr = [];
  for (let i = from; i <= to; i++) {
    arr.push(inStr ? `${i}` : i);
  }
  return arr;
}

export function converCmToFeetInch(cm) {
  const realFeet = (cm * 0.3937) / 12;
  let feet;

  if (realFeet % 1 > 0.93) {
    feet = Math.round(realFeet);
  } else {
    feet = Math.floor(realFeet);
  }

  const inches = Math.round((realFeet - feet) * 12);

  return { feet, inches };
}

export function converFeetInchToCm(feet, inches) {
  return feet * 30.48 + inches * 2.54;
}

export function converKgToLbs(kg) {
  let lbs = kg * 2.20462;

  if (lbs % 1 > 0.97) {
    lbs = Math.round(lbs);
  }

  const fullLbs = Math.floor(lbs);
  let decimal;

  if (lbs % 1 > 0) {
    decimal = `.${(lbs % 1).toFixed(1).toString()[2]}`;
  } else {
    decimal = `.0`;
  }

  return { lbs: fullLbs, decimal };
}

export function converLbsToKg(lbs) {
  return lbs * 0.45359237;
}

export function converAnnualWithComa(annual) {
  let annualStr = annual.toString();
  annualStr = annualStr.replace(/\./g, ",");

  return annualStr;
}

export function converAnnualToNumber(annual) {
  annual = annual.replace(/\,/g, ".");

  return annual;
}
