import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

type ExplorerPeriod = {
  users: number;
  name: string;
};

export type ExplorersPercent = {
  totalUsers: number;
  totalDeletedUsers: number;
  verifiedUsers: number;
  totalPosts: number;
  totalMatches: number;
  totalPeriod: {
    name: string;
    users: number;
  }[];
  newUsersToday: number;
  deletedUsersToday: number;
  verifiedUsersToday: number;
  newPostsToday: number;
  newMatchesToday: number;
  newUsersPercent: number;
  deletedUsersPercent: number;
  verifiedUsersPercent: number;
  newPostsPercent: number;
  newMatchesPercent: number;
};

export const getStatistics = async (
  period: "week" | "month" | "year"
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`statistic?period=${period}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
