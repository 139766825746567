import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { ArrowDownIcon } from "../../assets";
//components
import StaffItem from "../StaffItem";
import ReportsType from "../ReportsType";
//modals
import ReportModal from "../../modals/Report";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
//types
import { ReportType, ReportUserType } from "../../types";

const ReportItem = ({
  item,
  onFeedback,
}: {
  item: ReportType;
  onFeedback: (page: number, limit: number, search: string) => void;
}) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const formatedDate = useFormatDate(item.createdAt);

  return (
    <>
      <ReportModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        reporter={item.reporterInfo}
        reportedOn={item.reportedOnInfo}
        category={item.category}
        userId={item.reporter}
        feedBackId={item.id}
        date={item.createdAt}
        reportedId={item.reportedOn}
        onFeedback={onFeedback}
        description={item.description}
        attachmentImage={item.attachment || undefined}
      />
      <div className={styles.businessBody}>
        <div
          className={styles.wrapper}
          onClick={() => setIsOpenModal((current) => !current)}
        >
          <div>
            {item?.reporterInfo ? (
              <StaffItem
                image={item?.reporterInfo?.photos?.main || undefined}
                name={item?.reporterInfo?.userName || ""}
                verificationDocument={item?.reporterInfo?.verificationDocument}
              />
            ) : (
              <StaffItem image={undefined} name={`Deleted user`} />
            )}
          </div>
          <div className={styles.type}>
            <ReportsType
              isComplaint={item.type === "User complaint" ? true : false}
            />
          </div>
          <div className={styles.type}>
            <p>{item.category}</p>
          </div>
          <div className={styles.reportedOn}>
            {item.reportedOnInfo && item.reportedOnInfo?.id ? (
              <StaffItem
                small
                image={item?.reportedOnInfo?.photos?.main || undefined}
                name={item?.reportedOnInfo?.userName || ""}
                verificationDocument={
                  item?.reportedOnInfo?.verificationDocument
                }
              />
            ) : (
              <div style={{ opacity: 0 }}>
                <StaffItem image={undefined} name={`Deleted user`} />
              </div>
            )}
          </div>
          <div className={styles.date}>
            <p>{formatedDate}</p>
          </div>
          <div className={styles.select}>
            <div className={styles.arrowBtn}>
              <ArrowDownIcon width={"15px"} fill="white" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReportItem;
