import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { ArrowDownIcon } from "../../assets";
//components
import CheckBox from "../Checkbox";
import StaffItem from "../StaffItem";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
//types
import { UserType } from "../../types";
//utils
import { showUserStatus } from "../../utils";

interface Props {
  id: string;
  image: null;
  name: string;
  description: string;
  ownerLocation: string;
  businessLocation: string;
  businessType: string;
  revenue: string;
  employees: string;
}

const UsersItem = ({
  item,
  allChecked,
  setDeleteOwners,
}: {
  item: UserType;
  allChecked: boolean;
  setDeleteOwners: (item: any) => void;
}) => {
  const navigate = useNavigate();
  const formatedDate = useFormatDate(item.createdAt || "");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);

  useEffect(() => {
    if (isChecked) {
      setDeleteOwners((current: any) => [...current, item.id]);
    }
    if (!isChecked)
      setDeleteOwners((current: any) =>
        current.filter((id: any) => id != item.id)
      );
  }, [isChecked]);

  const userPhoto = useMemo(
    () => (item?.photos?.main ? item.photos.main : undefined),
    [item]
  );

  return (
    <div className={styles.businessBody}>
      <div className={styles.wrapper}>
        {/* <div className={styles.checkbox}>
          <CheckBox
            isChecked={isChecked}
            setIsChecked={() => setIsChecked((current) => !current)}
          />
        </div> */}
        <div
          className={styles.staffBody}
          onClick={() => navigate(`/users/${item.id}`)}
        >
          <StaffItem
            image={userPhoto}
            name={item.userName}
            verificationDocument={item?.verificationDocument}
          />
        </div>
        <div onClick={() => navigate(`/users/${item.id}`)}>
          <div className={styles.ownerLocationWrapper}>
            <p> {item.location?.name ? `${item?.location.name}` || "" : ""}</p>
          </div>
        </div>
        <div onClick={() => navigate(`/users/${item.id}`)}>
          <div className={styles.businessLocationWrapper}>
            {/* <p>{item.career ? item.career : ""}</p> */}
            {item?.status && showUserStatus(item)}
          </div>
        </div>
        <div onClick={() => navigate(`/users/${item.id}`)}>
          <div className={styles.businessLocationWrapper}>
            <p>{formatedDate ? formatedDate : ""}</p>
          </div>
        </div>
      </div>
      <div
        onClick={() => navigate(`/users/${item.id}`)}
        className={styles.select}
      >
        <div className={styles.arrowBtn}>
          <ArrowDownIcon width={"15px"} fill="white" />
        </div>
      </div>
    </div>
  );
};
{
  /* <div className={styles.dateWrapper}>

<div className={styles.arrowBtn}>
  <ArrowDownIcon width={"15px"} fill="white" />
</div>
</div> */
}
export default UsersItem;
