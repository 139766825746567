import React, { useEffect, useRef, useState } from "react";

import styles from "./style.module.scss";
//components
import ContentWrapper from "../Layout/ContentWrapper";
import RadioSettings from "../RadioSelect";
import Button from "../Button";
import Checkbox from "../Checkbox";

export default function ReportsFilter({
  onClose,
  onApply,
  users,
}: {
  onClose: (value: boolean) => void;
  onApply: (
    type: "User complaint" | "Problem report" | "All",
    category: string[]
  ) => void;
  users?: any;
}) {
  const [type, setType] = useState("All");
  const [reporters, setReporters] = useState("All");
  const [businessType, setBusinessType] = useState("All");
  const [revenue, setRevenue] = useState("All");
  const [employees, setEmployees] = useState("All");
  const [isUserBehavior, setIsUserBehavior] = useState(false);
  const [isSexual, setIsSexual] = useState(false);
  const [violence, setViolence] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [isFalseInformation, setIsFalseInformation] = useState(false);
  const [isScam, setIsScam] = useState(false);
  const [isProblemTransactions, setIsProblemTransactions] = useState(false);
  const [reportsFrom, setReportsFrom] = useState();
  const [location, setLocation] = useState<{
    city: string;
    state?: string;
    country: string;
  }>({
    city: "",
    state: "",
    country: "",
  });
  useEffect(() => {
    if (users) setReportsFrom(users[0]);
  }, [users]);

  const onClearHandler = () => {
    setType("All");
    setLocation({ city: "", country: "", state: "" });
    setBusinessType("All");
    setRevenue("All");
    setEmployees("All");
    setIsAll(false);
    setReporters("All");
    setIsUserBehavior(false);
    setIsFalseInformation(false);
    setViolence(false);
    setIsSexual(false);
    setIsScam(false);
    setIsProblemTransactions(false);
    if (users) {
      setReportsFrom(users[0]);
    }
  };

  useEffect(() => {
    setIsUserBehavior(isAll);
    setIsFalseInformation(isAll);
    setIsScam(isAll);
    setIsSexual(isAll);
    setViolence(isAll);
    setIsProblemTransactions(isAll);
  }, [isAll]);

  return (
    <ContentWrapper>
      <div className={styles.filterHeader}>
        <p>Filter by</p> <span onClick={onClearHandler}>Clear all filters</span>
      </div>{" "}
      <>
        <div className={styles.radioBody}>
          <p>Type</p>
          <RadioSettings
            options={[
              { label: "All", value: "All" },
              { label: "User complaint", value: "User complaint" },
              { label: "Problem report", value: "Problem report" },
            ]}
            name="type"
            value={type}
            onChange={setType}
          />
        </div>
        <div className={styles.dividerX}></div>
        <div className={styles.radioBody}>
          <p>Category</p>
          <div className={styles.checkboxBody}>
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isAll} setIsChecked={setIsAll} />
              <span>All</span>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                isChecked={isUserBehavior}
                setIsChecked={setIsUserBehavior}
              />
              <span>User’s behaviour</span>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isSexual} setIsChecked={setIsSexual} />
              <span>False information</span>
            </div>

            <div className={styles.checkboxContainer}>
              <Checkbox
                isChecked={isFalseInformation}
                setIsChecked={setIsFalseInformation}
              />
              <span>Bad content</span>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isScam} setIsChecked={setIsScam} />
              <span>Found issue</span>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={violence} setIsChecked={setViolence} />
              <span>Feedback</span>
            </div>
            {/* <div className={styles.checkboxContainer}>
              <Checkbox
                isChecked={isProblemTransactions}
                setIsChecked={setIsProblemTransactions}
              />
              <span>Problem with website</span>
            </div> */}
          </div>
        </div>
      </>
      <div
        onClick={() => {
          onClose(false);
          let categoryFilter: string[] = [];
          if (isUserBehavior)
            categoryFilter = [...categoryFilter, "User’s behaviour"];
          if (isFalseInformation)
            categoryFilter = [...categoryFilter, "Bad content"];
          if (isSexual)
            categoryFilter = [...categoryFilter, "False information"];
          if (isScam) categoryFilter = [...categoryFilter, "Found issue"];
          if (violence) categoryFilter = [...categoryFilter, "Feedback"];
          // if (isProblemTransactions)
          //   categoryFilter = [...categoryFilter, "Problem with website"];
          onApply?.(type as "All", categoryFilter);
        }}
        className={styles.applyButton}
      >
        <Button title="Apply" />
      </div>
    </ContentWrapper>
  );
}
