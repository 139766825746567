import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import { MoreVerticalIcon } from "../../assets";
//components
import Popup from "../Popup";
//modals
import Delete from "../../modals/Delete";
import GroupModal from "../../modals/Group";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
import { useOutsideClick } from "../../hooks/useOutsideClick";
//types
import { GroupCategoryEnum, GroupCategoryObj, GroupType } from "../../types";
//constants
import { toastError } from "../../constants";
//api
import { deleteGroup } from "../../services/groups.service";

const GroupItem = ({
  item,
  onGetAll,
}: {
  item: GroupType;
  onGetAll: () => any;
}) => {
  const navigate = useNavigate();
  const [showPopap, setShowPopap] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const formatedDate = useFormatDate(item.createdAt || "");

  const onEdit = () => {
    setShowPopap(false);
    setIsOpenEditModal(true);
  };

  const onDelete = async () => {
    setIsShowDeleteModal(true);
    setShowPopap(false);
  };

  const confirmedDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteGroup(item.id);
      if (data?.success) {
        onGetAll();
      }
      setIsLoadingDelete(false);
      toast.success("Group has been successfully deleted");
    } catch (error) {
      toast.error(toastError);
      setIsLoadingDelete(false);
    }
    setIsShowDeleteModal(false);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, (e) => {
    setShowPopap(false);
  });

  const navigateToGroup = () => {
    navigate(`/groups/${item.id}`);
  };

  return (
    <>
      <GroupModal
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        onGetAll={onGetAll}
        item={item}
      />
      <Delete
        title="Delete group"
        isLoading={isLoadingDelete}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        onDelete={confirmedDelete}
        type="delete"
        text="Are you sure you want to delete this group?"
      />
      <div className={styles.wrapper}>
        <div className={styles.name} onClick={navigateToGroup}>
          {item.category === GroupCategoryEnum.relationshipCoaching
            ? item.title
            : GroupCategoryObj[GroupCategoryEnum.meetAndGreet]}
        </div>
        <div className={styles.category} onClick={navigateToGroup}>
          {GroupCategoryObj[item.category]}
        </div>
        <div className={styles.followers} onClick={navigateToGroup}>
          {item.followersCount}
        </div>
        <div className={styles.date} onClick={navigateToGroup}>
          {formatedDate ? formatedDate : ""}
        </div>
        <div className={clsx(styles.select)} ref={ref}>
          <div
            onClick={() => {
              setShowPopap((current) => !current);
            }}
            className={styles.selectBtn}
          >
            <MoreVerticalIcon />
          </div>
          {showPopap && (
            <Popup
              onEdit={
                item.category === GroupCategoryEnum.relationshipCoaching
                  ? onEdit
                  : undefined
              }
              onDelete={onDelete}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GroupItem;
