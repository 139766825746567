/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// services
import { getGroup } from "../../../../services/groups.service";

// types
import { GroupDetailType } from "../../../../types";

const useGroup = () => {
  const { id: groupId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState<GroupDetailType | Record<string, never>>(
    {}
  );

  const fetchGetGroup = async () => {
    try {
      setIsLoading(true);

      const { data } = await getGroup(groupId as string);

      if (data?.success) {
        setGroup(data?.group);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    groupId && fetchGetGroup();
  }, [groupId]);

  return {
    isLoading,
    group,
    groupInstructor: group.instructor,
    refetch: fetchGetGroup
  };
};

export default useGroup;
