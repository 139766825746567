import moment from "moment";

export const calcYearMonth = (date) => {
  const months = moment().diff(moment(date), "months");
  const years = moment().diff(moment(date), "years");
  if (months <= 1) {
    return "1 month";
  }
  if (months < 12) {
    return `${months} month`;
  } else if (years <= 1) {
    return "1 year";
  } else {
    return `${years} years`;
  }
};
