import React, { FC } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface Props {
  page: number;
  isActive: boolean;
  onPress: () => void;
}

const PageItem: FC<Props> = ({
  page = 1,
  isActive = false,
  onPress,
}): JSX.Element => {
  return (
    <div
      onClick={onPress}
      className={clsx(styles.wrapper, { [styles.activeWrapper]: isActive })}
    >
      <span className={clsx(styles.text, { [styles.activeText]: isActive })}>
        {page}
      </span>
    </div>
  );
};

export default PageItem;
