import { useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-hot-toast";

import styles from "./styles.module.scss";
//assets
import { MoreVerticalIcon } from "../../assets";
//components
import StaffItem from "../StaffItem";
import ContentWrapper from "../Layout/ContentWrapper";
//modals
import EditManagerModal from "../../modals/Manager/EditManager";
import Delete from "../../modals/Delete";
//types
import { AdminType } from "../../types";
//constants
import { toastError } from "../../constants";
//api
import { deleteStaff } from "../../services/staff.service";
import Popup from "../Popup";

const LIMIT_PER_PAGE = 10;

const StaffManagementItem = ({
  item,
  allChecked,
  setIsLoading,
  onGetAllStaff,
  isLoading,
}: {
  item: AdminType;
  allChecked: boolean;
  setIsLoading: (value: boolean) => void;
  onGetAllStaff: (page: number, limit: number) => void;
  isLoading: boolean;
}) => {
  const [isLoadingDelete, setisLoadingDelete] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showPopap, setShowPopap] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);

  const onEdit = () => {
    setShowPopap(false);
    setIsOpenEditModal(true);
  };

  const onDelete = async () => {
    setIsShowDeleteModal(true);
    setShowPopap(false);
  };

  const confirmedDelete = async () => {
    try {
      setisLoadingDelete(true);
      const { data } = await deleteStaff(item.id);
      if (data?.success) {
        onGetAllStaff(1, LIMIT_PER_PAGE);
      }
      setisLoadingDelete(false);
      toast.success("User has been successfully deleted");
    } catch (error) {
      toast.error(toastError);
      setisLoadingDelete(false);
    }
    setIsShowDeleteModal(false);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!event.target.closest(".details__body")) {
        setShowPopap(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <EditManagerModal
        item={item}
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        onGetAllStaff={onGetAllStaff}
      />
      <Delete
        title="Delete user"
        isLoading={isLoadingDelete}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        onDelete={confirmedDelete}
        type="delete"
      />
      <div className={styles.businessBody}>
        <div className={styles.wrapper}>
          <StaffItem
            image={item.avatar || undefined}
            name={`${item.firstName} ${item.lastName}`}
          />
          <div className={styles.email}>
            <p>{item.email}</p>
          </div>
          <div className={styles.role}>
            <p>
              {item.role === "manager" ? "Organization Manager" : "Moderator"}
            </p>
          </div>
        </div>
        <div className={clsx(styles.select, "details__body")}>
          <div onClick={() => setShowPopap((current) => !current)}>
            <MoreVerticalIcon />
          </div>
          {showPopap && <Popup onEdit={onEdit} onDelete={onDelete} />}
        </div>
      </div>
    </>
  );
};
export default StaffManagementItem;
