import { useEffect, useState } from "react";

import styles from "./styles.module.scss";
//assets
import { SearchIcon } from "../../../../assets";
//components
import { Input, PageItem, PostItem } from "../../../../components";
//modals
import CheckVideoModal from "../../../../modals/CheckVideo";
//types
import { PostType, UserType } from "../../../../types";
//api
import { getUserPosts } from "../../../../services/users.service";
import { PulseLoader } from "react-spinners";

const LIMIT_PRE_PAGE = 10;

const PostsDetails = ({ user }: { user?: UserType | null }) => {
  // const [showModal, setShowModal] = useState(false);
  // const [search, setSearch] = useState("");
  // const [currentImage, setCurrentImage] = useState("");
  const [posts, setPosts] = useState<PostType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const onGetUserPosts = async (id: string, page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await getUserPosts({
        userId: id,
        page: page,
        limit: limit,
      });
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalPosts / limit));
        setPosts(data?.posts);
      }
    } catch (error) {
      console.log("onGetUserPosts error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      onGetUserPosts(user.id, page, LIMIT_PRE_PAGE);
    }
  }, [user, page]);

  return (
    <div className={styles.userPosts}>
      {/* <CheckVideoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        image={currentImage}
      /> */}
      {/* <div className={styles.input}>
        <Input
          icon={SearchIcon}
          placeholder="Search"
          isEmpty={search.length === 0}
          isSearch
          onChange={(e) => setSearch(e.target.value)}
          // style={{ width: "88%" }}
          setEmpty={() => setSearch("")}
        />
      </div> */}
      {loading ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <div>
          {posts?.length > 0 ? (
            posts?.map((item: any, index: number) => {
              return <PostItem item={item} key={`postItem-${index}`} />;
            })
          ) : (
            <div className={styles.empty}>Nothing here yet</div>
          )}
        </div>
      )}
      {pagesCount > 0 && (
        <div className={styles.paginationContainer}>
          {pagesCount >= 0 &&
            Array(pagesCount === 0 ? 1 : pagesCount)
              .fill("_")
              .map((item, index) => (
                <PageItem
                  onPress={() => setPage(index + 1)}
                  key={`pageItem-${index}`}
                  page={index + 1}
                  isActive={index + 1 === page}
                />
              ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => setPage((prev) => prev + 1)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default PostsDetails;
