import { useMemo, useState } from "react";
import { ClipLoader } from "react-spinners";

import styles from "./styles.module.scss";

import { getPublicLinkForImage } from "../../services/auth.service";
import clsx from "clsx";

interface IPrivateImage extends React.ImgHTMLAttributes<HTMLImageElement> {
  defaultsrc?: string;
  wrapperClassName?: string;
  size?: string | number;
}

export default function PrivateImage(props: IPrivateImage) {
  const [isLoading, setIsLoading] = useState(true);
  const [publicUrl, setPublicUrl] = useState<string | undefined>(undefined);
  const privateUrl = useMemo(() => props.src, [props.src]);

  const downloadImage = async () => {
    try {
      setIsLoading(true);
      if (!privateUrl || !!publicUrl) return;
      const resLinkImg = await getPublicLinkForImage([privateUrl]);
      const imageLink = resLinkImg?.data?.links[0].downloadLink;
      // console.log({ resLinkImg, imageLink });
      setPublicUrl(imageLink);
    } catch (e) {
      console.log({ e });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={clsx(styles.wrapper, props?.className)}>
      {isLoading && (
        <div className={styles.loader}>
          <ClipLoader color="#e13f4e" size={props?.size || "35%"} />
        </div>
      )}
      <img
        {...props}
        src={publicUrl ?? privateUrl ?? props.defaultsrc}
        alt={props.alt || "Private Image"}
        onError={(e) => {
          downloadImage();
          console.log(e);
        }}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
}
