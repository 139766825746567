import { useState } from "react";

interface IUseTable {
  defaultPage?: number;
}

const useTable = ({ defaultPage = 1 }: IUseTable = {}) => {
  const [page, setPage] = useState(defaultPage);
  const [sortBy, setSortBy] = useState<Record<string, number> | null>(null);

  const onChangeSortBy = (sorter: Record<string, number>) => {
    const [key] = Object.keys(sorter);

    setSortBy(key ? { [`sort[${key}]`]: sorter[key] } : null);
  };

  return {
    page,
    setPage,
    sortBy,
    onChangeSortBy,
  };
};

export default useTable;
