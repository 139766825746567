import { ReactNode } from "react";
import ReactModal from "react-modal";
import clsx from "clsx";

// assets
import { CloseIcon } from "../../assets";

// styles
import styles from "./styles.module.scss";

interface IModalProps {
  children: ReactNode;
  className?: string;
  open: boolean;
  title: string;
  subtitle?: string;
  onClose: () => void;
}

const Modal = ({
  children,
  className,
  open,
  title,
  subtitle,
  onClose,
}: IModalProps) => {
  return (
    <ReactModal
      className={clsx(styles.modal, className)}
      overlayClassName={styles.modal__overlay}
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div className={styles.modal__header}>
        <div className={styles["modal__header--title"]}>{title}</div>
        {subtitle ? (
          <div className={styles["modal__header--subtitle"]}>{subtitle}</div>
        ) : null}
        <CloseIcon
          className={styles["modal__header--close"]}
          onClick={onClose}
        />
      </div>
      {children}
    </ReactModal>
  );
};

export default Modal;
