import { useEffect, useState } from "react";

import styles from "./styles.module.scss";
//components
import { Layout, RadioButtons } from "../../../components";
import Events from "./Events";
import Requests from "./Requests";
import { getAllMeetupRequests } from "../../../services/meetups.service";

export default function Meetups() {
  const [currentTab, setCurrentTab] = useState<"Events" | "Requests">("Events");
  const [totalRequests, setTotalRequsts] = useState<number | string>(0);

  const onGetRequests = async () => {
    try {
      const { data } = await getAllMeetupRequests({
        page: 1,
        limit: 1,
      });
      setTotalRequsts(data?.totalRequests);
    } catch (error) {}
  };

  useEffect(() => {
    if (currentTab === "Events") {
      onGetRequests();
    }
  }, [currentTab]);

  return (
    <>
      <Layout title={"Meetups"}>
        <div className={styles.menuBody}>
          <RadioButtons
            tag={[0, totalRequests]}
            name="meetups"
            options={[
              { label: "Stroynik Events", value: "Events" },
              { label: "User Requests", value: "Requests" },
            ]}
            value={currentTab}
            onChange={setCurrentTab as (value: string) => void}
          />
        </div>
        {currentTab === "Events" && <Events />}
        {currentTab === "Requests" && (
          <Requests setTotalRequsts={setTotalRequsts} />
        )}
      </Layout>
    </>
  );
}
