import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";
//assets
import PlaceholderAvatar from "../../../assets/images/avatar.png";
import { AddIcon } from "../../../assets";
//components
import {
  Button,
  Layout,
  RadioButtons,
  GroupItem,
  PrivateImage,
} from "../../../components";
//modals
import GroupModal from "../../../modals/Group";
import InstructorModal from "../../../modals/Instructor";
import Delete from "../../../modals/Delete";
//api
import {
  deleteInstructor,
  getInstructor,
  getInstructorGroups,
} from "../../../services/instructors.service";
//types
import { GroupType, InstructorType } from "../../../types";
//utils
//constants
import { toastError } from "../../../constants";
//context
import { useAdminContext } from "../../../context";

interface Props {}

const InstructorProfile: FC<Props> = (): JSX.Element => {
  const [instructorState, setInstructorState] = useState<InstructorType | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { admin, adminLoading } = useAdminContext();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowEditModal, setIsShowEditModal] = useState<boolean>(false);

  const [groups, setGroups] = useState<GroupType[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(true);

  const onGetInstructor = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getInstructor(id);
      if (data?.success) {
        setInstructorState(data?.instructor);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("onGetInstructor error", error);
      setIsLoading(false);
    }
  };

  const onGetInstructorGroups = async (id: string) => {
    try {
      setIsLoadingGroups(true);
      const { data } = await getInstructorGroups(id);
      console.log("onGetInstructorGroups", data);

      if (data?.success) {
        setGroups(data?.groups);
      }
      setIsLoadingGroups(false);
    } catch (error) {
      console.log("onGetInstructorGroups error", error);
      setIsLoadingGroups(false);
    }
  };

  const onUpdate = () => {
    if (id) onGetInstructor(id);
  };
  const onUpdateGroups = () => {
    if (id) onGetInstructorGroups(id);
  };

  useEffect(() => {
    if (id) {
      onGetInstructor(id);
      onGetInstructorGroups(id);
    }
  }, [id]);

  const onDeleteInstructor = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteInstructor(instructorState?.id || id || "0");
      if (data?.success) {
        setIsLoadingDelete(false);
        navigate(-1);
        toast.success("Instructor has been successfully deleted");
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoadingDelete(false);
    }
  };

  const userPhoto = useMemo(
    () => instructorState?.avatar || undefined,
    [instructorState]
  );

  return (
    <Layout
      subtitle={"Back to all"}
      buttons={
        <>
          {!adminLoading && admin?.role !== "manager" && (
            <div className={styles.btnWrapper}>
              <Button
                title={"Edit"}
                styleType="outlined"
                color="#000000"
                onClick={() => setIsShowEditModal(true)}
                style={{
                  border: "none",
                  width: "93px",
                  background: "#E4E4E4",
                  borderRadius: "16px",
                  color: "black",
                }}
              />
              <Button
                title={"Delete"}
                styleType="filled"
                onClick={() => setIsShowDeleteModal(true)}
                loading={isLoadingDelete}
                style={{
                  border: "none",
                  width: "93px",
                  background: "#FF3C3C",
                  borderRadius: "16px",
                }}
              />
            </div>
          )}
        </>
      }
    >
      <>
        <GroupModal
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          onGetAll={onUpdateGroups}
          instructorId={instructorState?.id}
        />
        <InstructorModal
          isOpen={isShowEditModal}
          setIsOpen={setIsShowEditModal}
          item={instructorState}
          onUpdate={onUpdate}
        />
        <Delete
          title="Delete instructor"
          onDelete={onDeleteInstructor}
          isLoading={isLoading || false}
          isOpen={isShowDeleteModal}
          setIsOpen={setIsShowDeleteModal}
          type="delete"
          text={`Are you sure you want to delete instructor?`}
        />
        <div className={styles.wrapper}>
          {isLoading && !instructorState ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          ) : (
            <>
              <div className={styles.info}>
                <PrivateImage
                  src={userPhoto}
                  defaultsrc={PlaceholderAvatar}
                  alt="avatar"
                  className={styles.avatar}
                />
                <div className={styles.nameWrapper}>
                  <p className={styles.name}>
                    {instructorState?.firstName} {instructorState?.lastName}
                  </p>
                  <div className={styles.title}>{instructorState?.title}</div>
                </div>
              </div>
              <div className={styles.about}>{instructorState?.description}</div>
              <div className={styles.tabMenu}>
                <div className={styles.tabWrapper}>
                  <RadioButtons
                    tag={[groups?.length || 0]}
                    name="time"
                    options={[{ label: "Groups", value: "Groups" }]}
                    value={"Groups"}
                    onChange={() => {}}
                  />
                </div>
                <Button
                  onClick={() => setIsOpenModal(true)}
                  title="Add Group"
                  icon={AddIcon}
                  className={styles.addNewBtn}
                />
              </div>

              <div className={styles.groupsWrapper}>
                <div className={styles.sectionHeader}>
                  <div className={styles.nameBlock}>
                    <p>Group Name</p>
                  </div>
                  <div className={styles.categoryBlock}>
                    <p>Category</p>
                  </div>
                  <div className={styles.followersBlock}>
                    <p>Followers</p>
                  </div>
                  <div className={styles.dateBlock}>
                    <p>Date created</p>
                  </div>
                </div>

                <div className={styles.groupsContainer}>
                  {isLoadingGroups ? (
                    <div className={styles.loaderWrapper}>
                      <PulseLoader color={"#236BFE"} />
                    </div>
                  ) : groups.length > 0 ? (
                    groups.map((item, index) => {
                      return (
                        <GroupItem
                          key={item.id}
                          item={item}
                          onGetAll={onUpdateGroups}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </Layout>
  );
};

export default InstructorProfile;
