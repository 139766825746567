import { AxiosResponse } from "axios";

// apis
import axiosInstance from "../apis";

// utils
import { queryParamsBuilder } from "../utils/methods";

// types
import { GroupCategoryEnum } from "../types";

type GroupMeetCreateParams = {
  category: GroupCategoryEnum.meetAndGreet;
};

type GroupCoachCreateParams = {
  category: GroupCategoryEnum.relationshipCoaching;
  description: string;
  title: string;
};

type GroupCreateParams = GroupMeetCreateParams | GroupCoachCreateParams;

type FollowedGroupsParams = {
  page?: number;
  limit?: number;
};

export const getGroup = async (
  groupId: string | number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`groups/${groupId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createGroup = async (
  instructorId: string,
  params: GroupCreateParams
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      category: params.category,
    };
    if (params.category === GroupCategoryEnum.relationshipCoaching) {
      body.title = params.title;
      body.description = params.description;
    }

    axiosInstance
      .post(`groups/instructor/${instructorId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const editGroup = async (
  groupId: string,
  { category, title, description }: GroupCoachCreateParams
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = { category, title, description };

    axiosInstance
      .patch(`groups/${groupId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteGroup = async (groupId: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`groups/${groupId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getGroupMeetings = async (
  groupId: string,
  params: Record<string, string | number>
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`groups/${groupId}/admin/meetings${queryParamsBuilder(params)}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getGroupFollowers = async (
  groupId: string,
  params: Record<string, string | number>
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`groups/${groupId}/followers${queryParamsBuilder(params)}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const getFollowedGroups = async ({
  page = 1,
  limit = 10,
}: FollowedGroupsParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`groups/user?page=${page}&limit=${limit}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const followGroup = async (groupId: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`groups/${groupId}/follow`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const unfollowGroup = async (
  groupId: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`groups/${groupId}/unfollow`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
