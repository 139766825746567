import React, { FC, TextareaHTMLAttributes } from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string | boolean;
  wrapperStyles?: any;
}

const Textarea: FC<Props> = ({
  label = "",
  error,
  wrapperStyles = {},
  className,
  ...props
}) => {
  return (
    <div className={clsx(styles.input, wrapperStyles)}>
      {label && <span>{label}</span>}
      <textarea
        className={clsx(styles.input__main, {
          [styles.error]: error,
          className,
        })}
        {...props}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default Textarea;
