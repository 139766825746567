import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

// pages
import SignIn from "../pages/SignIn";
import Statistics from "../pages/Dashboard/Statistics";
import Users from "../pages/Dashboard/Users";
import Verification from "../pages/Dashboard/Verification";
import UserProfile from "../pages/Dashboard/UsersProfile";
import Reports from "../pages/Dashboard/Reports";
import StaffManagement from "../pages/Dashboard/StaffManagement";
import Instructors from "../pages/Dashboard/Instructors";
import InstructorProfile from "../pages/Dashboard/InstructorProfile";
import GroupDetails from "../pages/Dashboard/GroupDetails";
import Meetups from "../pages/Dashboard/Meetups";

// components
import PrivateRoute from "./PrivateRoute";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="signin" element={<SignIn />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Navigate to="statistics" />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:id" element={<UserProfile />} />
          <Route path="verification" element={<Verification />} />
          <Route path="reports" element={<Reports />} />
          <Route path="staff-management" element={<StaffManagement />} />
          <Route path="instructors" element={<Instructors />} />
          <Route path="instructors/:id" element={<InstructorProfile />} />
          <Route path="groups/:id" element={<GroupDetails />} />
          <Route path="meetups" element={<Meetups />} />
        </Route>

        <Route path="*" element={<Navigate to="/statistics" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Navigation;
