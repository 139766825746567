import React, { useMemo } from "react";

import styles from "./styles.module.scss";
//components
import { InterestTag } from "../../../../components";
import { LikesType, UserType } from "../../../../types";
import moment from "moment";
import {
  calcYearMonth,
  converCmToFeetInch,
  converKgToLbs,
} from "../../../../utils";
import clsx from "clsx";

const AccountDetails = ({
  user,
}: {
  user?: UserType | null; // IOwner | undefined
}) => {
  const userPoints = useMemo(() => {
    const points = [];
    if (user?.status?.student) {
      points.push({
        value: `${user?.status?.student?.institution}, ${
          user?.status?.student?.degree
        }, ${moment(user?.status?.student?.graduationYear).format("YYYY")}`,
        topic: "education",
      });
    }
    if (user?.status?.employee) {
      points.push({
        value: `${user?.status?.employee?.type} x ${calcYearMonth(
          user?.status?.employee?.startWorkingDate
        )}`,
        topic: "work",
      });
    }
    if (user?.status?.employee?.annualSalary) {
      let USDollar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      const formatedSalary = USDollar.format(
        user?.status?.employee?.annualSalary
      );
      points.push({
        value: `${formatedSalary} ${user?.status?.employee?.currency}`,
        topic: "money",
      });
    }
    if (user?.generalInfo?.height?.value) {
      const { feet, inches } = converCmToFeetInch(
        user?.generalInfo?.height.value
      );
      points.push({
        value: `${feet}’${inches}”`,
        topic: "height",
      });
    }
    if (user?.generalInfo?.weight?.value) {
      const { lbs, decimal } = converKgToLbs(user?.generalInfo?.weight.value);
      points.push({
        value: `${lbs}${decimal === ".0" ? "" : decimal} lbs`,
        topic: "weight",
      });
    }
    if (user?.generalInfo?.eyeColor) {
      points.push({
        value: user?.generalInfo?.eyeColor,
        topic: "eye",
      });
    }
    if (user?.generalInfo?.useAlcohol) {
      points.push({
        value: "Alcohol",
        topic: "alcohol",
      });
    }
    if (user?.generalInfo?.useMarijuana) {
      points.push({
        value: "Marijuana",
        topic: "marijuana",
      });
    }
    if (user?.generalInfo?.useTobacco) {
      points.push({
        value: "Smoker",
        topic: "behabits",
      });
    }
    if (user?.faith) {
      points.push({
        value: user?.faith,
        topic: "faith",
      });
    }
    // if (user?.generalInfo?.previouslyMarried) {
    //   points.push({
    //     value: "Previously Married",
    //     topic: "work",
    //   });
    // }
    // if (user?.generalInfo?.kids) {
    //   points.push({
    //     value: "Kids",
    //     topic: "work",
    //   });
    // }
    return points;
  }, [user]);

  const getAllLikes = (
    likes: LikesType | undefined | null,
    type: "hobbies" | "interests" | "values"
  ) => {
    let typeArray: {
      value: string;
    }[] = [];
    if (likes && likes[type] && likes[type]?.length > 0) {
      const mapedLikes = likes[type]?.map((el) => ({ value: el }));
      typeArray = typeArray.concat(mapedLikes);
    }
    if (
      likes &&
      likes?.manuallyAdded &&
      likes?.manuallyAdded[type] &&
      likes?.manuallyAdded[type]?.length > 0
    ) {
      typeArray = typeArray.concat(
        likes?.manuallyAdded[type]?.map((el) => ({ value: el }))
      );
    }
    return typeArray;
  };

  const userHobies = useMemo(() => {
    const hobbies = getAllLikes(user?.likes, "hobbies");
    return hobbies;
  }, [user]);
  const userInterests = useMemo(() => {
    const interests = getAllLikes(user?.likes, "interests");
    return interests;
  }, [user]);
  const userValues = useMemo(() => {
    const values = getAllLikes(user?.likes, "values");
    return values;
  }, [user]);

  const useHasSomeLikes = useMemo(
    () =>
      (userPoints && userPoints?.length > 0) ||
      (userHobies && userHobies?.length > 0) ||
      (userInterests && userInterests?.length > 0) ||
      (userValues && userValues?.length > 0),
    [userPoints, userHobies, userInterests, userValues]
  );
  const useHasSomething = useMemo(
    () => useHasSomeLikes || user?.description || user?.lookingFor,
    [useHasSomeLikes, user]
  );

  return (
    <div className={styles.accountBody}>
      {useHasSomething ? (
        <>
          {useHasSomeLikes && (
            <div className={styles.accountTags}>
              {userPoints?.length > 0 && (
                <div className={styles.interestBody}>
                  {userPoints.map((item, index) => {
                    return (
                      <InterestTag
                        isInterest
                        item={item}
                        key={`userPoints-${index}`}
                      />
                    );
                  })}
                </div>
              )}
              {userHobies && userHobies?.length > 0 && (
                <>
                  <h4 className={styles.nameTag}>Hobbies</h4>
                  <div className={styles.interestBody}>
                    {userHobies.map((item, index) => {
                      return (
                        <InterestTag
                          isInterest
                          item={item}
                          key={`userHobies-${index}`}
                        />
                      );
                    })}
                  </div>
                </>
              )}
              {userInterests && userInterests?.length > 0 && (
                <>
                  <h4 className={styles.nameTag}>Interest</h4>
                  <div className={styles.interestBody}>
                    {userInterests.map((item, index) => {
                      return (
                        <InterestTag
                          isInterest
                          item={item}
                          key={`userInterests-${index}`}
                        />
                      );
                    })}
                  </div>
                </>
              )}
              {userValues && userValues?.length > 0 && (
                <>
                  <h4 className={styles.nameTag}>Values</h4>
                  <div className={styles.interestBody}>
                    {userValues.map((item, index) => {
                      return (
                        <InterestTag
                          isInterest
                          item={item}
                          key={`userValues-${index}`}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          )}
          <div className={styles.accountInfo}>
            {user?.description && (
              <div className={styles.accountInfoBody}>
                <h4 className={styles.accountInfoTitle}>About me</h4>
                <p className={styles.accountInfoSubtitle}>
                  {user?.description}
                </p>
              </div>
            )}
            {user?.lookingFor && (
              <div className={styles.accountInfoBody}>
                <h4 className={styles.accountInfoTitleLooking}>
                  I’m looking for...
                </h4>
                <p className={styles.accountInfoSubtitle}>{user?.lookingFor}</p>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={styles.photosEmpty}>Nothing here yet</div>
      )}
    </div>
  );
};
export default AccountDetails;
