import * as yup from "yup";

export const meetingLinkSchema = yup.object({
  link: yup
    .string()
    .trim()
    .required("Link is a required field")
    .url("Link must be a valid URL"),
});

export const addMeetingSchema = (isCoaching: boolean) =>
  yup.object({
    date: yup.date().required("Date is a required field"),
    time: yup.string().trim().required("Time is a required field"),
    ...(isCoaching && {
      description: yup
        .string()
        .trim()
        .required("Description is a required field")
        .min(
          20,
          "The minimum length of the 'General info for for meetings' field should be 20 characters"
        )
        .max(
          500,
          "The maximum length of the 'General info for for meetings' should not exceed 500 characters"
        ),
    }),
    link: yup.string().trim().url("Link must be a valid URL"),
  });
