import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";
//assets
import { MoreVerticalIcon } from "../../assets";
//components
import PrivateImage from "../PrivateImage";
import Popup from "../Popup";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
import { useOutsideClick } from "../../hooks/useOutsideClick";
//types
import { MeetupType } from "../../types";
//modals
import Delete from "../../modals/Delete";
//apis
import { deleteMeetup } from "../../services/meetups.service";
//constants
import { toastError } from "../../constants";
import MeetupModal from "../../modals/Meetup";

const MeetupItem = ({
  item,
  onGetAllStaff,
}: {
  item: MeetupType;
  onGetAllStaff: () => void;
}) => {
  const [showPopap, setShowPopap] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const navigate = useNavigate();
  const formatedDate = useFormatDate(item.date || "");

  const eventPhoto = useMemo(() => item?.eventImage || undefined, [item]);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, (e) => {
    setShowPopap(false);
  });

  const onEdit = () => {
    setShowPopap(false);
    setIsShowEditModal(true);
  };

  const onDelete = async () => {
    setShowPopap(false);
    setIsShowDeleteModal(true);
  };

  const onDeleteMeetup = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteMeetup(item.id);
      if (data?.success) {
        onGetAllStaff();
        toast.success("Meetup have been successfully deleted");
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
      toast.error(toastError);
    }
  };

  return (
    <div className={styles.businessBody}>
      <MeetupModal
        isOpen={isShowEditModal}
        setIsOpen={setIsShowEditModal}
        onGetAll={() => onGetAllStaff()}
        item={item}
      />
      <Delete
        title="Delete meetup"
        onDelete={onDeleteMeetup}
        isLoading={isLoadingDelete || false}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        type="delete"
        text={`Are you sure you want to delete meetup?`}
      />
      <div
        className={styles.wrapper}
        // onClick={() => navigate(`/instructors/${item.id}`)}
      >
        <div className={styles.eventBody}>
          <PrivateImage
            src={eventPhoto}
            className={styles.eventImage}
            size={20}
          />
          <div className={styles.eventInfo}>
            <p className={styles.title}>{item.title}</p>
            <p className={styles.name}>{item.organizersName}</p>
          </div>
        </div>
        <div className={styles.price}>
          <p>${item.price}</p>
        </div>
        <div className={styles.location}>
          <p>{item?.location?.name}</p>
        </div>
        <div className={styles.members}>
          <p>
            {item.minParticipants} - {item.maxParticipants}
          </p>
        </div>
        <div className={styles.date}>
          <p>{formatedDate ? formatedDate : ""}</p>
        </div>
        <div className={styles.select} ref={ref}>
          <div
            onClick={() => {
              setShowPopap((current) => !current);
            }}
            className={styles.selectBtn}
          >
            <MoreVerticalIcon />
          </div>
          {showPopap && <Popup onEdit={onEdit} onDelete={onDelete} />}
        </div>
      </div>
    </div>
  );
};

export default MeetupItem;
