import { AxiosResponse } from "axios";

// apis
import axiosInstance from "../apis";

interface IMeetingLinkRequest {
  link: string;
}

interface ICreateMeetingRequest {
  startDate: Date;
  link: string;
  description: string;
}

export const addMeetingLink = async (
  meetingId: string,
  body: IMeetingLinkRequest
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`meetings/${meetingId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createMeeting = async (
  groupId: string,
  body: ICreateMeetingRequest
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`meetings/group/${groupId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteMeeting = async (
  meetingId: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`meetings/${meetingId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
