import clsx from "clsx";
import React, { InputHTMLAttributes } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";

import styles from "./styles.module.scss";

export const GOOGLE_API_KEY = "AIzaSyC_jd5nUg33T-L4TDU2ftNXRedS78ME2bI";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string | boolean;
  wrapperStyles?: any;
  onSelectPlace: (place: any) => void;
  style?: {};
}

export default function InputLocation({
  label = "",
  error,
  wrapperStyles = {},
  style,
  onSelectPlace,
  defaultValue,
  ...props
}: Props) {
  const handleFindPlacePress = async (place: any) => {
    const location = place?.geometry?.location;
    const lat =
      typeof location?.latitude === "function"
        ? location?.latitude()
        : typeof location?.lat === "function"
        ? location?.lat()
        : "";
    const lng =
      typeof location?.longitude === "function"
        ? location?.longitude()
        : typeof location?.lng === "function"
        ? location?.lng()
        : "";

    onSelectPlace({
      name: place.formatted_address,
      coordinates: {
        latitude: lat,
        longitude: lng,
      },
    });
  };

  return (
    <div className={clsx(styles.input, wrapperStyles)}>
      {label && <span>{label}</span>}
      <div className={styles.inputWrapper}>
        <ReactGoogleAutocomplete
          lang="en"
          language="en"
          defaultValue={defaultValue}
          apiKey={GOOGLE_API_KEY}
          onPlaceSelected={(place) => {
            console.log(place);
            handleFindPlacePress(place);
          }}
          options={{ types: ["(cities)"] }}
          placeholder=""
          className={clsx(
            styles.input__main,
            {
              [styles.error]: error,
            },
            "search__input"
          )}
          style={style}
        />
      </div>
      {error && (
        <p className={styles.errorText}>
          {typeof error !== "string" ? JSON.stringify(error) : error}
        </p>
      )}
    </div>
  );
}
