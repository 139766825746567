import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import profileImage from "../../assets/images/avatar.png";
import Logo from "../../assets/icons/logo.svg";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChartIcon,
  CheckCircleIcon,
  FlagIcon,
  LogoutIcon,
  UsersCandidatesIcon,
  UsersCandidatesVIcon,
  InstructorsIcon,
  MeetupsIcon,
} from "../../assets";
//components
import ContentWrapper from "../Layout/ContentWrapper";
import Tag from "../Tag";
import PrivateImage from "../PrivateImage";
//utils
import {
  removeAccessToken,
  removeRefreshToken,
} from "../../utils/tokenStorage";
//api
import { logout } from "../../services/auth.service";
//context
import { useAdminContext, useNumbersContext } from "../../context";

export default function Sidebar() {
  const [isOpenProfileSettings, setIsOpenProfileSettings] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { admin } = useAdminContext();
  const numbers = useNumbersContext();

  const onLogOut = async () => {
    await logout();
    removeAccessToken();
    removeRefreshToken();
    navigate("/signin");
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.navOutside}>
        <nav className={styles.navWrapper}>
          <div>
            <div className={styles.logo}>
              <img src={Logo} alt={"stroynik"} />
            </div>
            <NavLink
              to="/statistics"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <ChartIcon />
              <span>Statistics</span>
            </NavLink>
            <NavLink
              to="/users"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <UsersCandidatesVIcon />
              <span>Users</span>
            </NavLink>
            <NavLink
              to="/verification"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <CheckCircleIcon />
              <span>Verification</span>
              {numbers.number > 0 && (
                <div className={styles.tag}>
                  <Tag
                    label={`${numbers.number}`}
                    isSelected={location.pathname === "/verification"}
                  />
                </div>
              )}
            </NavLink>
            <NavLink
              to="/instructors"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <InstructorsIcon />
              <span>Instructors</span>
            </NavLink>
            <NavLink
              to="/meetups"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <MeetupsIcon />
              <span>Meetups</span>
            </NavLink>
            <NavLink
              to="/reports"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <FlagIcon />
              <span>Reports</span>
            </NavLink>
            {admin?.role === "admin" && (
              <NavLink
                to="/staff-management"
                className={({ isActive, isPending }) =>
                  clsx({
                    [styles.navButtonSelected]: isActive,
                    [styles.navButton]: !isActive,
                  })
                }
              >
                <UsersCandidatesIcon />
                <span>Staff Management</span>
              </NavLink>
            )}
          </div>
        </nav>
        <div
          onClick={() => setIsOpenProfileSettings((current) => !current)}
          className={styles.profileWrapper}
        >
          <motion.div
            animate={isOpenProfileSettings ? "open" : "closed"}
            variants={{
              open: {
                opacity: 1,
                visibility: "visible",
                y: "-40px",
                position: "absolute",
              },
              closed: {
                opacity: 0,
                visibility: "hidden",
                y: "50%",
                position: "absolute",
              },
            }}
            className={styles.logoutWrapper}
          >
            <ContentWrapper className={{ padding: "10px", marginBottom: "0" }}>
              <>
                <div onClick={onLogOut} className={styles.subNav}>
                  <LogoutIcon className={styles.svg} /> <span>Logout</span>
                </div>
              </>
            </ContentWrapper>
          </motion.div>
          <div className={styles.image}>
            <PrivateImage
              src={admin?.avatar}
              defaultsrc={profileImage}
              alt="profile image"
            />
          </div>
          <div className={styles.profileBody}>
            <span
              className={clsx(styles.profileTitle, {
                [styles.isLargeName]:
                  `${admin?.firstName ? admin?.firstName : ""} ${
                    admin?.lastName ? admin?.lastName : ""
                  }`.length > 12,
              })}
            >
              {admin?.firstName} {admin?.lastName}
            </span>
            <p
              className={clsx({
                [styles.organizatiorManager]: admin?.role === "manager",
              })}
            >
              {admin?.role === "admin"
                ? "Super Admin"
                : admin?.role === "manager"
                ? "Organization Manager"
                : "Moderator"}
            </p>
          </div>
          <div className={styles.arrowWrapper}>
            <ArrowUpIcon width={"20px"} height="15px" />
            <ArrowDownIcon width={"20px"} height="15px" />
          </div>
        </div>
      </div>
    </div>
  );
}
