import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

type VerificationAllParams = {
  page?: number;
  limit?: number;
  search?: string;
  type?:
    | { userName: 1 | -1 }
    | { "verificationDocument.updatedAt": 1 | -1 }
    | {};
};

type VerifyUserParams = {
  userId: string;
  status: "Approved" | "Declined";
  reason?: string;
};

type VerifyParams = {
  users: VerifyUserParams[];
};

export const getAllVerificationUsers = async ({
  page = 1,
  limit = 10,
  search = "",
  type,
}: VerificationAllParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const postParams: any = {};
    if (!!search) postParams.search = search;
    if (!!type) postParams.sort = { ...type };

    axiosInstance
      .post(`user/all/nv?page=${page}&limit=${limit}`, postParams)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const verifyUser = async ({
  users,
}: VerifyParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const postParams: any = {
      verify: users,
    };

    axiosInstance
      .put(`user/verify`, postParams)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
