import * as yup from "yup";

import validationValues from "./values";

export const newInstructorSchema = yup
  .object({
    firstName: validationValues.firstName,
    lastName: validationValues.lastName,
    title: validationValues.title,
    about: validationValues.about,
  })
  .required();
