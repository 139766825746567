import ReactPaginate from "react-paginate";
import clsx from "clsx";

// styles
import styles from "./styles.module.scss";

interface IPaginationProps {
  page: number;
  perPage?: number;
  total: number;
  onChangePage: (page: number) => void;
}

const Pagination = ({
  page = 1,
  perPage = 15,
  total,
  onChangePage,
}: IPaginationProps) => {
  const pageCount = Math.ceil(total / perPage);

  const onChangeCurrentPage = (selectedItem: { selected: number }) => {
    onChangePage(selectedItem.selected + 1);
  };

  return (
    <ReactPaginate
      className={styles.pagination}
      pageClassName={styles.pagination__page}
      activeClassName={styles["pagination__page-active"]}
      breakClassName={styles.pagination__break}
      nextClassName={clsx(styles.pagination__button, {
        [styles["pagination__button-hide"]]: page >= pageCount,
      })}
      nextLabel="Next"
      forcePage={page - 1}
      onPageChange={onChangeCurrentPage}
      pageCount={pageCount}
      previousLabel={null}
      renderOnZeroPageCount={null}
    />
  );
};

export default Pagination;
