import clsx from "clsx";
import styles from "./styles.module.scss";

export default function ReportsType({ isComplaint }: { isComplaint: boolean }) {
  return (
    <div
      className={clsx({
        [styles.complaint]: isComplaint,
        [styles.disabled]: !isComplaint,
      })}
    >
      <span>{isComplaint ? "User complaint" : "Problem report"}</span>
    </div>
  );
}
