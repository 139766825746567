import React from "react";

import styles from "./styles.module.scss";
//assets
import { TrashIcon } from "../../assets";

export default function Empty() {
  return (
    <>
      <div className={styles.emptyState}>
        <div className={styles.emptyStateContent}>
          <div className={styles.emptyStateMessage}>Data not found</div>
          <div className={styles.emptyStateHelp}>
            <TrashIcon fill="#E13F4E" />
          </div>
        </div>
      </div>
    </>
  );
}
