import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { CloseIcon } from "../../assets";
//componets
import { Button, Textarea, RadioSelect } from "../../components";
//constants
import { blockReasonSelect } from "../../constants";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onBlock: (reason: string, type: "block" | "unblock") => void;
  isLoading: boolean;
  title: string;
  type: "block" | "delete";
  userId: string;
  isBlocked: any; // Block;
}

const BlockUser: FC<Props> = ({
  isOpen,
  setIsOpen,
  isLoading: loading,
  title,
  type,
  userId,
  onBlock,
  isBlocked,
}): JSX.Element => {
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };
  const onBlockAccount = async () => {
    onBlock(
      blockReason !== "Other" ? blockReason : otherReason,
      isBlocked?.isBlocked ? "unblock" : "block"
    );
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>{title}</h5>{" "}
          <CloseIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
        </div>
        <div className={styles.optionsContainer}>
          <div className={styles.blockReasonWrapper}>
            <p className={styles.title}>
              {`Choose a reason why you want to ${title.toLowerCase()}`}:
            </p>
            <div className={styles.reasonList}>
              <RadioSelect
                options={blockReasonSelect}
                name="reason"
                value={blockReason}
                onChange={setBlockReason}
              />
              {blockReason === "Other" && (
                <Textarea
                  placeholder="Message"
                  value={otherReason}
                  onChange={onChangeOtherReason}
                  maxLength={240}
                  rows={5}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            title={type === "delete" ? "Delete" : "Block"}
            loading={isLoading}
            onClick={onBlockAccount}
            disabled={blockReason === "Other" && !otherReason}
            styleType={
              blockReason === "Other" && !otherReason ? "disabled" : "filled"
            }
            style={{
              border: "none",
              background: "#FF3C3C",
              borderRadius: "16px",
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BlockUser;
