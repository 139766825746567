import { FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { CloseIcon } from "../../assets";
//types
import { VideoType } from "../../types";
//components
import { PrivateVideo } from "../../components";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  video?: VideoType;
}

const CheckVideoModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  video,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <PrivateVideo
          controls
          src={`${video?.url}`}
          timing={0}
          type="video/mp4"
          size={100}
        />
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className={styles.closeBtn}
        >
          <CloseIcon fill="white" />
        </div>
      </div>
    </Modal>
  );
};

export default CheckVideoModal;
