import React, { ReactNode, createContext, useEffect, useState } from "react";
//utils
import { getAccessToken } from "../../utils/tokenStorage";
//api
import { getAllVerificationUsers } from "../../services/verification.service";

type NumbersContextType = {
  number: number;
  addNumber: (number: number) => void;
};

export const NumbersContext = createContext<NumbersContextType>({
  number: 0,
  addNumber: () => {},
});

export const NumbersProvider = ({ children }: { children: ReactNode }) => {
  const [number, setNumber] = useState(0);
  const token = getAccessToken();

  const getVerificationsNumber = async () => {
    try {
      const { data } = await getAllVerificationUsers({
        page: 1,
        limit: 1,
        type: {},
      });
      if (data?.success) {
        addNumber(data?.totalUsers);
      }
    } catch (e) {
      console.log("getAdmin error", e);
    }
  };

  useEffect(() => {
    if (token) {
      getVerificationsNumber();
    }
  }, [token]);

  const addNumber = (newNumber: number) => {
    setNumber(newNumber);
  };

  return (
    <NumbersContext.Provider value={{ number, addNumber }}>
      {children}
    </NumbersContext.Provider>
  );
};
