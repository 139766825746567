import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import styles from "./styles.module.scss";
//assets
import { CalendarIcon, CloseIcon } from "../../assets";
//components
import {
  Textarea,
  Button,
  RadioSelect,
  StaffItem,
  PrivateImage,
} from "../../components";
//constants
import { blockReasonSelect, feedbackRadios, toastError } from "../../constants";
//hooks
import { useFormatDate } from "../../hooks/formatDate";
//context
import { useAdminContext } from "../../context";
//types
import { ReportUserType } from "../../types";
//api
import { blockUser, deleteUser } from "../../services/users.service";
import { deleteReport } from "../../services/reports.service";

const LIMIT_PER_PAGE = 10;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  reporter: ReportUserType;
  category: string;
  reportedOn?: ReportUserType;
  date: string;
  description: string;
  attachmentImage?: string;
  userId: string;
  reportedId: string | undefined;
  feedBackId: string;
  onFeedback: (page: number, limit: number, search: string) => void;
}
const ReportModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  reporter,
  category,
  reportedOn,
  date,
  description,
  userId,
  reportedId = "",
  feedBackId,
  attachmentImage,
  onFeedback,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [approve, setApprove] = useState("skipThisReport");
  const [otherReason, setOtherReason] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const { admin, adminLoading } = useAdminContext();

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };
  const onBlockAccount = async () => {
    try {
      setDisableButton(true);
      setIsLoading(true);

      const { data } = await blockUser({
        userId: reportedId || "",
        reason: blockReason === "Other" ? otherReason : blockReason,
        status: "block",
      });
      await deleteReport(feedBackId);
      if (data?.success) {
        setIsLoading(false);
        toast.success("User has been blocked");
        onFeedback(1, LIMIT_PER_PAGE, "");
        setDisableButton(false);
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
      setDisableButton(false);
    }
    return;
  };

  const onDeleteAccount = async () => {
    try {
      setDisableButton(true);
      setIsLoading(true);
      const { data } = await deleteUser(reportedId || "0");
      await deleteReport(feedBackId);
      if (data?.success) {
        setIsLoading(false);
        toast.success("User has been successfully deleted");
        onFeedback(1, 20, "");
        setDisableButton(false);
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
      setDisableButton(false);
    }
    return;
  };

  const sendHandler = async () => {
    if (approve === "block") {
      onBlockAccount();
    } else if (approve === "delete") {
      onDeleteAccount();
    } else {
      try {
        setDisableButton(true);
        const { data } = await deleteReport(feedBackId);
        if (data?.success) {
          setDisableButton(false);
          setIsLoading(false);
          toast.success("Feedback resolved");
          onFeedback(1, 20, "");
        }
      } catch (error) {
        setDisableButton(false);
        setIsLoading(false);
        toast.error(toastError);
      }
    }
    // navigate(-1);
  };

  function whichRole(role: string) {
    if (role?.toLowerCase() === "owner") {
      return "Business Owner";
    } else if (role?.toLowerCase() === "explorer") {
      return "Business Explorer";
    } else {
      return "Super Admin";
    }
  }

  const formatedDate = useFormatDate(date);
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>Report</h5>{" "}
          <CloseIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.mainInfo}>
            <p className={styles.category}>{category}</p>
            <div className={styles.reportersContainer}>
              <div className={styles.staffBody}>
                <span className={styles.repName}>Reporter</span>
                <div className={styles.staff}>
                  {reporter && reporter?.id ? (
                    <StaffItem
                      image={reporter?.photos?.main || undefined}
                      name={reporter?.userName || ""}
                      verificationDocument={reporter?.verificationDocument}
                    />
                  ) : (
                    <StaffItem image={undefined} name={`Deleted user`} />
                  )}
                </div>
              </div>
              {reportedOn && reportedOn?.id && (
                <div className={styles.staffBody}>
                  <span className={styles.repName}>Reported on</span>
                  <div className={styles.staff}>
                    <StaffItem
                      image={reportedOn?.photos?.main || undefined}
                      name={reportedOn?.userName || ""}
                      verificationDocument={reportedOn?.verificationDocument}
                    />
                  </div>
                </div>
              )}
              <div className={styles.calendarBody}>
                <CalendarIcon fill="#E13F4E" />
                <span className={styles.date}>{formatedDate}</span>
              </div>
            </div>
          </div>
          <div className={styles.descriptionBody}>
            <span>Description</span>
            <p>{description}</p>
          </div>
          {attachmentImage && (
            <div className={styles.attachment}>
              <div className={styles.attachName}>
                <span>Attachment</span>
              </div>
              <div className={styles.attachmentBody}>
                <PrivateImage src={attachmentImage} />
              </div>
            </div>
          )}
          {!adminLoading && admin?.role !== "manager" && (
            <div className={styles.approveBody}>
              <div className={styles.approveSection}>
                <div className={styles.radio}>
                  <RadioSelect
                    options={feedbackRadios}
                    name="report"
                    value={approve}
                    onChange={setApprove}
                  />
                  {reportedOn && reportedOn?.id && (
                    <div>
                      <RadioSelect
                        options={[
                          {
                            label: `Block ${reportedOn?.userName}`,
                            value: "block",
                          },
                        ]}
                        name="report"
                        value={approve}
                        onChange={setApprove}
                      />
                    </div>
                  )}
                </div>
                {approve === "block" && reportedOn && reportedOn?.id && (
                  <div className={styles.subRadio}>
                    <p>Choose a reason:</p>
                    <RadioSelect
                      options={blockReasonSelect}
                      name="reason"
                      value={blockReason}
                      onChange={setBlockReason}
                    />
                    {blockReason === "Other" && (
                      <Textarea
                        placeholder="Message"
                        value={otherReason}
                        onChange={onChangeOtherReason}
                        maxLength={240}
                        rows={3}
                      />
                    )}
                  </div>
                )}
                {reportedOn && reportedOn?.id && (
                  <div className={styles.radio}>
                    <RadioSelect
                      options={[
                        {
                          label: `Delete ${reportedOn?.userName}'s account`,
                          value: "delete",
                        },
                      ]}
                      name="report"
                      value={approve}
                      onChange={setApprove}
                    />
                  </div>
                )}
              </div>
              <div className={styles.button}>
                <Button
                  title="Send"
                  onClick={sendHandler}
                  disabled={disableButton}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReportModal;
