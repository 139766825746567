import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { PulseLoader } from "react-spinners";

import styles from "./styles.module.scss";
//api
import { getStatistics } from "../../services/statistics.service";

type TimeType = {
  title: "week" | "month" | "year";
};

type DataType = {
  users: number;
  name: string;
};

function ControlsBtn({ isActive = false, onClick = () => {}, title = "" }) {
  return (
    <div
      onClick={onClick}
      className={isActive ? styles.activeControlsBtn : styles.contorlsBtn}
    >
      <div
        className={
          isActive ? styles.activeControlsBtnText : styles.controlsBtnText
        }
      >
        {title}
      </div>
    </div>
  );
}

const CustomTooltip = (props: any) => {
  return (
    <div className={styles.tooltipWrapper}>
      <p className={styles.data}>{props?.payload[0]?.payload?.users}</p>
      <p className={styles.label}>{props?.label}</p>
    </div>
  );
};

function Charts({}: // setCurrentTime,
// statisticsData
{
  // setCurrentTime: (value: "week" | "year" | "month") => void;
  // statisticsData: any
}) {
  const [activeType, setActiveType] = useState(1);
  const [focusBar, setFocusBar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [barGraphData, setBarGraphData] = useState({});
  const [toolTipYPosition, setToolTipYPosition] = useState({});
  const [data, setData] = useState<DataType[]>([]);
  const controls: TimeType[] = [
    { title: "week" },
    { title: "month" },
    { title: "year" },
  ];

  const onGetStatistics = async (period: "week" | "year" | "month") => {
    try {
      setIsLoading(true);
      const { data } = await getStatistics(period);
      if (data?.success) {
        let periodData: DataType[] = [];
        if (period === "month" || period === "week") {
          periodData = data.totalPeriod.map((item: any) => {
            const period = item.name.split("-");
            return { ...item, name: `${period[1]}-${period[2]}` };
          });
        } else periodData = data.totalPeriod;
        setData(periodData);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  let localData = [
    {
      users: 0,
      name: "2023-04-16",
    },
    {
      users: 0,
      name: "2023-04-17",
    },
    {
      users: 0,
      name: "2023-04-18",
    },
    {
      users: 0,
      name: "2023-04-19",
    },
    {
      users: 0,
      name: "2023-04-20",
    },
    {
      users: 0,
      name: "2023-04-21",
    },
    {
      users: 0,
      name: "2023-04-22",
    },
    {
      users: 0,
      name: "2023-04-23",
    },
    {
      users: 0,
      name: "2023-04-24",
    },
    {
      users: 0,
      name: "2023-04-25",
    },
    {
      users: 0,
      name: "2023-04-26",
    },
    {
      users: 0,
      name: "2023-04-27",
    },
    {
      users: 0,
      name: "2023-04-28",
    },
    {
      users: 0,
      name: "2023-04-29",
    },
    {
      users: 0,
      name: "2023-04-30",
    },
    {
      users: 0,
      name: "2023-05-01",
    },
    {
      users: 0,
      name: "2023-05-02",
    },
    {
      users: 0,
      name: "2023-05-03",
    },
    {
      users: 0,
      name: "2023-05-04",
    },
    {
      users: 4,
      name: "2023-05-05",
    },
    {
      users: 0,
      name: "2023-05-06",
    },
    {
      users: 0,
      name: "2023-05-07",
    },
    {
      users: 1,
      name: "2023-05-08",
    },
    {
      users: 32,
      name: "2023-05-09",
    },
    {
      users: 27,
      name: "2023-05-10",
    },
    {
      users: 0,
      name: "2023-05-11",
    },
    {
      users: 0,
      name: "2023-05-12",
    },
    {
      users: 0,
      name: "2023-05-13",
    },
    {
      users: 0,
      name: "2023-05-14",
    },
    {
      users: 0,
      name: "2023-05-15",
    },
  ];

  useEffect(() => {
    console.log("GET activeType STATISTIC", activeType);
    onGetStatistics(controls[activeType]?.title);
  }, [activeType]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartsControls}>
        {controls.map((item, index) => {
          return (
            <ControlsBtn
              isActive={index === activeType}
              onClick={() => {
                setActiveType(index);
                // setCurrentTime(controls[index]?.title);
              }}
              title={item.title}
              key={index}
            />
          );
        })}
      </div>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <ResponsiveContainer width={"100%"} height={200}>
          <BarChart
            data={data} // data
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
            barSize={20}
            onMouseMove={(state: any) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}
          >
            <XAxis
              dataKey="name"
              padding={{ left: 10, right: 10 }}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 10 }}
              allowDuplicatedCategory={true}
              allowDataOverflow
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickMargin={15}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
              offset={-38}
              allowEscapeViewBox={{ x: true, y: true }}
              position={{ y: -toolTipYPosition + 150 }}
              wrapperStyle={{ outline: "none" }}
            />

            <Bar
              dataKey="users"
              // fill="#F9D9DC"
              background={false}
              barSize={26}
              radius={6}
              // allowDuplicatedCategory={true}
              isAnimationActive={true}
              label={(e: any) => {
                if (e?.index === focusBar) {
                  setToolTipYPosition(e?.height);
                }
                return <></>;
              }}
            >
              {data?.map(
                // data.map
                (entry, index) => (
                  <Cell
                    key={`cell-${index}-${entry.name}`}
                    stroke={"none"}
                    fill={focusBar === index ? "#E13F4E" : "#F9D9DC"}
                  />
                )
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default Charts;
