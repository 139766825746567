import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import find from "lodash/find";
import { addHours, startOfDay, format, isToday } from "date-fns";

import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
//assets
import { CloseIcon } from "../../assets";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
//components
import {
  Input,
  Button,
  Textarea,
  PrivateImage,
  DatePicker,
  Select,
  InputLocation,
} from "../../components";
//schemas
import { newMeetupSchema } from "../../schemas";
//api
import { getLinkForUploadImage } from "../../services/auth.service";
import { createMeetup, editMeetup } from "../../services/meetups.service";
import { uploadFile } from "../../services/file.service";
//constants
import { toastError } from "../../constants";
//types
import { MeetupType } from "../../types";
import { OptionType } from "../../components/Select";

const timeOptions = [
  {
    text: "12 AM",
    id: "0",
  },
  {
    text: "1 AM",
    id: "1",
  },
  {
    text: "2 AM",
    id: "2",
  },
  {
    text: "3 AM",
    id: "3",
  },
  {
    text: "4 AM",
    id: "4",
  },
  {
    text: "5 AM",
    id: "5",
  },
  {
    text: "6 AM",
    id: "6",
  },
  {
    text: "7 AM",
    id: "7",
  },
  {
    text: "8 AM",
    id: "8",
  },
  {
    text: "9 AM",
    id: "9",
  },
  {
    text: "10 AM",
    id: "10",
  },
  {
    text: "11 AM",
    id: "11",
  },
  {
    text: "12 PM",
    id: "12",
  },
  {
    text: "1 PM",
    id: "13",
  },
  {
    text: "2 PM",
    id: "14",
  },
  {
    text: "3 PM",
    id: "15",
  },
  {
    text: "4 PM",
    id: "16",
  },
  {
    text: "5 PM",
    id: "17",
  },
  {
    text: "6 PM",
    id: "18",
  },
  {
    text: "7 PM",
    id: "19",
  },
  {
    text: "8 PM",
    id: "20",
  },
  {
    text: "9 PM",
    id: "21",
  },
  {
    text: "10 PM",
    id: "22",
  },
  {
    text: "11 PM",
    id: "23",
  },
];

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onGetAll?: () => void;
  item?: MeetupType | null;
  onUpdate?: () => void;
}

interface ICreateMeetupFormData {
  title: string;
  price: string | number;
  organizersName: string;
  date: Date | null;
  time: string;
  minParticipants: string | number;
  maxParticipants: string | number;
  minAge: string | number;
  description: string;
  location: null | {
    name: string;
    coordinates: {
      latitude: string | number;
      longitude: string | number;
    };
  };
}

const initialValues = {
  title: "",
  price: "",
  organizersName: "",
  date: new Date(),
  time: "",
  location: null,
  minParticipants: "",
  maxParticipants: "",
  minAge: "",
  description: "",
};

const MeetupModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  onGetAll,
  item,
  onUpdate,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [commonError, setCommonError] = useState("");

  //Event image
  const [eventImg, setEventImg] = useState<string | undefined>(undefined);
  const [eventImgFile, setEventImgFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >(null);
  const [eventImgFileName, setEventImgFileName] = useState<string>("");
  const eventInputFile = useRef<HTMLInputElement>(null);

  //Organizer image
  const [orgImg, setOrgImg] = useState<string | undefined>(undefined);
  const [orgImgFile, setOrgImgFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >(null);
  const [orgImgFileName, setOrgImgFileName] = useState<string>("");
  const orgInputFile = useRef<HTMLInputElement>(null);

  const uploadImage = async (image: string, imageFileName: string) => {
    try {
      const resLinkImg = await getLinkForUploadImage(imageFileName);
      const imageLink = resLinkImg?.data?.links[0].downloadUrl;
      let blob = await fetch(image).then((r) => r.blob());
      const resUploadImg = await uploadFile(
        blob,
        resLinkImg?.data?.links[0].uploadUrl
      );
      return imageLink;
    } catch (e) {
      console.log("uploadImage error", e);
      return null;
    }
  };

  const handleCreateEditMeetup = async (
    body: any,
    item?: MeetupType | null
  ) => {
    try {
      if (item) {
        const { data } = await editMeetup(body, item.id);
        return data;
      } else {
        const { data } = await createMeetup(body);
        return data;
      }
    } catch (e) {
      throw e;
    }
  };

  const onCreate = async (
    formData: ICreateMeetupFormData,
    item?: MeetupType | null
  ) => {
    try {
      setIsLoading(true);
      const {
        title,
        price,
        organizersName,
        date,
        time,
        minParticipants,
        maxParticipants,
        minAge,
        description,
        location,
      } = formData;

      if (!eventImg || !orgImg || !location) {
        toast.error(toastError);
        return;
      }

      const body: any = {
        title,
        price,
        organizersName,
        date: addHours(startOfDay(date as Date), +time),
        minParticipants,
        maxParticipants,
        minAge,
        description,
        location: {
          name: location?.name,
          coordinates: {
            coordinates: [
              location?.coordinates?.longitude,
              location?.coordinates?.latitude,
            ],
          },
        },
      };

      if (!item) {
        body.type = "stroynik";
      }

      if (item) {
        if (item.eventImage !== eventImg) {
          const resEventImage = await uploadImage(eventImg, eventImgFileName);
          body.eventImage = resEventImage;
        } else {
          body.eventImage = item.eventImage;
        }
        if (item.organizersImage !== orgImg) {
          const resOrgImage = await uploadImage(orgImg, orgImgFileName);
          body.organizersImage = resOrgImage;
        } else {
          body.organizersImage = item.organizersImage;
        }
      } else {
        const resEventImage = await uploadImage(eventImg, eventImgFileName);
        const resOrgImage = await uploadImage(orgImg, orgImgFileName);

        body.eventImage = resEventImage;
        body.organizersImage = resOrgImage;
      }

      const data = await handleCreateEditMeetup(body, item);

      if (data?.success) {
        onGetAll && onGetAll();
        setIsOpen(false);
        toast.success(
          `Meetup has been successfully ${item ? "edited" : "created"}!`
        );
      }

      setIsLoading(false);
    } catch (error: any) {
      console.log("onCreate error", error);
      setIsLoading(false);
      toast.error(toastError);
      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  const handleOnSubmit = (values: ICreateMeetupFormData) => {
    onCreate(values, item);
  };

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    touched,
    setValues,
    setFieldValue,
  } = useFormik<ICreateMeetupFormData>({
    initialValues: initialValues,
    validationSchema: newMeetupSchema,
    validateOnChange: true,
    onSubmit: handleOnSubmit,
  });

  //Event image functions
  const openEventInputFile = () => {
    eventInputFile.current?.click();
  };
  const onSelectEventImg = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      const file = e.target.files[0];
      setEventImg(URL.createObjectURL(file));
      setEventImgFile(e.target.files[0]);
      setEventImgFileName(file?.name);
    } catch (error) {}
  };
  const onDeleteEventImg = () => {
    setEventImgFile(null);
    setEventImg(undefined);
    setEventImgFileName("");
  };

  //Organizer image functions
  const openOrgInputFile = () => {
    orgInputFile.current?.click();
  };
  const onSelectOrgImg = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      const file = e.target.files[0];
      setOrgImg(URL.createObjectURL(file));
      setOrgImgFile(e.target.files[0]);
      setOrgImgFileName(file?.name);
    } catch (error) {}
  };
  const onDeleteOrgImg = () => {
    setOrgImgFile(null);
    setOrgImg(undefined);
    setOrgImgFileName("");
  };

  useEffect(() => {
    if (item && isOpen) {
      console.log(item);
      setValues({
        title: item.title,
        price: item.price,
        organizersName: item.organizersName,
        date: new Date(item.date),
        time: format(new Date(item.date), "H"),
        location: item.location
          ? {
              name: item.location?.name,
              coordinates: {
                latitude:
                  item.location?.coordinates?.coordinates?.length > 1
                    ? item.location.coordinates.coordinates[1]
                    : 0,
                longitude:
                  item.location?.coordinates?.coordinates?.length > 0
                    ? item.location.coordinates.coordinates[0]
                    : 0,
              },
            }
          : null,
        minParticipants: item.minParticipants,
        maxParticipants: item.maxParticipants,
        minAge: item.minAge,
        description: item.description,
      });
      setEventImg(item?.eventImage || undefined);
      setOrgImg(item?.organizersImage || undefined);
    }
  }, [item, isOpen]);

  useEffect(() => {
    setCommonError("");
    if (!isOpen) {
      setValues(initialValues);
      setEventImg(undefined);
      setOrgImg(undefined);
    }
  }, [isOpen]);

  const filteredTimeOptions = useMemo(
    () =>
      values.date && isToday(values.date)
        ? timeOptions.filter((v) => {
            console.log(
              +v.id,
              +format(new Date(), "H"),
              +v.id > +format(new Date(), "H")
            );

            return +v.id > +format(new Date(), "H");
          })
        : timeOptions,
    [values.date]
  );

  console.log(filteredTimeOptions);

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h4>{!!item ? "Edit" : "New"} Event</h4>{" "}
          <CloseIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
        </div>
        <div className={styles.modalBody}>
          <div className={styles["meetup__form"]}>
            <div className={styles["meetup__form--columns"]}>
              <div className={styles["meetup__form--leftColumn"]}>
                <div>
                  <Input
                    id="title"
                    label="Event title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && errors.title}
                    // maxLength={15}
                    wrapperStyles={styles.input}
                  />
                </div>
                <div>
                  <Input
                    id="price"
                    label="Event price ($)"
                    value={values.price}
                    onChange={handleChange}
                    error={touched.price && errors.price}
                    maxLength={15}
                    wrapperStyles={styles.input}
                  />
                </div>
                <div>
                  <Input
                    id="organizersName"
                    label="Organizer’s name"
                    value={values.organizersName}
                    onChange={handleChange}
                    error={touched.organizersName && errors.organizersName}
                    wrapperStyles={styles.input}
                  />
                </div>
                <div className={styles["meetup__form--row"]}>
                  <DatePicker
                    className={styles["meetup__form--row-date-picker"]}
                    title="Meeting Date"
                    value={values.date}
                    onChange={(date) => setFieldValue("date", date)}
                    error={errors.date}
                    formatStr="iii, d MMM, yyyy"
                  />
                  <div className={styles["meetup__form--row-time"]}>
                    <Select
                      label="Time"
                      value={
                        find(timeOptions, { id: values.time })?.text as string
                      }
                      options={filteredTimeOptions}
                      onChange={(option: OptionType) =>
                        setFieldValue("time", option.id)
                      }
                      error={touched.time && errors.time}
                    />
                  </div>
                </div>
                <div>
                  <InputLocation
                    id="location"
                    label="Event location"
                    value={values.location?.name}
                    defaultValue={item?.location?.name}
                    onSelectPlace={(loc) => setFieldValue("location", loc)}
                    error={touched.location && errors.location}
                    wrapperStyles={styles.input}
                  />
                </div>
                <div className={styles["meetup__form--participants"]}>
                  <Input
                    id="minParticipants"
                    label="MIN participants"
                    value={values.minParticipants}
                    onChange={handleChange}
                    error={touched.minParticipants && errors.minParticipants}
                    wrapperStyles={styles.input}
                  />
                  <Input
                    id="maxParticipants"
                    label="MAX participants"
                    value={values.maxParticipants}
                    onChange={handleChange}
                    error={touched.maxParticipants && errors.maxParticipants}
                    wrapperStyles={styles.input}
                  />
                </div>
                <div>
                  <Input
                    id="minAge"
                    label="MIN age"
                    value={values.minAge}
                    onChange={handleChange}
                    error={touched.minAge && errors.minAge}
                    wrapperStyles={styles.input}
                  />
                </div>
              </div>
              <div className={styles["meetup__form--rightColumn"]}>
                <div className={styles["meetup__form--image"]}>
                  <h5 className={styles["meetup__form--image_title"]}>
                    Event image
                  </h5>
                  <div
                    onClick={openEventInputFile}
                    className={styles["meetup__form--image_btnBig"]}
                  >
                    {!!eventImg ? (
                      <PrivateImage
                        src={eventImg === null ? undefined : eventImg}
                        defaultsrc={PlaceholderAvatar}
                        alt="avatar"
                        className={styles["meetup__form--image_eventAvatar"]}
                        key={`avatar-${eventImg}`}
                        size={30}
                      />
                    ) : (
                      <div
                        className={styles["meetup__form--image_eventAvatar"]}
                      ></div>
                    )}
                  </div>
                  <div className={styles["meetup__form--image_info"]}>
                    <input
                      style={{ display: "none" }}
                      ref={eventInputFile}
                      type={"file"}
                      accept="image/*"
                      onChange={onSelectEventImg}
                      onClick={(e: any) => (e.target.value = null)}
                    />
                    <span
                      onClick={openEventInputFile}
                      className={styles["meetup__form--image_change"]}
                    >
                      Change image
                    </span>
                    {eventImg && (
                      <span
                        className={styles["meetup__form--image_delete"]}
                        onClick={onDeleteEventImg}
                      >
                        Delete
                      </span>
                    )}
                  </div>
                </div>
                <div className={styles["meetup__form--image"]}>
                  <h5 className={styles["meetup__form--image_title"]}>
                    Organizer’s image
                  </h5>
                  <div
                    onClick={openOrgInputFile}
                    className={styles["meetup__form--image_btn"]}
                  >
                    <PrivateImage
                      src={orgImg === null ? undefined : orgImg}
                      defaultsrc={PlaceholderAvatar}
                      alt="avatar"
                      className={styles["meetup__form--image_orgAvatar"]}
                      key={`avatar-${orgImg}`}
                      size={30}
                    />
                  </div>
                  <div className={styles["meetup__form--image_info"]}>
                    <input
                      style={{ display: "none" }}
                      ref={orgInputFile}
                      type={"file"}
                      accept="image/*"
                      onChange={onSelectOrgImg}
                      onClick={(e: any) => (e.target.value = null)}
                    />
                    <span
                      onClick={openOrgInputFile}
                      className={styles["meetup__form--image_change"]}
                    >
                      Change image
                    </span>
                    {orgImg && (
                      <span
                        className={styles["meetup__form--image_delete"]}
                        onClick={onDeleteOrgImg}
                      >
                        Delete
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Textarea
                id="description"
                label="Event description"
                value={values.description}
                onChange={handleChange}
                maxLength={240}
                rows={3}
                error={touched.description && errors.description}
                wrapperStyles={styles.input}
              />
            </div>
            {commonError && <p className={styles.commonError}>{commonError}</p>}
            <Button
              title={!!item ? "Save" : "Create"}
              onClick={() => handleSubmit()}
              disabled={isLoading}
              type="submit"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MeetupModal;
