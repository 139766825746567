import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//asssets
import { SmallRightIcon } from "../../assets";
//components
import ContentWrapper from "./ContentWrapper";
import Button from "../Button";
import Sidebar from "../Sidebar";

interface Props {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  buttons?: ReactNode;
}

export default function Layout({ children, title, subtitle, buttons }: Props) {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.layoutWrapper}>
          <Sidebar />
          <div className={styles.divider}></div>
          <div className={styles.body}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {subtitle && (
              <div className={styles.subPage}>
                <>
                  <div onClick={() => navigate(-1)} className={styles.subtitle}>
                    <SmallRightIcon className={styles.svg} />
                    <h3>{subtitle}</h3>
                  </div>
                  {buttons && <>{buttons}</>}
                </>
              </div>
            )}
            <ContentWrapper refMark="wrapper__body__content">
              {children}
            </ContentWrapper>
          </div>
        </div>
      </div>
    </>
  );
}
