import { useContext } from "react";

import { AdminContext } from "./admin";
import { NumbersContext } from "./numbers";

export const useAdminContext = () => {
  return useContext(AdminContext);
};
export const useNumbersContext = () => {
  return useContext(NumbersContext);
};

export { AppContextProvider } from "./AppContextProvider";
