import { useMemo, useState } from "react";
import { ClipLoader } from "react-spinners";

import styles from "./styles.module.scss";

import { getPublicLinkForImage } from "../../services/auth.service";
import clsx from "clsx";
import axios from "axios";

interface IPrivateVideo
  extends React.DetailedHTMLProps<
    React.SourceHTMLAttributes<HTMLSourceElement>,
    HTMLSourceElement
  > {
  // defaultsrc?: string;
  // wrapperClassName?: string;
  size?: string | number;
  controls?: boolean;
  timing?: number;
}

export default function PrivateVideo(props: IPrivateVideo) {
  const [isLoading, setIsLoading] = useState(true);
  const [publicUrl, setPublicUrl] = useState<string | undefined>(undefined);
  const privateUrl = useMemo(() => props.src, [props.src]);

  const downloadVideo = async () => {
    try {
      setIsLoading(true);
      if (!privateUrl || !!publicUrl) return;
      const resLinkVideo = await getPublicLinkForImage([privateUrl]);
      const videoLink = resLinkVideo?.data?.links[0].downloadLink;
      console.log({ resLinkVideo, videoLink });
      setPublicUrl(`${videoLink}${props.timing && `#t=${props.timing}`}`);
    } catch (e) {
      console.log({ e });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={clsx(styles.wrapper, props?.className)}>
      {/* {isLoading && (
        <div className={styles.loader}>
          <ClipLoader color="#e13f4e" size={props?.size || "35%"} />
        </div>
      )} */}
      <video
        controls={props.controls}
        key={`key-${publicUrl ?? privateUrl ?? props.src}`}
      >
        <source
          src={publicUrl ?? privateUrl ?? props.src}
          type={props.type}
          onError={() => downloadVideo()}
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
