import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";

import styles from "./styles.module.scss";
//components
import { Charts, Layout, StatisticInfo } from "../../../components";
//api
import {
  ExplorersPercent,
  getStatistics,
} from "../../../services/statistics.service";

const progress = [
  { title: "Education", subtitle: 1.345, statusPercent: 30 },
  { title: "Construction", subtitle: 645, statusPercent: 25 },
  { title: "Design", subtitle: 312, statusPercent: 21 },
  { title: "Corporate Services", subtitle: 235, statusPercent: 15 },
  { title: "Retail", subtitle: 235, statusPercent: 12 },
  { title: "Energy & Mining", subtitle: 235, statusPercent: 8 },
  { title: "Manufacturing", subtitle: 235, statusPercent: 7 },
  { title: "Finance", subtitle: 235, statusPercent: 6 },
];
export default function Statistics() {
  const [isLoading, setIsLoading] = useState(false); //true
  const [statisticsData, setStatisticsData] = useState<ExplorersPercent>();

  const onGetStatistics = async (period: "week" | "year" | "month") => {
    try {
      setIsLoading(true);
      const { data } = await getStatistics(period);
      if (data?.success) {
        // console.log("data", data);

        setStatisticsData(data);
        // verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    onGetStatistics("year");
  }, []);

  const staticInfo = [
    {
      title: "Verified users",
      subtitle: `${statisticsData?.verifiedUsers}`,
      todayInfo: `${statisticsData?.verifiedUsersToday}`,
      percent: `${Math.abs(
        Math.round(statisticsData?.verifiedUsersPercent || 0)
      )}%`,
      isIncrease:
        typeof statisticsData?.verifiedUsersPercent === "number" &&
        statisticsData?.verifiedUsersPercent > 0
          ? true
          : false,
    },
    {
      title: "Posts",
      subtitle: `${statisticsData?.totalPosts}`,
      todayInfo: `${statisticsData?.newPostsToday}`,
      percent: `${Math.abs(Math.round(statisticsData?.newUsersPercent || 0))}%`,
      isIncrease:
        typeof statisticsData?.newUsersPercent === "number" &&
        statisticsData?.newUsersPercent > 0
          ? true
          : false,
    },
    {
      title: "Matches",
      subtitle: `${statisticsData?.totalMatches}`,
      todayInfo: `${statisticsData?.newMatchesToday}`,
      percent: `${Math.abs(
        Math.round(statisticsData?.newMatchesPercent || 0)
      )}%`,
      isIncrease:
        typeof statisticsData?.newMatchesPercent === "number" &&
        statisticsData?.newMatchesPercent > 0
          ? true
          : false,
    },
    {
      title: "Deleted Users",
      subtitle: `${statisticsData?.totalDeletedUsers}`,
      todayInfo: `${statisticsData?.deletedUsersToday}`,
      percent: `${Math.abs(
        Math.round(statisticsData?.deletedUsersPercent || 0)
      )}%`,
      // isIncrease:
      //   typeof statisticsData?.deletedUsersPercent === "number" &&
      //   statisticsData?.deletedUsersPercent > 0
      //     ? true
      //     : false,
    },
  ];

  return (
    <Layout title={"Statistics"}>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <>
          <div className={styles.statisticBody}>
            <div className={styles.statistic}>
              <StatisticInfo
                percent={`${Math.abs(
                  Math.round(statisticsData?.newUsersPercent || 0)
                )}%`} // `${Math.abs(Math.round(statisticsData?.usersPercent || 0))}%`
                subtitle={statisticsData?.totalUsers?.toString() || "0"} // `${statisticsData?.totalUsers}`
                title="All Users"
                todayInfo={statisticsData?.newUsersToday?.toString() || "0"} // `${statisticsData?.newUsersToday}`
                isMain
                isIncrease={
                  typeof statisticsData?.newUsersPercent === "number" &&
                  statisticsData?.newUsersPercent > 0
                    ? true
                    : false
                }
              />
            </div>
            <div className={styles.chartBody}>
              <div className={styles.chart}>
                <Charts
                // setCurrentTime={setCurrentTime}
                // statisticsData={statisticsData?.totalPeriod}
                />
              </div>
            </div>
          </div>
          <div className={styles.commonInfo}>
            <div className={styles.dividerTop}></div>
            {staticInfo.map((statiscit, index) => {
              return (
                <div key={index} className={styles.statisticInfo}>
                  {(index === 1 || index === 2) && (
                    <div className={styles.dividerLeft}></div>
                  )}
                  <StatisticInfo
                    percent={statiscit.percent}
                    subtitle={statiscit.subtitle ? `${statiscit.subtitle}` : ""}
                    title={statiscit.title}
                    todayInfo={
                      statiscit.todayInfo ? `${statiscit.todayInfo}` : ""
                    }
                    isIncrease={statiscit.isIncrease}
                  />
                  {index === 2 && <div className={styles.dividerRight}></div>}
                </div>
              );
            })}
            <div className={styles.dividerBottom}></div>
          </div>
          {/* <div>
            <h3 className={styles.titleProgress}>Top Industries</h3>
            <div className={styles.progressWrapper}>
              {progress.map((progressItem, index) => {
                return <Progress key={index} {...progressItem} />;
              })}
            </div>
          </div> */}
        </>
      )}
    </Layout>
  );
}
