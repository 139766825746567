import * as yup from "yup";

import validationValues from "./values";

export const newGroupSchema = yup
  .object({
    category: validationValues.groupCategory,
    title: validationValues.groupTitle,
    description: validationValues.groupDescription,
  })
  .required();
