import * as yup from "yup";

import validationValues from "./values";

export const newStaffSchema = yup
  .object({
    firstName: validationValues.firstName,
    lastName: validationValues.lastName,
    email: validationValues.email,
    password: validationValues.password,
    role: validationValues.role,
  })
  .required();
