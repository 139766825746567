import * as yup from "yup";
import { GroupCategoryEnum } from "../types";

const passError =
  "Password must be at least 8 characters long and include an uppercase letter, a lower case letter, a number and a special character";

const validationValues = {
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is a required field"),
  password: yup
    .string()
    .min(8, passError)
    .max(128, "Password must be at most 128 characters")
    .required(passError)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9])[\W_A-Za-z0-9]{8,}$/gm,
      passError
    )
    .typeError(passError),
  firstName: yup
    .string()
    .min(2, "The First name field should contain more than 2 letters")
    .required("First Name is a required field"),
  lastName: yup
    .string()
    .min(2, "The Last name field should contain more than 2 letters")
    .required("Last Name is a required field"),
  role: yup.string().required("Role is a required field"),
  title: yup
    .string()
    .min(2, "The Title field should contain more than 2 letters")
    .required("Title is a required field"),
  about: yup
    .string()
    .min(2, "About field should contain more than 2 letters")
    .required("The About is a required field"),

  //Group
  groupCategory: yup
    .string()
    .oneOf([
      GroupCategoryEnum.meetAndGreet,
      GroupCategoryEnum.relationshipCoaching,
    ]),
  groupTitle: yup.string().when("category", {
    is: GroupCategoryEnum.relationshipCoaching,
    then: () =>
      yup
        .string()
        .min(2, "Title field should contain more than 2 letters")
        .required("The Title is a required field"),
  }),
  groupDescription: yup.string().when("category", {
    is: GroupCategoryEnum.relationshipCoaching,
    then: () =>
      yup
        .string()
        .min(2, "Description field should contain more than 2 letters")
        .required("The Description is a required field"),
  }),
};

export default validationValues;
