import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";
import moment from "moment";

import styles from "./styles.module.scss";
//assets
import PlaceholderAvatar from "../../../assets/images/avatar.png";
import { LocationPointIcon, VerificatiionIcon } from "../../../assets";
//components
import {
  Button,
  ImageWithConvert,
  Layout,
  PrivateImage,
  RadioButtons,
} from "../../../components";
import AccountDetails from "./AccountDetails";
import PhotosDetails from "./Photos";
import VideoDetails from "./Video";
import PostsDetails from "./Posts";
//api
import {
  blockUser,
  deleteUser,
  getUser,
  getUserPosts,
} from "../../../services/users.service";
//types
import { UserType } from "../../../types";
//utils
import { showUserStatus } from "../../../utils";
//constants
import { VERIFICATION_STATUSES, toastError } from "../../../constants";
//context
import { useAdminContext } from "../../../context";
//modals
import Delete from "../../../modals/Delete";
import BlockUser from "../../../modals/BlockUser";

interface Props {}

const UserProfile: FC<Props> = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<
    "About" | "Photos" | "Videos" | "Posts"
  >("About");
  const [search, setSearch] = useState<string>("");
  const [userState, setUserState] = useState<UserType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [postsNumber, setPostsNumber] = useState<number>(0);
  const navigate = useNavigate();
  const { id } = useParams();

  const { admin, adminLoading } = useAdminContext();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState<boolean>(false);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const onGetUser = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getUser(id);
      if (data?.success) {
        setUserState(data?.user);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("onGetUser error", error);
      setIsLoading(false);
    }
  };
  const onGetUserPosts = async (id: string) => {
    try {
      const { data } = await getUserPosts({
        userId: id,
        page: 1,
        limit: 1,
      });
      if (data?.success) {
        setPostsNumber(data?.totalPosts);
      }
    } catch (error) {
      console.log("onGetUserPosts error", error);
    }
  };

  useEffect(() => {
    if (id) {
      onGetUser(id);
      onGetUserPosts(id);
    }
  }, [id]);

  const onDeleteEmployer = async () => {
    try {
      setIsLoading(true);
      const { data } = await deleteUser(userState?.id || id || "0");
      if (data?.success) {
        setIsLoading(false);
        navigate(-1);
        toast.success("User has been successfully deleted");
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
    }
  };

  const onBlock = async (reason: string, type: "block" | "unblock") => {
    try {
      setIsLoading(true);
      const { data } = await blockUser({
        userId: userState?.id || id || "",
        status: type,
        reason: reason,
      });
      if (data?.success) {
        setIsLoading(false);
        onGetUser(userState?.id || "");
        toast.success(
          type === "block"
            ? "User has been blocked"
            : "User has been unblocked "
        );
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
    }
  };

  const userPhoto = useMemo(
    () => (userState?.photos?.main ? userState.photos.main : undefined),
    [userState]
  );

  const userPhotos = useMemo(() => {
    if (!userState?.photos || !userState?.photos?.main) return [];

    const array = [userState?.photos?.main];
    if (userState?.photos?.others?.length > 0)
      array.push(...userState?.photos?.others);

    return array;
  }, [userState]);

  return (
    <Layout
      subtitle={"Back to all"}
      buttons={
        <>
          {!adminLoading && admin?.role !== "manager" && (
            <div className={styles.btnWrapper}>
              <Button
                title={userState?.block?.isBlocked ? "Unblock" : "Block"}
                styleType="outlined"
                color="#000000"
                onClick={() => {
                  if (userState?.block?.isBlocked) {
                    onBlock?.(userState?.block.reason, "unblock");
                  } else setIsShowBlockModal(true);
                }}
                style={{
                  border: "none",
                  width: "93px",
                  background: "#E4E4E4",
                  borderRadius: "16px",
                  color: "black",
                }}
              />
              <Button
                title={"Delete"}
                styleType="filled"
                onClick={() => setIsShowDeleteModal(true)}
                loading={isLoadingDelete}
                style={{
                  border: "none",
                  width: "93px",
                  background: "#FF3C3C",
                  borderRadius: "16px",
                }}
              />
            </div>
          )}
        </>
      }
    >
      <>
        <BlockUser
          title={userState?.block?.isBlocked ? "Unblock user" : "Block user"}
          isBlocked={userState?.block as any}
          isLoading={isLoadingDelete || false}
          isOpen={isShowBlockModal}
          setIsOpen={setIsShowBlockModal}
          onBlock={onBlock}
          type="block"
          userId={userState?.id || "0"}
        />
        <Delete
          title="Delete user"
          onDelete={onDeleteEmployer}
          isLoading={isLoadingDelete || false}
          isOpen={isShowDeleteModal}
          setIsOpen={setIsShowDeleteModal}
          type="delete"
          userId={userState?.id || "0"}
        />
        <div className={styles.wrapper}>
          {isLoading && !userState ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.info}>
                  <PrivateImage
                    src={userPhoto}
                    defaultsrc={PlaceholderAvatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                  <div className={styles.nameWrapper}>
                    <p className={styles.name}>
                      {userState?.userName}{" "}
                      {userState?.verificationDocument?.status ===
                        VERIFICATION_STATUSES.Approved && (
                        <>
                          <VerificatiionIcon />
                        </>
                      )}
                      <span>
                        {" "}
                        {moment().diff(moment(userState?.birthDate), "years")}
                      </span>
                    </p>
                    <div className={styles.location}>
                      <div className={styles.locationImage}>
                        <LocationPointIcon fill="#535860" />
                      </div>
                      {userState?.location?.name}
                    </div>
                    <div className={styles.role}>
                      {userState?.status && showUserStatus(userState)}
                    </div>
                  </div>
                </div>
                <div className={styles.socialbtnWrapper}></div>
              </div>
              <div className={styles.tabWrapper}>
                <RadioButtons
                  tag={[0, userPhotos?.length, 0, postsNumber]}
                  name="time"
                  options={[
                    { label: "About", value: "About" },
                    { label: "Photos", value: "Photos" },
                    { label: "Videos", value: "Videos" },
                    { label: "Posts", value: "Posts" },
                  ]}
                  value={currentTab}
                  onChange={setCurrentTab as (value: string) => void}
                />
              </div>
              {currentTab === "About" && <AccountDetails user={userState} />}
              {currentTab === "Photos" && <PhotosDetails user={userState} />}
              {currentTab === "Videos" && <VideoDetails user={userState} />}
              {currentTab === "Posts" && <PostsDetails user={userState} />}
            </>
          )}
        </div>
      </>
    </Layout>
  );
};

export default UserProfile;
