export enum VerificationDocumentStatus {
  "Approved",
  "Declined",
  "Verification",
}

export type VerificationDocumentType = {
  url: string;
  status: "Approved" | "Declined" | "Verification";
};

export type GeneralInfoType = {
  height: null | {
    value: "feet" | "cm";
    type: string;
  };
  weight: null | {
    value: "lbs" | "kg";
    type: string;
  };
  eyeColor?: string;
  previouslyMarried?: boolean;
  kids?: boolean;
  useAlcohol?: boolean;
  useTobacco?: boolean;
  useMarijuana?: boolean;
};

export type LikesType = {
  hobbies: string[];
  interests: string[];
  values: string[];
  manuallyAdded: null | {
    hobbies: string[];
    interests: string[];
    values: string[];
  };
};

export type VideoType = {
  url: string;
  question?: string;
  questionNumber?: number;
};

export interface UserType {
  id: string;
  email: string;
  phone: null | {
    number: string;
    countryCode: string;
  };
  userName: string;
  birthDate: string;
  gender: "Male" | "Female";
  location: null | {
    name: string;
    coordinates: number[];
  };
  verificationDocument: VerificationDocumentType;
  generalInfo: null | GeneralInfoType;
  likes: null | LikesType;
  status: null | {
    student?: null | any;
    employee?: null | any;
    selfEmployed?: null | any;
    unemployed?: boolean;
  };
  photos: null | {
    main: null | string;
    others: string[];
  };
  ethnicity: string[];
  isTutorialCompleted: boolean;
  isActivated: boolean;
  isDeleted: boolean;
  isOnline: boolean;
  lastVisit: string;
  registrationMethod: "email" | "Google" | "Apple";
  updatedAt: string;
  createdAt: string;
  block: null | {
    isBlocked: boolean;
    reason: string;
  };
  description?: string;
  lookingFor?: string;
  faith?: string;
  video?: VideoType[];
  paidVideoInterview?: string;
}

export interface AdminType {
  id: string;
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  createdAt: string;
  updatedAt: string;
}

export interface PostType {
  id: string;
  authorId: string;
  text: string;
  mediaFile?: null | {
    url: string;
    type: "image" | "video";
  };
  createdAt: string;
  totalLikes: number;
  likes: {
    id: string;
    authorId: string;
  }[];
  totalComments: number;
}

export type ReportUserType = {
  id?: string;
  userName?: string;
  verificationDocument?: VerificationDocumentType;
  photos?: null | {
    main: null | string;
    others: string[];
  };
  isDeleted?: boolean;
};

export interface ReportType {
  id: string;
  reporter: string;
  reportedOn: string;
  type: string;
  category: string;
  description: string;
  attachment: string;
  createdAt: string;
  reporterInfo: ReportUserType;
  reportedOnInfo?: ReportUserType;
}

export interface InstructorType {
  id: string;
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  title: string;
  description: string;
  groups: GroupType[];
  groupsCount: number;
  createdAt: string;
  updatedAt: string;
}

export enum GroupCategoryEnum {
  "relationshipCoaching" = "relationshipCoaching",
  "meetAndGreet" = "meetAndGreet",
}
export const GroupCategoryObj = {
  [GroupCategoryEnum.relationshipCoaching]: "Relationship Coaching",
  [GroupCategoryEnum.meetAndGreet]: "Meet and Greet",
};

export const GroupCategoryArr = [
  { text: "Meet and Greet", id: GroupCategoryEnum.meetAndGreet },
  {
    text: "Relationship Coaching",
    id: GroupCategoryEnum.relationshipCoaching,
  },
];

export interface GroupType {
  id: string;
  category: GroupCategoryEnum;
  title: string;
  description: string;
  followersCount: number;
  meetingsCount: number;
  meetings: string[];
  createdAt: string;
  updatedAt: string;
}

export interface GroupDetailType extends GroupType {
  instructor: InstructorType;
}

export interface MeetingType {
  id: string;
  startDate: string;
  link: string | null;
  description: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface MeetupType {
  id: string;
  authorId: string;
  type: string;
  eventImage: string;
  title: string;
  date: string;
  location: null | {
    name: string;
    coordinates: {
      type: string;
      coordinates: number[];
    };
  };
  minParticipants: number;
  maxParticipants: number;
  minAge: number;
  description: string;
  price: number;
  organizersName: string;
  organizersImage: string;
  members: string[];
  createdAt: string;
  author?: null | {
    id: string;
    userName: string;
    photos: null | {
      main: null | string;
      others: string[];
    };
  };
}

export interface RequestType {
  id: string;
  userId: string;
  name: string;
  email: string;
  phone: string;
  coverLetter: string;
  meetupId: string;
  meetup: {
    title: string;
  };
  createdAt: string;
  author: {
    id: string;
    userName: string;
    photos: {
      main: string;
    };
  };
}
