/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { format } from "date-fns";

// components
import AddMeetingLink from "../AddMeetingLink";
import GroupMeetingActions from "../GroupMeetingActions";
import { Pagination, Table } from "../../../../../components";

// styles
import styles from "./styles.module.scss";

const GroupMeetings = ({
  isLoading,
  meetings,
  totalMeetings,
  page,
  setPage,
  setSortBy,
  refetchMeetings,
}: any) => {
  const columns = useMemo(
    () => [
      {
        header: "Date & Time",
        accessor: "startDate",
        Cell: (value: any) =>
          format(new Date(value.startDate), "MMM d, yyyy, ha"),
        width: 200,
        bold: true,
        sorting: true,
      },
      {
        header: "Meeting info",
        accessor: "description",
        Cell: (value: any) => (
          <div className={styles["meetings__table--description"]}>
            {value.description}
          </div>
        ),
        overflow: "hidden",
      },
      {
        header: "Link",
        accessor: "link",
        Cell: (value: any) =>
          value.link ? (
            <a
              className={styles["meetings__table--link"]}
              href={value.link}
              target="_blank"
              rel="noreferrer"
            >
              {value.link}
            </a>
          ) : (
            <AddMeetingLink
              meetingId={value.id}
              refetchMeetings={refetchMeetings}
            />
          ),
        width: 300,
      },
      {
        header: "",
        accessor: "",
        Cell: (value: any) => (
          <GroupMeetingActions
            meetingId={value.id}
            refetchMeetings={refetchMeetings}
          />
        ),
        width: 60,
        align: "center",
      },
    ],
    []
  );

  return (
    <Table
      loading={isLoading}
      columns={columns}
      data={meetings}
      handleSortBy={setSortBy}
      footer={
        <Pagination page={page} total={totalMeetings} onChangePage={setPage} />
      }
    />
  );
};

export default GroupMeetings;
