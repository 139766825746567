import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//components
import Checkbox from "../Checkbox";
import ActiveButton from "../ActiveButtons/ActiveButton";
import DeclineButton from "../ActiveButtons/DeclineButton";
import PrivateImage from "../PrivateImage";
//modals
import ApproveModal from "../../modals/Approve";
import AgreementModal from "../../modals/Agreement";
//hookes
import { useFormatDate } from "../../hooks/formatDate";
//types
import { UserType } from "../../types";

interface Props {
  id: string;
  image: null;
  name: string;
  description: string;
  ownerLocation: string;
  businessLocation: string;
  businessType: string;
  revenue: string;
  employees: string;
}

const PhotoVerificationItem = ({
  item,
  allChecked,
  setVerifyUser,
  isLoading,
  handleVerify,
}: {
  item: UserType;
  allChecked: boolean;
  setVerifyUser: (item: any) => void;
  isLoading: boolean;
  handleVerify: (verifyData?: {
    id: string;
    status: "Approved" | "Declined";
    reason?: string;
  }) => void;
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);

  useEffect(() => {
    if (isChecked) {
      setVerifyUser((current: any) => [...current, item.id]);
    }
    if (!isChecked)
      setVerifyUser((current: any) =>
        current.filter((id: any) => id != item.id)
      );
  }, [isChecked]);

  useEffect(() => {
    return () => setVerifyUser([]);
  }, []);

  const formatedDate = useFormatDate(item.updatedAt);

  const verify = (status?: "Approved" | "Declined", reason?: string) => {
    if (isDelete) {
      handleVerify({
        id: item.id,
        status: status || "Declined",
        reason: reason || "",
      });
    } else handleVerify({ id: item.id, status: "Approved" });
  };

  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={showConfirmModal}
        setIsOpen={setShowConfirmModal}
        // text={isDelete ? "Decline verification?" : "Verify user?"}
        text="Verify user?"
        title="Verification"
        onAccept={verify}
      />
      <ApproveModal
        isOpen={showApproveModal}
        setIsOpen={setShowApproveModal}
        onAccept={verify}
        user={item}
      />
      <div className={styles.businessBody}>
        <div className={styles.wrapper}>
          <div className={styles.checkbox}>
            <Checkbox
              isChecked={isChecked}
              setIsChecked={() => setIsChecked((current) => !current)}
            />
          </div>
          <div
            className={styles.staffName}
            onClick={() => {
              setShowApproveModal(true);
              setDelete(true);
            }}
          >
            {/* <StaffItem image={item.avatar} name={`${item.firstName} ${item.lastName}`} /> */}
            {`${item.userName}`}
          </div>
          <div
            className={styles.ownerLocationBody}
            onClick={() => {
              setShowApproveModal(true);
              setDelete(true);
            }}
          >
            <div className={styles.ownerLocationWrapper}>
              <p>{formatedDate}</p>
            </div>
          </div>
          <div>
            <div
              onClick={() => {
                setShowApproveModal(true);
                setDelete(true);
              }}
              className={styles.businessLocationWrapper}
            >
              <div className={styles.image}>
                <PrivateImage
                  src={item?.verificationDocument?.url}
                  alt="verification image"
                />
              </div>
            </div>
          </div>

          {/* {me?.role !== "manager" && ( */}
          <div className={styles.acceptButtons}>
            <div
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              <ActiveButton />
            </div>
            <div
              onClick={() => {
                setShowApproveModal(true);
                setDelete(true);
              }}
            >
              <DeclineButton />
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};
export default PhotoVerificationItem;
