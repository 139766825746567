import clsx from "clsx";

import styles from "./styles.module.scss";
//components
import DecreaseStatistic from "../Statistic/DecreaseStatistic";
import IncreaseStatistic from "../Statistic/IncreaseStatistic";

interface Props {
  title: string;
  subtitle: string;
  percent: string;
  todayInfo: string;
  isIncrease?: boolean;
  isMain?: boolean;
}
export default function StatisticInfo({
  title,
  subtitle,
  percent,
  todayInfo,
  isIncrease,
  isMain,
}: Props) {
  return (
    <div>
      <h3 className={styles.subtitle}>{title}</h3>{" "}
      <h4 className={styles.infoPerDay}>{subtitle}</h4>
      <div className={styles.divider}></div>
      <div>
        <span className={styles.today}>Today</span>
        <div
          className={clsx({
            [styles.todayWrapper]: isMain,
            [styles.todaySimple]: !isMain,
          })}
        >
          <h5>{todayInfo}</h5>
          {typeof isIncrease !== "undefined" ? (
            isIncrease ? (
              <IncreaseStatistic percent={percent} />
            ) : (
              <DecreaseStatistic percent={percent} />
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
