import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

// utils
import { getAccessToken } from "../../utils/tokenStorage";

const PrivateRoute: FC = (): JSX.Element => {
  const token = getAccessToken();

  return token ? <Outlet /> : <Navigate to={"/signin"} />;
};

export default PrivateRoute;
