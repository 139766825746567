import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

type MeetupsAllParams = {
  page?: number;
  limit?: number;
  search?: string;
};

interface ICreateMeetupRequest {
  type: string;
  eventImage: string;
  title: string;
  date: Date;
  location: {
    name: string;
    coordinates: {
      coordinates: string[];
    };
  };
  minParticipants: string;
  maxParticipants: string;
  minAge: string;
  description: string;
  price: string;
  organizersName: string;
  organizersImage: string;
}

interface ResolveMeetupRequestParams {
  id: string;
  status: "Approved" | "Declined";
}

export const getAllMeetups = async ({
  page = 1,
  limit = 10,
  search = "",
}: MeetupsAllParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page: page,
      limit: limit,
    };
    const body: any = {};
    if (!!search) body.search = search;

    axiosInstance
      .post(`meetup/events-admin-panel`, body, {
        params: params,
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createMeetup = async (
  body: ICreateMeetupRequest
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`meetup`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteMeetup = async (
  meetupId: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`meetup?id=${meetupId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const editMeetup = async (
  body: ICreateMeetupRequest,
  meetupId: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`meetup?id=${meetupId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getAllMeetupRequests = async ({
  page = 1,
  limit = 10,
  search = "",
}: MeetupsAllParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page: page,
      limit: limit,
    };
    const body: any = {};
    if (!!search) body.search = search;

    axiosInstance
      .post(`meetup/request/list`, body, {
        params: params,
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const resolveMeetupRequest = async ({
  id,
  status,
}: ResolveMeetupRequestParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      id,
    };
    const body: any = { status };

    axiosInstance
      .put(`meetup/request`, body, {
        params: params,
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
