import React from "react";

import styles from "./styles.module.scss";

//components
import ContentWrapper from "../Layout/ContentWrapper";

const Popup = ({
  onEdit,
  onDelete,
}: {
  onEdit?: () => any;
  onDelete?: () => any;
}) => {
  return (
    <div className={styles.popap}>
      <ContentWrapper className={{ padding: "10px", marginBottom: 0 }}>
        {onEdit && (
          <p
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className={styles.edit}
          >
            Edit
          </p>
        )}
        {onDelete && (
          <p
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            className={styles.delete}
          >
            Delete
          </p>
        )}
      </ContentWrapper>
    </div>
  );
};

export default Popup;
