import { ChangeEvent, FC, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
//assets
import { CloseIcon } from "../../assets";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
//components
import { Input, Button, Select, PrivateImage } from "../../components";
//schemas
import { newStaffSchema } from "../../schemas";
//api
import { getLinkForUploadImage } from "../../services/auth.service";
import { createStaff } from "../../services/staff.service";
import { uploadFile } from "../../services/file.service";
//constants
import { toastError } from "../../constants";

const LIMIT_PER_PAGE = 10;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onGetAllStaff: (page: number, limit: number) => void;
}

const ManagerModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  onGetAllStaff,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [role, setRole] = useState<string>("Moderator");

  const [commonError, setCommonError] = useState("");
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const [avatarFile, setAvatarFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >();
  const [avatarFileName, setAvatarFileName] = useState<string>("");
  const inputFile = useRef<HTMLInputElement>(null);

  const onCreate = async (values: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: string;
  }) => {
    try {
      setIsLoading(true);
      let imageLink = "";
      if (avatarFile && avatar) {
        //@ts-ignore
        const resLinkImg = await getLinkForUploadImage(avatarFileName);
        imageLink = resLinkImg?.data?.links[0].downloadUrl;
        let blob = await fetch(avatar).then((r) => r.blob());
        const resUploadImg = await uploadFile(
          blob,
          resLinkImg?.data?.links[0].uploadUrl
        );
      }
      const { data } = await createStaff({
        avatar: imageLink || null,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        role: values.role?.toLowerCase(),
      });
      if (data?.success) {
        onGetAllStaff(1, LIMIT_PER_PAGE);
        setIsOpen(false);
        setAvatar(undefined);
        setAvatarFile(undefined);
        setAvatarFileName("");
        toast.success("User has been successfully created");
      }
      setIsLoading(false);
    } catch (error: any) {
      console.log("onCreate error", error);
      setIsLoading(false);
      toast.error(toastError);
      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  const { values, errors, handleSubmit, handleChange, touched, setFieldValue } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "Moderator",
      },
      validationSchema: newStaffSchema,
      validateOnChange: true,
      onSubmit: onCreate,
    });

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      let file = e.target.files[0];
      setAvatar(URL.createObjectURL(file));
      setAvatarFile(file);
      setAvatarFileName(file?.name);
    } catch (error) {}
  };

  const onDeleteAvatar = () => {
    setAvatarFile("");
    setAvatar(undefined);
    setAvatarFileName("");
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>New Manager</h5>{" "}
          <CloseIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.profileSection}>
            <div className={styles.profileBody}>
              <div className={styles.avatarBody}>
                <div onClick={showOpenFileDialog} className={styles.avatarBtn}>
                  <PrivateImage
                    src={avatar}
                    defaultsrc={PlaceholderAvatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                </div>
              </div>
              <div className={styles.avatarInfoBody}>
                <div>
                  <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    type={"file"}
                    accept="image/*"
                    onChange={onSelectFile}
                    onClick={(e: any) => (e.target.value = null)}
                  />
                  <span onClick={showOpenFileDialog}>Change Avatar</span>
                </div>
                {avatar && (
                  <span className={styles.deleteBody} onClick={onDeleteAvatar}>
                    Delete
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.dividerX}></div>
          <div className={styles.form}>
            <div className={styles.names}>
              <Input
                id="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && errors.firstName}
                maxLength={15}
                wrapperStyles={styles.input}
              />
              <Input
                id="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && errors.lastName}
                maxLength={15}
                wrapperStyles={styles.input}
              />
            </div>
            <div className={styles.email}>
              <Input
                id="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && errors.email}
                wrapperStyles={styles.input}
              />
            </div>
            <div className={styles.password}>
              <Input
                id="password"
                label="Password"
                isShow
                type="password"
                value={values.password}
                onChange={handleChange}
                error={touched.password && errors.password}
                wrapperStyles={styles.input}
              />
            </div>
            <div className={styles.role}>
              <Select
                label="Role"
                options={["Moderator"]}
                // value={role}
                // onChange={setRole}
                value={values.role}
                onChange={(v: any) => setFieldValue("role", v)}
              />
            </div>
            {commonError && <p className={styles.commonError}>{commonError}</p>}
            <Button
              title="Create"
              onClick={() => handleSubmit()}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManagerModal;
