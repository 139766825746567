import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import PlaceholderAvatar from "../../assets/images/avatar.png";
import { VerificatiionIcon } from "../../assets";
//constants
import { VERIFICATION_STATUSES } from "../../constants";
//types
import { VerificationDocumentType } from "../../types";
//components
import ImageWithConvert from "../ImageWithConvert";
import PrivateImage from "../PrivateImage";

interface Props {
  image: undefined | string;
  name: string;
  small?: boolean;
  verificationDocument?: VerificationDocumentType;
  email?: string;
}

const StaffItem = ({
  image,
  name,
  small,
  verificationDocument,
  email,
}: Props) => {
  return (
    <div
      className={clsx({
        [styles.avatarWrapper]: !small,
        [styles.avatarWrapperSmall]: small,
      })}
    >
      {/* <ImageWithConvert
        src={image ? image : PlaceholderAvatar}
        alt="avatar"
        className={styles.avatar}
      /> */}
      <PrivateImage
        src={image}
        defaultsrc={PlaceholderAvatar}
        alt="avatar"
        className={styles.avatar}
      />
      <div className={styles.infoWrapper}>
        <div className={styles.nameWrapper}>
          <p className={styles.name}>{name}</p>
          {verificationDocument?.status === VERIFICATION_STATUSES.Approved && (
            <>
              <VerificatiionIcon />
            </>
          )}
        </div>
        {email && <p className={styles.email}>{email}</p>}
      </div>
    </div>
  );
};
export default StaffItem;
