import Calendar from "react-calendar";
import { format } from "date-fns";
import clsx from "clsx";

// hooks
import useBoolean from "../../hooks/useBoolean";
import { useOutsideClick } from "../../hooks/useOutsideClick";

// assets
import { ArrowDownIcon } from "../../assets";

// styles
import "react-calendar/dist/Calendar.css";
import "./calendar.scss";
import styles from "./styles.module.scss";
import { useRef } from "react";

interface IDatePickerProps {
  className?: string;
  placeholder?: string;
  title: string;
  value: Date | null;
  error?: string | null;
  minDate?: Date;
  formatStr?: string;
  onChange: (date: Date) => void;
}

const DatePicker = ({
  className,
  placeholder = "Select Date",
  title,
  value,
  error = null,
  minDate = new Date(),
  formatStr = "iiii, d MMMM, yyyy",
  onChange,
}: IDatePickerProps) => {
  const datePickerRef = useRef(null);

  const {
    value: isOpenDatePicker,
    toggle: toggleDatePicker,
    setFalse: closeDatePicker,
  } = useBoolean(false);

  useOutsideClick(datePickerRef, closeDatePicker);

  const onChangeDate = (date: Date) => {
    onChange(date);
    closeDatePicker();
  };

  return (
    <div ref={datePickerRef} className={clsx(styles["date-picker"], className)}>
      <div className={styles["date-picker__title"]}>{title}</div>
      <div
        className={clsx(styles["date-picker__value"], {
          [styles["date-picker__value-error"]]: !!error,
        })}
        onClick={toggleDatePicker}
      >
        <span
          className={clsx(styles["date-picker__value--text"], {
            [styles["date-picker__value--text-placeholder"]]: !value,
          })}
        >
          {value ? format(value, formatStr) : placeholder}
        </span>
        <ArrowDownIcon
          className={clsx(styles["date-picker__value--arrow"], {
            [styles["date-picker__value--arrow-active"]]: isOpenDatePicker,
          })}
        />
      </div>
      {error ? <p className={styles["date-picker__error"]}>{error}</p> : null}
      {isOpenDatePicker ? (
        <Calendar
          className={styles["date-picker__calendar"]}
          value={value ?? new Date()}
          onChange={onChangeDate as any}
          locale="en"
          minDate={minDate}
        />
      ) : null}
    </div>
  );
};

export default DatePicker;
