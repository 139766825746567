import { ChangeEvent, useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
//assets
import { PaidVideoIcon, TrashIcon } from "../../../../assets";
//modals
import CheckVideoModal from "../../../../modals/CheckVideo";
//types
import { UserType, VideoType } from "../../../../types";
//components
import { PrivateVideo } from "../../../../components";
//constants
import { toastError } from "../../../../constants";
import { uploadFile } from "../../../../services/file.service";
import { getLinkForUploadImage } from "../../../../services/auth.service";
import { addPaidVideoToUser } from "../../../../services/users.service";
import { ClipLoader } from "react-spinners";

const VideoDetails = ({ user }: { user?: UserType | null }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<VideoType | undefined>(
    undefined
  );
  const [paidVideo, setPaidVideo] = useState<VideoType | undefined>(undefined);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commonError, setCommonError] = useState("");
  const inputFile = useRef<HTMLInputElement>(null);

  const onUpload = async (
    avatar: string,
    avatarFile: File,
    avatarFileName: string
  ) => {
    try {
      if (!user || !avatarFile || !avatar) {
        toast.error(toastError);
        return;
      }
      setIsLoading(true);

      const resLinkImg = await getLinkForUploadImage(avatarFileName);
      const imageLink = resLinkImg?.data?.links[0].downloadUrl;
      let blob = await fetch(avatar).then((r) => r.blob());
      const resUploadImg = await uploadFile(
        blob,
        resLinkImg?.data?.links[0].uploadUrl
      );

      const { data } = await addPaidVideoToUser({
        userId: user.id,
        paidVideoInterview: imageLink,
      });
      if (data?.success) {
        toast.success("Video has been successfully uploaded");
      }
      setIsLoading(false);
    } catch (error: any) {
      console.log("onUpload error", error);
      setIsLoading(false);
      toast.error(toastError);
      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  const onDelete = async () => {
    try {
      if (!user) {
        toast.error(toastError);
        return;
      }
      setIsLoading(true);

      const { data } = await addPaidVideoToUser({
        userId: user.id,
        paidVideoInterview: null,
      });
      if (data?.success) {
        setPaidVideo(undefined);
        toast.success("Video has been successfully deleted");
      }
      setIsLoading(false);
    } catch (error: any) {
      console.log("onDelete error", error);
      setIsLoading(false);
      toast.error(toastError);
      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  const showOpenFileDialog = () => {
    if (!isLoading) inputFile.current?.click();
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);
      setPaidVideo({ url: fileURL });
      onUpload(fileURL, file, file?.name);
    } catch (error) {}
  };

  useEffect(() => {
    if (user?.paidVideoInterview) {
      setPaidVideo({ url: user.paidVideoInterview });
    }
  }, [user]);

  return (
    <>
      <CheckVideoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        video={currentVideo}
      />
      {/* {user?.video && user.video?.length > 0 ? ( */}
      <div className={styles.videosWrapper}>
        {user?.video &&
          user.video?.length > 0 &&
          user.video.map((item, index) => {
            return (
              <div
                className={styles.videosBody}
                onClick={() => {
                  setShowModal(true);
                  setCurrentVideo(item);
                }}
                key={`videoDetails-${index}`}
              >
                <PrivateVideo
                  src={`${item?.url}`}
                  timing={0}
                  type="video/mp4"
                  size={30}
                />
                <div className={styles.triangle}></div>
                <div
                  className={styles.trash}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                >
                  <TrashIcon fill="white" width={"16px"} height={"16px"} />
                </div>
                <div className={styles.videoInfo}>
                  <p>{item?.question}</p>
                </div>
              </div>
            );
          })}
        <div className={styles.paidWrapper}>
          {paidVideo ? (
            <div
              className={styles.videosBody}
              onClick={() => {
                setShowModal(true);
                setCurrentVideo(paidVideo);
              }}
            >
              <PrivateVideo
                src={paidVideo?.url}
                timing={0}
                type="video/mp4"
                size={30}
              />
              {isLoading ? (
                <div className={styles.loader}>
                  <ClipLoader color="#e13f4e" size={40} />
                </div>
              ) : (
                <div className={styles.triangle}></div>
              )}
              <div
                className={styles.trash}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <TrashIcon fill="white" width={"16px"} height={"16px"} />
              </div>
              <div className={styles.videoTag}>
                <p>Paid-interview</p>
              </div>
            </div>
          ) : (
            <div className={styles.videoPaid} onClick={showOpenFileDialog}>
              <PaidVideoIcon />
              <p className={styles.videoBtn}>Add Paid-interview video</p>
              <input
                style={{ display: "none" }}
                ref={inputFile}
                type={"file"}
                accept="video/*"
                onChange={onSelectFile}
                onClick={(e: any) => (e.target.value = null)}
              />
            </div>
          )}
        </div>
      </div>
      {/*  ) : (
         <div className={styles.videosEmpty}>Nothing here yet</div>
       )} */}
    </>
  );
};
export default VideoDetails;
