import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

type ReportsAllParams = {
  page?: number;
  limit?: number;
  search?: string;
  type?:
    | { "reporterInfo.userName": 1 | -1 }
    | { type: 1 | -1 }
    | { category: 1 | -1 }
    | { "reportedOnInfo.userName": 1 | -1 }
    | { createdAt: 1 | -1 }
    | {};
  filter?: any;
};

export const getAllReports = async ({
  page = 1,
  limit = 10,
  search = "",
  type,
  filter,
}: ReportsAllParams): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {};
    if (!!search) body.search = search;
    if (!!type) body.sort = { ...type };
    if (!!filter) body.filter = { ...filter };

    axiosInstance
      .post(`report/all?page=${page}&limit=${limit}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteReport = async (
  reportId: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`report?reportId=${reportId}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
