import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import { ArrowDownIcon } from "../../assets";

export type OptionType = {
  id: any;
  text: string;
};

type SelectorStringProps = {
  options: string[];
  onChange: (value: string) => void;
};

type SelectorOptionProps = {
  options: OptionType[];
  onChange: (value: OptionType) => void;
};

type SelectorProps = SelectorStringProps | SelectorOptionProps;

type CustomSelectorProps = SelectorProps & {
  // options: string[];
  label: string;
  value: string;
  // onChange: (option: string) => void;
  disabled?: boolean;
  error?: string | boolean;
};

export default function Select({
  options,
  onChange,
  label,
  value,
  disabled,
  error,
}: CustomSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  function handleOptionClick(option: any) {
    onChange(option);
  }

  const LastElem = useMemo(() => options[options.length - 1], [options]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.wrapper} ref={dropdownRef}>
      <span className={styles.label}>{label}</span>
      <div className={styles.selectBody}>
        <div
          className={clsx(styles.selectHeader, {
            [styles.selectHeaderOpen]: isOpen,
            [styles.disabled]: disabled,
            [styles.error]: error,
          })}
          onClick={() => !disabled && setIsOpen((current) => !current)}
        >
          <span>{value}</span>
          {!disabled && (
            <ArrowDownIcon
              width={"18px"}
              className={clsx({
                [styles.arrowOpen]: isOpen,
                [styles.arrow]: !isOpen,
              })}
            />
          )}
        </div>
        <div
          className={clsx({
            [styles.optionOpen]: isOpen,
            [styles.option]: !isOpen,
            [styles.lastOption]:
              options[options.length - 1] === LastElem && isOpen,
          })}
        >
          {options.map((option, index) => (
            <div
              key={typeof option === "string" ? option : option?.id || index}
              style={{ margin: "10px 0 10px 0" }}
              className={clsx(
                { [styles.selected]: value === option },
                styles.optionItem
              )}
              onClick={() => {
                handleOptionClick(option);
                setIsOpen((current) => !current);
              }}
            >
              <span>
                {typeof option === "string" ? option : option?.text || ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
