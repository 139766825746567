import { ChangeEvent, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import { SearchIcon, AddIcon } from "../../../assets";
//components
import {
  Button,
  Empty,
  Input,
  PageItem,
  MeetupItem,
} from "../../../components";
//modals
import MeetupModal from "../../../modals/Meetup";
//hooks
import useDebounce from "../../../hooks/useDebounce";
//types
import { MeetupType } from "../../../types";
//api
import { getAllMeetups } from "../../../services/meetups.service";

const LIMIT_PER_PAGE = 10;

const Events = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [allMeetups, setAllMeetups] = useState<MeetupType[]>([]);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false); // true

  const searchDebounced = useDebounce(search, 500);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetMeetups = async (
    page: number = 1,
    limit: number,
    search: string = ""
  ) => {
    try {
      setIsLoading(true);
      const { data } = await getAllMeetups({
        page: page,
        limit: limit,
        search: search,
      });
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalMeetups / limit));
        setAllMeetups(data?.meetups);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetMeetups(1, LIMIT_PER_PAGE, search);
  }, []);

  useEffect(() => {
    onGetMeetups(page, LIMIT_PER_PAGE, searchDebounced);
  }, [searchDebounced, page]);

  return (
    <>
      <MeetupModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        onGetAll={() => onGetMeetups(1, LIMIT_PER_PAGE)}
      />
      <div className={styles.navBody}>
        <div className={styles.input}>
          <Input
            icon={SearchIcon}
            placeholder="Search"
            isEmpty={search.length === 0}
            isSearch
            onChange={onChangeSearch}
            // style={{ width: "88%" }}
            setEmpty={() => setSearch("")}
          />
        </div>
        <Button
          onClick={(e) => setIsOpenModal(true)}
          title="Add New"
          icon={AddIcon}
        />
      </div>
      <div className={styles.wrapper}>
        <section className={styles.contentContainer}>
          <div
            className={clsx(styles.sectionHeader, styles.sectionHeaderEvents)}
          >
            <div className={styles.nameBlock}>
              <p>Event</p>
            </div>
            <div className={styles.industryBlock}>
              <p>Price</p>
            </div>
            <div className={styles.locationBlock}>
              <p>Location</p>
            </div>
            <div className={styles.sizeBlock}>
              <p>Members</p>
            </div>
            <div className={styles.sizeBlock}>
              <p>Creation date</p>
            </div>
          </div>
          <div className={styles.meetupsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#236BFE"} />
              </div>
            )}
            {!isLoading &&
              allMeetups.length > 0 &&
              allMeetups.map((item, index) => {
                return (
                  <MeetupItem
                    key={item.id}
                    item={item}
                    onGetAllStaff={() =>
                      onGetMeetups(1, LIMIT_PER_PAGE, search)
                    }
                  />
                );
              })}
            {!isLoading && allMeetups.length === 0 && <Empty />}
          </div>
          <div className={styles.paginationContainer}>
            {pagesCount >= 0 &&
              Array(pagesCount === 0 ? 1 : pagesCount)
                .fill("_")
                .map((item, index) => (
                  <PageItem
                    onPress={() => setPage(index + 1)}
                    key={index}
                    page={index + 1}
                    isActive={index + 1 === page}
                  />
                ))}
            {pagesCount > 1 && page !== pagesCount && (
              <div
                onClick={() => setPage((prev) => prev + 1)}
                className={styles.nextBtn}
              >
                Next
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default Events;
