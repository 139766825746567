import React, { useContext, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import clsx from "clsx";

import styles from "./styles.module.scss";
//assets
import { ArrowDownIcon } from "../../../assets";
//components
import {
  PhotoVerificationItem,
  Checkbox,
  Empty,
  PageItem,
} from "../../../components";
//hooks
import useDebounce from "../../../hooks/useDebounce";
//api
import { getAllVerificationUsers } from "../../../services/verification.service";
//types
import { UserType } from "../../../types";
//context
import { useNumbersContext } from "../../../context";

const LIMIT_PER_PAGE = 10;

export default function PhotosVerification({
  setVerifyUsers,
  isLoading: loadingServer,
  handleVerify,
  search,
  reload,
}: {
  setVerifyUsers: (value: string[]) => void;
  isLoading: boolean;
  handleVerify: (verifyData?: {
    id: string;
    status: "Approved" | "Declined";
  }) => void;
  search: string;
  reload: boolean;
}) {
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState(false);
  const [allUsers, setAllUsers] = useState<UserType[]>([]);
  const [userNameSort, setUserNameSort] = useState<1 | -1 | 0>(0);
  const [updatedAtSort, setUpdatedAteSort] = useState<1 | -1 | 0>(0);
  const numbers = useNumbersContext();

  const searchDebounced = useDebounce(search, 500);

  useEffect(() => {
    setIsLoading(loadingServer);
  }, [loadingServer]);

  const onGetVerificationOwners = async (
    page: number = 1,
    limit: number = LIMIT_PER_PAGE,
    search: string = "",
    type:
      | { userName: 1 | -1 }
      | { "verificationDocument.updatedAt": 1 | -1 }
      | {} = {}
  ) => {
    try {
      setIsLoading(true);
      const { data } = await getAllVerificationUsers({
        page: page,
        limit: limit,
        search: search,
        type: type,
      });
      if (data?.success) {
        setAllUsers(data?.users);
        setPagesCount(Math.ceil(data?.totalUsers / limit));
        if (!search) {
          numbers.addNumber(data?.totalUsers);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    onGetVerificationOwners(1, LIMIT_PER_PAGE, search);
  }, []);

  useEffect(() => {
    if (userNameSort !== 0) {
      onGetVerificationOwners(page, LIMIT_PER_PAGE, searchDebounced, {
        userName: userNameSort,
      });
    } else if (updatedAtSort !== 0) {
      onGetVerificationOwners(page, LIMIT_PER_PAGE, searchDebounced, {
        "verificationDocument.updatedAt": updatedAtSort,
      });
    } else {
      onGetVerificationOwners(page, LIMIT_PER_PAGE, searchDebounced, {});
    }
  }, [userNameSort, updatedAtSort, page, searchDebounced, reload]);

  return (
    <div className={styles.wrapper}>
      <section className={styles.contentContainer}>
        <div className={styles.sectionHeader}>
          <div className={styles.checkbox}>
            <Checkbox
              isChecked={isChecked}
              setIsChecked={() => setIsChecked((current) => !current)}
              mainCheckBox
            />
          </div>
          <div
            onClick={() => {
              setUpdatedAteSort(0);
              setUserNameSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
            }}
            className={clsx(styles.nameBlock, {
              [styles.activeBlockAZ]: userNameSort === 1,
              [styles.activeBlockZA]: userNameSort === -1,
            })}
          >
            <p>User</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setUserNameSort(0);
              setUpdatedAteSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
            }}
            className={clsx(styles.industryBlock, {
              [styles.activeBlockAZ]: updatedAtSort === 1,
              [styles.activeBlockZA]: updatedAtSort === -1,
            })}
          >
            <p>Publication date</p>{" "}
            <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>

          <div className={clsx(styles.sizeBlock)}>
            <p>Photo</p>
          </div>
        </div>
        <div className={styles.reportsContainer}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          )}
          {!isLoading &&
            allUsers.map((item, index) => {
              return (
                <PhotoVerificationItem
                  key={item.id}
                  isLoading={isLoading}
                  item={item}
                  allChecked={isChecked}
                  setVerifyUser={setVerifyUsers}
                  handleVerify={handleVerify}
                />
              );
            })}
          {!isLoading && allUsers.length === 0 && <Empty />}
        </div>
        <div className={styles.paginationContainer}>
          {pagesCount >= 0 &&
            Array(pagesCount === 0 ? 1 : pagesCount)
              .fill("_")
              .map((item, index) => (
                <PageItem
                  onPress={() => setPage(index + 1)}
                  key={index}
                  page={index + 1}
                  isActive={index + 1 === page}
                />
              ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => setPage((prev) => prev + 1)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
