import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import styles from "./styles.module.scss";
//assets
import Logo from "../../assets/icons/logo.svg";
//api
import { login } from "../../services/auth.service";
//components
import { Input, Button, Checkbox } from "../../components";
//utils
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
} from "../../utils/tokenStorage";
//schemas
import { signInSchema } from "../../schemas";
//context
import { useAdminContext } from "../../context";

const SignIn: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [isRemember, setIsRemember] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const admin = useAdminContext();

  useEffect(() => {
    let token = getAccessToken();
    if (token) {
      navigate("/");
    }
  }, []);

  const onLogin = async (values: { email: string; password: string }) => {
    try {
      setError("");
      setIsLoading(true);
      const { data } = await login(values?.email, values?.password);
      console.log("data", data);

      if (data?.success) {
        setAccessToken(data?.accessToken);
        setRefreshToken(data?.refreshToken);
        admin.setAdmin(data?.admin);
        navigate("/statistics");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("login error", error);
      setError("Email address or password doesn't correct.");
    }
  };

  const { values, errors, handleSubmit, handleChange, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signInSchema,
    validateOnChange: true,
    onSubmit: onLogin,
  });

  return (
    <div className={styles.wrapper}>
      <img src={Logo} alt={"stroynik"} />
      <div className={styles.signInForm}>
        <Input
          id="email"
          value={values.email}
          onChange={handleChange}
          label={"Email"}
          placeholder={"example@gmail.com"}
          wrapperStyles={styles.input}
          error={touched.email && errors.email}
        />
        <Input
          id="password"
          value={values.password}
          onChange={handleChange}
          label={"Password"}
          isShow
          placeholder={"Password"}
          type={"password"}
          wrapperStyles={styles.input}
          error={touched.password && errors.password}
        />
        {/* <div className={styles.checkboxContainer}>
          <Checkbox isChecked={isRemember} setIsChecked={setIsRemember} />
          <span>Remember me</span>
        </div>{" "} */}
        <Button
          loading={isLoading}
          onClick={() => handleSubmit()}
          style={{ borderRadius: "16px" }}
          title={"Login"}
          styleType={"filled"}
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

export default SignIn;
