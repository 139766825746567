/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { format } from "date-fns";

// components
import { Pagination, PrivateImage, Table } from "../../../../../components";

// hooks
import { useGroupFollowers } from "../../hooks";
import useTable from "../../../../../hooks/useTable";

// assets
import { ArrowDownIcon } from "../../../../../assets";
import { PlaceholderAvatar } from "../../../../../assets/images";

// styles
import styles from "./styles.module.scss";

interface IGroupFollowersProps {
  groupId: string;
}

const GroupFollowers = ({ groupId }: IGroupFollowersProps) => {
  const navigate = useNavigate();

  const navigateToUser = (userId: string) => {
    return () => {
      navigate(`/users/${userId}`);
    };
  };

  const { page, setPage, sortBy, onChangeSortBy } = useTable();

  const { isLoading, groupFollowers, totalGroupFollowers } = useGroupFollowers({
    groupId,
    params: {
      page,
      sort: sortBy,
    },
  });

  const columns = useMemo(
    () => [
      {
        header: "User",
        accessor: "userName",
        Cell: (value: any) => (
          <div className={styles["followers__table--user"]}>
            <div className={styles["followers__table--user-avatar"]}>
              <PrivateImage
                src={value?.photos?.main}
                defaultsrc={PlaceholderAvatar}
                alt="follower avatar"
              />
            </div>
            <div className={styles["followers__table--user-name"]}>
              {value?.userName}
            </div>
          </div>
        ),
        width: 500,
        sorting: true,
      },
      {
        header: "Location",
        accessor: "location",
        Cell: (value: any) => value?.location?.name,
      },
      {
        header: "Registration date",
        accessor: "createdAt",
        Cell: (value: any) => format(new Date(value.createdAt), "PP"),
        sorting: true,
      },
      {
        header: "",
        accessor: "",
        Cell: (value: any) => (
          <div
            className={styles["followers__table--action"]}
            onClick={navigateToUser(value.id)}
          >
            <ArrowDownIcon width={"15px"} fill="white" />
          </div>
        ),
        width: 40,
        align: "center",
      },
    ],
    []
  );

  return (
    <Table
      loading={isLoading}
      columns={columns}
      data={groupFollowers}
      handleSortBy={onChangeSortBy}
      footer={
        <Pagination
          page={page}
          total={totalGroupFollowers}
          onChangePage={setPage}
        />
      }
    />
  );
};

export default GroupFollowers;
