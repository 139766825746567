import { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
//assets
import { SearchIcon, AddIcon, ArrowDownIcon } from "../../../assets";
//components
import {
  Button,
  Empty,
  Input,
  Layout,
  PageItem,
  StaffManagementItem,
} from "../../../components";
//modals
import ManagerModal from "../../../modals/Manager";
//api
import { getAllStaff } from "../../../services/staff.service";
//hooks
import useDebounce from "../../../hooks/useDebounce";
//types
import { AdminType } from "../../../types";
//context
import { useAdminContext } from "../../../context";

const LIMIT_PER_PAGE = 10;

export default function StaffManagement() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false); // true
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [staff, setStaff] = useState<AdminType[]>([]);
  const [firstNameSort, setFirstNameSort] = useState<-1 | 1 | 0>(0);
  const [emailSort, setEmailSort] = useState<-1 | 1 | 0>(0);
  const [roleSort, setRoleSort] = useState<-1 | 1 | 0>(0);
  const { admin, adminLoading } = useAdminContext();

  const searchDebounced = useDebounce(search, 500);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetAllStaff = async (
    page: number = 1,
    limit: number = LIMIT_PER_PAGE,
    search: string = "",
    type:
      | { firstName: 1 | -1 }
      | { lastName: 1 | -1 }
      | { email: 1 | -1 }
      | { role: 1 | -1 }
      | {} = {}
  ) => {
    try {
      setIsLoading(true);
      const { data } = await getAllStaff({
        page: page,
        limit: limit,
        search: search,
        type: type,
      });
      if (data?.success) {
        console.log("staff", staff);

        setStaff(data?.users);
        setPagesCount(Math.ceil(data?.totalUsers / limit));
        // verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetAllStaff(1, LIMIT_PER_PAGE, search);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchDebounced]);

  useEffect(() => {
    if (firstNameSort !== 0) {
      onGetAllStaff(page, LIMIT_PER_PAGE, searchDebounced, {
        firstName: firstNameSort,
      });
    } else if (emailSort !== 0) {
      onGetAllStaff(page, LIMIT_PER_PAGE, searchDebounced, {
        email: emailSort,
      });
    } else if (roleSort !== 0) {
      onGetAllStaff(page, LIMIT_PER_PAGE, searchDebounced, { role: roleSort });
    } else {
      onGetAllStaff(page, LIMIT_PER_PAGE, searchDebounced, {});
    }
  }, [firstNameSort, emailSort, roleSort, page, searchDebounced]);

  if (!adminLoading && admin?.role !== "admin") {
    navigate("/statistics");
    return <></>;
  }
  return (
    <>
      <ManagerModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        onGetAllStaff={onGetAllStaff}
      />
      <Layout title={"Staff Management"}>
        <div className={styles.navBody}>
          <div className={styles.input}>
            <Input
              icon={SearchIcon}
              placeholder="Search"
              isEmpty={search.length === 0}
              isSearch
              onChange={onChangeSearch}
              // style={{ width: "88%" }}
              setEmpty={() => setSearch("")}
            />
          </div>
          <Button
            onClick={(e) => setIsOpenModal(true)}
            title="Add New"
            icon={AddIcon}
          />
        </div>
        <div className={styles.wrapper}>
          <section className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              <div
                onClick={() => {
                  setEmailSort(0);
                  setRoleSort(0);
                  setFirstNameSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.nameBlock, {
                  [styles.activeBlockAZ]: firstNameSort === 1,
                  [styles.activeBlockZA]: firstNameSort === -1,
                })}
              >
                <p>User</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setRoleSort(0);
                  setEmailSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.industryBlock, {
                  [styles.activeBlockAZ]: emailSort === 1,
                  [styles.activeBlockZA]: emailSort === -1,
                })}
              >
                <p>Email</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>

              <div
                onClick={() => {
                  setEmailSort(0);
                  setFirstNameSort(0);
                  setRoleSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.locationBlock, {
                  [styles.activeBlockAZ]: roleSort === 1,
                  [styles.activeBlockZA]: roleSort === -1,
                })}
              >
                <p>Role</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div className={styles.sizeBlock}></div>
            </div>
            <div className={styles.reportsContainer}>
              {isLoading && (
                <div className={styles.loaderWrapper}>
                  <PulseLoader color={"#236BFE"} />
                </div>
              )}
              {!isLoading &&
                staff.map((item, index) => (
                  <StaffManagementItem
                    allChecked={isChecked}
                    key={item.id}
                    item={item}
                    setIsLoading={() => setIsLoading}
                    onGetAllStaff={onGetAllStaff}
                    isLoading={isLoading}
                  />
                ))}
              {!isLoading && staff.length === 0 && <Empty />}
            </div>
            <div className={styles.paginationContainer}>
              {pagesCount >= 0 &&
                Array(pagesCount === 0 ? 1 : pagesCount)
                  .fill("_")
                  .map((item, index) => (
                    <PageItem
                      onPress={
                        () => setPage(index + 1)
                        // onGetAllStaff(index + 1, LIMIT_PER_PAGE, search)
                      }
                      key={index}
                      page={index + 1}
                      isActive={index + 1 === page}
                    />
                  ))}
              {pagesCount > 1 && page !== pagesCount && (
                <div
                  onClick={
                    () => setPage((prev) => prev + 1)
                    // onGetAllStaff(page + 1, LIMIT_PER_PAGE, search)
                  }
                  className={styles.nextBtn}
                >
                  Next
                </div>
              )}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
